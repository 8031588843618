const initialState = {
  isrRifRanges: [
    {
      lowerLimit: 0.01,
      upperLimit: 992.14,
      fixedFee: 0,
      porcentage: .0192,
      year: 2017
    },
    {
      lowerLimit: 992.15,
      upperLimit: 8420.82,
      fixedFee: 19.04,
      porcentage: .0640,
      year: 2017
    },
    {
      lowerLimit: 8420.83,
      upperLimit: 14798.84,
      fixedFee: 494.48,
      porcentage: .1088,
      year: 2017
    },
    {
      lowerLimit: 14798.85,
      upperLimit: 17203.00,
      fixedFee: 1188.42,
      porcentage: .16,
      year: 2017
    },
    {
      lowerLimit: 17203.01,
      upperLimit: 20596.70,
      fixedFee: 1573.08,
      porcentage: .1792,
      year: 2017
    },
    {
      lowerLimit: 20596.71,
      upperLimit: 41540.58,
      fixedFee: 2181.22,
      porcentage: .2136,
      year: 2017
    },
    {
      lowerLimit: 41540.59,
      upperLimit: 65473.66,
      fixedFee: 6654.84,
      porcentage: .2352,
      year: 2017
    },
    {
      lowerLimit: 65473.67,
      upperLimit: 125000.00,
      fixedFee: 12283.90,
      porcentage: .3,
      year: 2017
    },
    {
      lowerLimit: 125000.01,
      upperLimit: 166666.66,
      fixedFee: 30141.80,
      porcentage: .32,
      year: 2017
    },
    {
      lowerLimit: 166666.67,
      upperLimit: 500000.00,
      fixedFee: 43475.14,
      porcentage: .34,
      year: 2017
    },
    {
      lowerLimit: 500000.01,
      upperLimit: null,
      fixedFee: 156808.46,
      porcentage: .35,
      year: 2017
    },

    {
      lowerLimit: 0.01,
      upperLimit: 992.14,
      fixedFee: 0,
      porcentage: .0192,
      year: 2018
    },
    {
      lowerLimit: 992.15,
      upperLimit: 8420.82,
      fixedFee: 19.04,
      porcentage: .0640,
      year: 2018
    },
    {
      lowerLimit: 8420.83,
      upperLimit: 14798.84,
      fixedFee: 494.48,
      porcentage: .1088,
      year: 2018
    },
    {
      lowerLimit: 14798.85,
      upperLimit: 17203.00,
      fixedFee: 1188.42,
      porcentage: .16,
      year: 2018
    },
    {
      lowerLimit: 17203.01,
      upperLimit: 20596.70,
      fixedFee: 1573.08,
      porcentage: .1792,
      year: 2018
    },
    {
      lowerLimit: 20596.71,
      upperLimit: 41540.58,
      fixedFee: 2181.22,
      porcentage: .2136,
      year: 2018
    },
    {
      lowerLimit: 41540.59,
      upperLimit: 65473.66,
      fixedFee: 6654.84,
      porcentage: .2352,
      year: 2018
    },
    {
      lowerLimit: 65473.67,
      upperLimit: 125000.00,
      fixedFee: 12283.90,
      porcentage: .3,
      year: 2018
    },
    {
      lowerLimit: 125000.01,
      upperLimit: 166666.66,
      fixedFee: 30141.80,
      porcentage: .32,
      year: 2018
    },
    {
      lowerLimit: 166666.67,
      upperLimit: 500000.00,
      fixedFee: 43475.14,
      porcentage: .34,
      year: 2018
    },
    {
      lowerLimit: 500000.01,
      upperLimit: null,
      fixedFee: 156808.46,
      porcentage: .35,
      year: 2018
    },

    {
      lowerLimit: 0.01,
      upperLimit: 992.14,
      fixedFee: 0,
      porcentage: .0192,
      year: 2019
    },
    {
      lowerLimit: 992.15,
      upperLimit: 8420.82,
      fixedFee: 19.04,
      porcentage: .0640,
      year: 2019
    },
    {
      lowerLimit: 8420.83,
      upperLimit: 14798.84,
      fixedFee: 494.48,
      porcentage: .1088,
      year: 2019
    },
    {
      lowerLimit: 14798.85,
      upperLimit: 17203.00,
      fixedFee: 1188.42,
      porcentage: .16,
      year: 2019
    },
    {
      lowerLimit: 17203.01,
      upperLimit: 20596.70,
      fixedFee: 1573.08,
      porcentage: .1792,
      year: 2019
    },
    {
      lowerLimit: 20596.71,
      upperLimit: 41540.58,
      fixedFee: 2181.22,
      porcentage: .2136,
      year: 2019
    },
    {
      lowerLimit: 41540.59,
      upperLimit: 65473.66,
      fixedFee: 6654.84,
      porcentage: .2352,
      year: 2019
    },
    {
      lowerLimit: 65473.67,
      upperLimit: 125000.00,
      fixedFee: 12283.90,
      porcentage: .3,
      year: 2019
    },
    {
      lowerLimit: 125000.01,
      upperLimit: 166666.66,
      fixedFee: 30141.80,
      porcentage: .32,
      year: 2019
    },
    {
      lowerLimit: 166666.67,
      upperLimit: 500000.00,
      fixedFee: 43475.14,
      porcentage: .34,
      year: 2019
    },
    {
      lowerLimit: 500000.01,
      upperLimit: null,
      fixedFee: 156808.46,
      porcentage: .35,
      year: 2019
    },

    {
      lowerLimit: 0.01,
      upperLimit: 992.14,
      fixedFee: 0,
      porcentage: .0192,
      year: 2020
    },
    {
      lowerLimit: 992.15,
      upperLimit: 8420.82,
      fixedFee: 19.04,
      porcentage: .0640,
      year: 2020
    },
    {
      lowerLimit: 8420.83,
      upperLimit: 14798.84,
      fixedFee: 494.48,
      porcentage: .1088,
      year: 2020
    },
    {
      lowerLimit: 14798.85,
      upperLimit: 17203.00,
      fixedFee: 1188.42,
      porcentage: .16,
      year: 2020
    },
    {
      lowerLimit: 17203.01,
      upperLimit: 20596.70,
      fixedFee: 1573.08,
      porcentage: .1792,
      year: 2020
    },
    {
      lowerLimit: 20596.71,
      upperLimit: 41540.58,
      fixedFee: 2181.22,
      porcentage: .2136,
      year: 2020
    },
    {
      lowerLimit: 41540.59,
      upperLimit: 65473.66,
      fixedFee: 6654.84,
      porcentage: .2352,
      year: 2020
    },
    {
      lowerLimit: 65473.67,
      upperLimit: 125000.00,
      fixedFee: 12283.90,
      porcentage: .3,
      year: 2020
    },
    {
      lowerLimit: 125000.01,
      upperLimit: 166666.66,
      fixedFee: 30141.80,
      porcentage: .32,
      year: 2020
    },
    {
      lowerLimit: 166666.67,
      upperLimit: 500000.00,
      fixedFee: 43475.14,
      porcentage: .34,
      year: 2020
    },
    {
      lowerLimit: 500000.01,
      upperLimit: null,
      fixedFee: 156808.46,
      porcentage: .35,
      year: 2020
    },

    {
      lowerLimit: 0.01,
      upperLimit: 1289.16,
      fixedFee: 0,
      porcentage: .0192,
      year: 2021
    },
    {
      lowerLimit: 1289.17,
      upperLimit: 10941.84,
      fixedFee: 24.76,
      porcentage: .0640,
      year: 2021
    },
    {
      lowerLimit: 10941.85,
      upperLimit: 19229.32,
      fixedFee: 642.52,
      porcentage: .1088,
      year: 2021
    },
    {
      lowerLimit: 19229.33,
      upperLimit: 22353.24,
      fixedFee: 1544.20,
      porcentage: .16,
      year: 2021
    },
    {
      lowerLimit: 22353.25,
      upperLimit: 26762.94,
      fixedFee: 2044.02,
      porcentage: .1792,
      year: 2021
    },
    {
      lowerLimit: 26762.95,
      upperLimit: 53977.00,
      fixedFee: 2834.24,
      porcentage: .2136,
      year: 2021
    },
    {
      lowerLimit: 53977.01,
      upperLimit: 85075.16,
      fixedFee: 8647.16,
      porcentage: .2352,
      year: 2021
    },
    {
      lowerLimit: 85075.17,
      upperLimit: 162422.50,
      fixedFee: 15961.46,
      porcentage: .3,
      year: 2021
    },
    {
      lowerLimit: 162422.51,
      upperLimit: 216563.34,
      fixedFee: 39165.66,
      porcentage: .32,
      year: 2021
    },
    {
      lowerLimit: 216563.35,
      upperLimit: 649690.02,
      fixedFee: 56490.72,
      porcentage: .34,
      year: 2021
    },
    {
      lowerLimit: 649690.03,
      upperLimit: null,
      fixedFee: 203753.80,
      porcentage: .35,
      year: 2021
    },

    {
      lowerLimit: 0.01,
      upperLimit: 1289.16,
      fixedFee: 0,
      porcentage: .0192,
      year: 2022
    },
    {
      lowerLimit: 1289.17,
      upperLimit: 10941.84,
      fixedFee: 24.76,
      porcentage: .0640,
      year: 2022
    },
    {
      lowerLimit: 10941.85,
      upperLimit: 19229.32,
      fixedFee: 642.52,
      porcentage: .1088,
      year: 2022
    },
    {
      lowerLimit: 19229.33,
      upperLimit: 22353.24,
      fixedFee: 1544.20,
      porcentage: .16,
      year: 2022
    },
    {
      lowerLimit: 22353.25,
      upperLimit: 26762.94,
      fixedFee: 2044.02,
      porcentage: .1792,
      year: 2022
    },
    {
      lowerLimit: 26762.95,
      upperLimit: 53977.00,
      fixedFee: 2834.24,
      porcentage: .2136,
      year: 2022
    },
    {
      lowerLimit: 53977.01,
      upperLimit: 85075.16,
      fixedFee: 8647.16,
      porcentage: .2352,
      year: 2022
    },
    {
      lowerLimit: 85075.17,
      upperLimit: 162422.50,
      fixedFee: 15961.46,
      porcentage: .3,
      year: 2022
    },
    {
      lowerLimit: 162422.51,
      upperLimit: 216563.34,
      fixedFee: 39165.66,
      porcentage: .32,
      year: 2022
    },
    {
      lowerLimit: 216563.35,
      upperLimit: 649690.02,
      fixedFee: 56490.72,
      porcentage: .34,
      year: 2022
    },
    {
      lowerLimit: 649690.03,
      upperLimit: null,
      fixedFee: 203753.80,
      porcentage: .35,
      year: 2022
    },
    {
      lowerLimit: 0.01,
      upperLimit: 1492.08,
      fixedFee: 0,
      porcentage: .0192,
      year: 2023
    },
    {
      lowerLimit: 1492.09,
      upperLimit: 12664.10,
      fixedFee: 28.64,
      porcentage: .0640,
      year: 2023
    },
    {
      lowerLimit: 12664.11,
      upperLimit: 22256.02,
      fixedFee: 743.66,
      porcentage: .1088,
      year: 2023
    },
    {
      lowerLimit: 22256.03,
      upperLimit: 25871.64,
      fixedFee: 1787.26,
      porcentage: .16,
      year: 2023
    },
    {
      lowerLimit: 25871.65,
      upperLimit: 30975.42,
      fixedFee: 2365.76,
      porcentage: .1792,
      year: 2023
    },
    {
      lowerLimit: 30975.42,
      upperLimit: 62472.98,
      fixedFee: 3280.36,
      porcentage: .2136,
      year: 2023
    },
    {
      lowerLimit: 62472.99,
      upperLimit: 98466.00,
      fixedFee: 10008.24,
      porcentage: .2352,
      year: 2023
    },
    {
      lowerLimit: 98466.01,
      upperLimit: 187987.80,
      fixedFee: 18473.78,
      porcentage: .3,
      year: 2023
    },
    {
      lowerLimit: 187987.81,
      upperLimit: 250650.40,
      fixedFee: 45330.34,
      porcentage: .32,
      year: 2023
    },
    {
      lowerLimit: 250650.41,
      upperLimit: 751951.22,
      fixedFee: 65382.36,
      porcentage: .34,
      year: 2023
    },
    {
      lowerLimit: 751951.23,
      upperLimit: null,
      fixedFee: 235824.64,
      porcentage: .35,
      year: 2023
    },
    {
      lowerLimit: 0.01,
      upperLimit: 1492.08,
      fixedFee: 0.00,
      porcentage: .0192,
      year: 2024
    },
    {
      lowerLimit: 1492.09,
      upperLimit: 12664.10,
      fixedFee: 28.64,
      porcentage: .0640,
      year: 2024
    },
    {
      lowerLimit: 12664.11,
      upperLimit: 22256.02,
      fixedFee: 743.66,
      porcentage: .1088,
      year: 2024
    },
    {
      lowerLimit: 22256.03,
      upperLimit: 25871.64,
      fixedFee: 1787.26,
      porcentage: .1600,
      year: 2024
    },
    {
      lowerLimit: 25871.65,
      upperLimit: 30975.42,
      fixedFee: 2365.76,
      porcentage: .1792,
      year: 2024
    },
    {
      lowerLimit: 30975.43,
      upperLimit: 62472.98,
      fixedFee: 3280.36,
      porcentage: .2136,
      year: 2024
    },
    {
      lowerLimit: 62472.99,
      upperLimit: 98466.00,
      fixedFee: 10008.24,
      porcentage: .2352,
      year: 2024
    },
    {
      lowerLimit: 98466.01,
      upperLimit: 187987.80,
      fixedFee: 18473.78,
      porcentage: .30,
      year: 2024
    },
    {
      lowerLimit: 187987.81,
      upperLimit: 250650.40,
      fixedFee: 45330.34,
      porcentage: .32,
      year: 2024
    },
    {
      lowerLimit: 250650.41,
      upperLimit: 751951.22,
      fixedFee: 65382.36,
      porcentage: .34,
      year: 2024
    },
    {
      lowerLimit: 751951.23,
      upperLimit: null,
      fixedFee: 235824.64,
      porcentage: .35,
      year: 2024
    },
    {
      "lowerLimit": 0.01,
      "upperLimit": 1492.08,
      "fixedFee": 0.00,
      "percentage": 0.0192,
      "year": 2025
    },
    {
      "lowerLimit": 1492.09,
      "upperLimit": 12664.10,
      "fixedFee": 28.64,
      "percentage": 0.0640,
      "year": 2025
    },
    {
      "lowerLimit": 12664.11,
      "upperLimit": 22256.02,
      "fixedFee": 743.66,
      "percentage": 0.1088,
      "year": 2025
    },
    {
      "lowerLimit": 22256.03,
      "upperLimit": 25871.64,
      "fixedFee": 1787.26,
      "percentage": 0.1600,
      "year": 2025
    },
    {
      "lowerLimit": 25871.65,
      "upperLimit": 30975.42,
      "fixedFee": 2365.76,
      "percentage": 0.1792,
      "year": 2025
    },
    {
      "lowerLimit": 30975.43,
      "upperLimit": 62472.98,
      "fixedFee": 3280.36,
      "percentage": 0.2136,
      "year": 2025
    },
    {
      "lowerLimit": 62472.99,
      "upperLimit": 98466.00,
      "fixedFee": 10008.24,
      "percentage": 0.2352,
      "year": 2025
    },
    {
      "lowerLimit": 98466.01,
      "upperLimit": 187987.80,
      "fixedFee": 18473.78,
      "percentage": 0.3000,
      "year": 2025
    },
    {
      "lowerLimit": 187987.81,
      "upperLimit": 250650.40,
      "fixedFee": 45330.34,
      "percentage": 0.3200,
      "year": 2025
    },
    {
      "lowerLimit": 250650.41,
      "upperLimit": 751951.22,
      "fixedFee": 65382.36,
      "percentage": 0.3400,
      "year": 2025
    },
    {
      "lowerLimit": 751951.23,
      "upperLimit": null,
      "fixedFee": 235824.64,
      "percentage": 0.3500,
      "year": 2025
    }
  ]
}

export const IsrRifReducer = (state = initialState, action) => {
  switch (action.type) {

    default:
      return state
  }
}
