const initialState = {
  isrLeaseRanges: [
    {
      lowerLimit: 0.01,
      upperLimit: 496.07,
      fixedFee: 0.00,
      porcentage: .0192,
      year: 2017
    },
    {
      lowerLimit: 496.08,
      upperLimit: 4210.41,
      fixedFee: 9.52,
      porcentage: .0640,
      year: 2017
    },
    {
      lowerLimit: 4210.42,
      upperLimit: 7399.42,
      fixedFee: 247.24,
      porcentage: .1088,
      year: 2017
    },
    {
      lowerLimit: 7399.43,
      upperLimit: 8601.50,
      fixedFee: 594.21,
      porcentage: .16,
      year: 2017
    },
    {
      lowerLimit: 8601.51,
      upperLimit: 10298.35,
      fixedFee: 786.54,
      porcentage: .1792,
      year: 2017
    },
    {
      lowerLimit: 10298.36,
      upperLimit: 20770.29,
      fixedFee: 1090.61,
      porcentage: .2136,
      year: 2017
    },
    {
      lowerLimit: 20770.30,
      upperLimit: 32736.83,
      fixedFee: 3327.42,
      porcentage: .2352,
      year: 2017
    },
    {
      lowerLimit: 32736.84,
      upperLimit: 62500.00,
      fixedFee: 6141.95,
      porcentage: .30,
      year: 2017
    },
    {
      lowerLimit: 62500.01,
      upperLimit: 83333.33,
      fixedFee: 15070.90,
      porcentage: .32,
      year: 2017
    },
    {
      lowerLimit: 83333.34,
      upperLimit: 250000.00,
      fixedFee: 21737.57,
      porcentage: .34,
      year: 2017
    },
    {
      lowerLimit: 250000.01,
      upperLimit: null,
      fixedFee: 78404.23,
      porcentage: .35,
      year: 2017
    },

    {
      lowerLimit: 0.01,
      upperLimit: 578.52,
      fixedFee: 0.00,
      porcentage: .0192,
      year: 2018
    },
    {
      lowerLimit: 578.53,
      upperLimit: 4910.18,
      fixedFee: 11.11,
      porcentage: .0640,
      year: 2018
    },
    {
      lowerLimit: 4910.19,
      upperLimit: 8629.20,
      fixedFee: 288.33,
      porcentage: .1088,
      year: 2018
    },
    {
      lowerLimit: 8629.21,
      upperLimit: 10031.07,
      fixedFee: 692.96,
      porcentage: .16,
      year: 2018
    },
    {
      lowerLimit: 10031.08,
      upperLimit: 12009.94,
      fixedFee: 917.26,
      porcentage: .1792,
      year: 2018
    },
    {
      lowerLimit: 12009.95,
      upperLimit: 24222.31,
      fixedFee: 1271.87,
      porcentage: .2136,
      year: 2018
    },
    {
      lowerLimit: 24222.32,
      upperLimit: 38177.69,
      fixedFee: 3880.44,
      porcentage: .2352,
      year: 2018
    },
    {
      lowerLimit: 38177.70,
      upperLimit: 72887.50,
      fixedFee: 7162.74,
      porcentage: .30,
      year: 2018
    },
    {
      lowerLimit: 72887.51,
      upperLimit: 97183.33,
      fixedFee: 17575.69,
      porcentage: .32,
      year: 2018
    },
    {
      lowerLimit: 97183.34,
      upperLimit: 291550.00,
      fixedFee: 25350.35,
      porcentage: .34,
      year: 2018
    },
    {
      lowerLimit: 291550.01,
      upperLimit: null,
      fixedFee: 91435.02,
      porcentage: .35,
      year: 2018
    },

    {
      lowerLimit: 0.01,
      upperLimit: 578.52,
      fixedFee: 0.00,
      porcentage: .0192,
      year: 2019
    },
    {
      lowerLimit: 578.53,
      upperLimit: 4910.18,
      fixedFee: 11.11,
      porcentage: .0640,
      year: 2019
    },
    {
      lowerLimit: 4910.19,
      upperLimit: 8629.20,
      fixedFee: 288.33,
      porcentage: .1088,
      year: 2019
    },
    {
      lowerLimit: 8629.21,
      upperLimit: 10031.07,
      fixedFee: 692.96,
      porcentage: .16,
      year: 2019
    },
    {
      lowerLimit: 10031.08,
      upperLimit: 12009.94,
      fixedFee: 917.26,
      porcentage: .1792,
      year: 2019
    },
    {
      lowerLimit: 12009.95,
      upperLimit: 24222.31,
      fixedFee: 1271.87,
      porcentage: .2136,
      year: 2019
    },
    {
      lowerLimit: 24222.32,
      upperLimit: 38177.69,
      fixedFee: 3880.44,
      porcentage: .2352,
      year: 2019
    },
    {
      lowerLimit: 38177.70,
      upperLimit: 72887.50,
      fixedFee: 7162.74,
      porcentage: .30,
      year: 2019
    },
    {
      lowerLimit: 72887.51,
      upperLimit: 97183.33,
      fixedFee: 17575.69,
      porcentage: .32,
      year: 2019
    },
    {
      lowerLimit: 97183.34,
      upperLimit: 291550.00,
      fixedFee: 25350.35,
      porcentage: .34,
      year: 2019
    },
    {
      lowerLimit: 291550.01,
      upperLimit: null,
      fixedFee: 91435.02,
      porcentage: .35,
      year: 2019
    },

    {
      lowerLimit: 0.01,
      upperLimit: 578.52,
      fixedFee: 0.00,
      porcentage: .0192,
      year: 2020
    },
    {
      lowerLimit: 578.53,
      upperLimit: 4910.18,
      fixedFee: 11.11,
      porcentage: .0640,
      year: 2020
    },
    {
      lowerLimit: 4910.19,
      upperLimit: 8629.20,
      fixedFee: 288.33,
      porcentage: .1088,
      year: 2020
    },
    {
      lowerLimit: 8629.21,
      upperLimit: 10031.07,
      fixedFee: 692.96,
      porcentage: .16,
      year: 2020
    },
    {
      lowerLimit: 10031.08,
      upperLimit: 12009.94,
      fixedFee: 917.26,
      porcentage: .1792,
      year: 2020
    },
    {
      lowerLimit: 12009.95,
      upperLimit: 24222.31,
      fixedFee: 1271.87,
      porcentage: .2136,
      year: 2020
    },
    {
      lowerLimit: 24222.32,
      upperLimit: 38177.69,
      fixedFee: 3880.44,
      porcentage: .2352,
      year: 2020
    },
    {
      lowerLimit: 38177.70,
      upperLimit: 72887.50,
      fixedFee: 7162.74,
      porcentage: .30,
      year: 2020
    },
    {
      lowerLimit: 72887.51,
      upperLimit: 97183.33,
      fixedFee: 17575.69,
      porcentage: .32,
      year: 2020
    },
    {
      lowerLimit: 97183.34,
      upperLimit: 291550.00,
      fixedFee: 25350.35,
      porcentage: .34,
      year: 2020
    },
    {
      lowerLimit: 291550.01,
      upperLimit: null,
      fixedFee: 91435.02,
      porcentage: .35,
      year: 2020
    },

    {
      lowerLimit: 0.01,
      upperLimit: 644.58,
      fixedFee: 0.00,
      porcentage: .0192,
      year: 2021
    },
    {
      lowerLimit: 644.59,
      upperLimit: 5470.92,
      fixedFee: 12.38,
      porcentage: .0640,
      year: 2021
    },
    {
      lowerLimit: 5470.93,
      upperLimit: 9614.66,
      fixedFee: 321.26,
      porcentage: .1088,
      year: 2021
    },
    {
      lowerLimit: 9614.67,
      upperLimit: 11176.62,
      fixedFee: 772.10,
      porcentage: .16,
      year: 2021
    },
    {
      lowerLimit: 11176.63,
      upperLimit: 13381.47,
      fixedFee: 1022.01,
      porcentage: .1792,
      year: 2021
    },
    {
      lowerLimit: 13381.48,
      upperLimit: 26988.50,
      fixedFee: 1417.12,
      porcentage: .2136,
      year: 2021
    },
    {
      lowerLimit: 26988.5,
      upperLimit: 42537.58,
      fixedFee: 4323.58,
      porcentage: .2352,
      year: 2021
    },
    {
      lowerLimit: 42537.59,
      upperLimit: 81211.25,
      fixedFee: 7980.73,
      porcentage: .30,
      year: 2021
    },
    {
      lowerLimit: 81211.26,
      upperLimit: 108281.67,
      fixedFee: 19582.83,
      porcentage: .32,
      year: 2021
    },
    {
      lowerLimit: 108281.68,
      upperLimit: 324845.01,
      fixedFee: 28245.36,
      porcentage: .34,
      year: 2021
    },
    {
      lowerLimit: 324845.02,
      upperLimit: null,
      fixedFee: 101876.90,
      porcentage: .35,
      year: 2021
    },
    {
      lowerLimit: 0.01,
      upperLimit: 644.58,
      fixedFee: 0.00,
      porcentage: .0192,
      year: 2022
    },
    {
      lowerLimit: 644.59,
      upperLimit: 5470.92,
      fixedFee: 12.38,
      porcentage: .0640,
      year: 2022
    },
    {
      lowerLimit: 5470.93,
      upperLimit: 9614.66,
      fixedFee: 321.26,
      porcentage: .1088,
      year: 2022
    },
    {
      lowerLimit: 9614.67,
      upperLimit: 11176.62,
      fixedFee: 772.10,
      porcentage: .16,
      year: 2022
    },
    {
      lowerLimit: 11176.63,
      upperLimit: 13381.47,
      fixedFee: 1022.01,
      porcentage: .1792,
      year: 2022
    },
    {
      lowerLimit: 13381.48,
      upperLimit: 26988.50,
      fixedFee: 1417.12,
      porcentage: .2136,
      year: 2022
    },
    {
      lowerLimit: 26988.5,
      upperLimit: 42537.58,
      fixedFee: 4323.58,
      porcentage: .2352,
      year: 2022
    },
    {
      lowerLimit: 42537.59,
      upperLimit: 81211.25,
      fixedFee: 7980.73,
      porcentage: .30,
      year: 2022
    },
    {
      lowerLimit: 81211.26,
      upperLimit: 108281.67,
      fixedFee: 19582.83,
      porcentage: .32,
      year: 2022
    },
    {
      lowerLimit: 108281.68,
      upperLimit: 324845.01,
      fixedFee: 28245.36,
      porcentage: .34,
      year: 2022
    },
    {
      lowerLimit: 324845.02,
      upperLimit: null,
      fixedFee: 101876.90,
      porcentage: .35,
      year: 2022
    },
    {
      lowerLimit: 0.01,
      upperLimit: 746.04,
      fixedFee: 0.00,
      porcentage: .0192,
      year: 2023,
    },
    {
      lowerLimit: 746.05,
      upperLimit: 6332.05,
      fixedFee: 14.32,
      porcentage: .0640,
      year: 2023,
    },
    {
      lowerLimit: 6332.06,
      upperLimit: 11128.01,
      fixedFee: 371.83,
      porcentage: .1088,
      year: 2023,
    },
    {
      lowerLimit: 11128.02,
      upperLimit: 12935.82,
      fixedFee: 893.63,
      porcentage: .16,
      year: 2023,
    },
    {
      lowerLimit: 12935.83,
      upperLimit: 15487.71,
      fixedFee: 1182.88,
      porcentage: .1792,
      year: 2023,
    },
    {
      lowerLimit: 15487.72,
      upperLimit: 31236.49,
      fixedFee: 1640.18,
      porcentage: .2136,
      year: 2023,
    },
    {
      lowerLimit: 31236.50,
      upperLimit: 49233.00,
      fixedFee: 5004.12,
      porcentage: .2352,
      year: 2023,
    },
    {
      lowerLimit: 49233.01,
      upperLimit: 93993.90,
      fixedFee: 9236.89,
      porcentage: .30,
      year: 2023,
    },
    {
      lowerLimit: 93993.91,
      upperLimit: 125325.20,
      fixedFee: 22665.17,
      porcentage: .32,
      year: 2023,
    },
    {
      lowerLimit: 125325.21,
      upperLimit: 375975.61,
      fixedFee: 32691.18,
      porcentage: .34,
      year: 2023,
    },
    {
      lowerLimit: 375975.62,
      upperLimit: null,
      fixedFee: 117912.32,
      porcentage: .35,
      year: 2023,
    },
    // Aqui comienza el 2024
    {
      lowerLimit: 0.01,
      upperLimit: 746.04,
      fixedFee: 0.00,
      porcentage: .0192,
      year: 2024,
    },
    {
      lowerLimit: 746.05,
      upperLimit: 6332.05,
      fixedFee: 14.32,
      porcentage: .0640,
      year: 2024,
    },
    {
      lowerLimit: 6332.06,
      upperLimit: 11128.01,
      fixedFee: 371.83,
      porcentage: .1088,
      year: 2024,
    },
    {
      lowerLimit: 11128.02,
      upperLimit: 12935.82,
      fixedFee: 893.63,
      porcentage: .16,
      year: 2024,
    },
    {
      lowerLimit: 12935.83,
      upperLimit: 15487.71,
      fixedFee: 1182.88,
      porcentage: .1792,
      year: 2024,
    },
    {
      lowerLimit: 15487.72,
      upperLimit: 31236.49,
      fixedFee: 1640.18,
      porcentage: .2136,
      year: 2024,
    },
    {
      lowerLimit: 31236.50,
      upperLimit: 49233.00,
      fixedFee: 5004.12,
      porcentage: .2352,
      year: 2024,
    },
    {
      lowerLimit: 49233.01,
      upperLimit: 93993.90,
      fixedFee: 9236.89,
      porcentage: .30,
      year: 2024,
    },
    {
      lowerLimit: 93993.91,
      upperLimit: 125325.20,
      fixedFee: 22665.17,
      porcentage: .32,
      year: 2024,
    },
    {
      lowerLimit: 125325.21,
      upperLimit: 375975.61,
      fixedFee: 32691.18,
      porcentage: .34,
      year: 2024,
    },
    {
      lowerLimit: 375975.62,
      upperLimit: null,
      fixedFee: 117912.32,
      porcentage: .35,
      year: 2024,
    },
    // Aqui comienza el 2025
    {
      lowerLimit: 0.01,
      upperLimit: 746.04,
      fixedFee: 0.00,
      porcentage: .0192,
      year: 2025,
    },
    {
      lowerLimit: 746.05,
      upperLimit: 6332.05,
      fixedFee: 14.32,
      porcentage: .0640,
      year: 2025,
    },
    {
      lowerLimit: 6332.06,
      upperLimit: 11128.01,
      fixedFee: 371.83,
      porcentage: .1088,
      year: 2025,
    },
    {
      lowerLimit: 11128.02,
      upperLimit: 12935.82,
      fixedFee: 893.63,
      porcentage: .16,
      year: 2025,
    },
    {
      lowerLimit: 12935.83,
      upperLimit: 15487.71,
      fixedFee: 1182.88,
      porcentage: .1792,
      year: 2025,
    },
    {
      lowerLimit: 15487.72,
      upperLimit: 31236.49,
      fixedFee: 1640.18,
      porcentage: .2136,
      year: 2025,
    },
    {
      lowerLimit: 31236.50,
      upperLimit: 49233.00,
      fixedFee: 5004.12,
      porcentage: .2352,
      year: 2025,
    },
    {
      lowerLimit: 49233.01,
      upperLimit: 93993.90,
      fixedFee: 9236.89,
      porcentage: .30,
      year: 2025,
    },
    {
      lowerLimit: 93993.91,
      upperLimit: 125325.20,
      fixedFee: 22665.17,
      porcentage: .32,
      year: 2025,
    },
    {
      lowerLimit: 125325.21,
      upperLimit: 375975.61,
      fixedFee: 32691.18,
      porcentage: .34,
      year: 2025,
    },
    {
      lowerLimit: 375975.62,
      upperLimit: null,
      fixedFee: 117912.32,
      porcentage: .35,
      year: 2025,
    },
  ]
}

export const isrLeaseReducer = (state = initialState, action) => {
  switch (action.type) {

    default:
      return state
  }
}
