const initialState = {
  IsrHonorariumRanges: [
    {
      id: 1,
      lowerLimit: 0.01,
      upperLimit: 496.07,
      fixedFee: 0.00,
      porcentage: .0192,
      year: 2017,
      month: 1,
      isrRetention: 2
    },
    {
      id: 2,
      lowerLimit: 496.08,
      upperLimit: 4210.41,
      fixedFee: 9.52,
      porcentage: .0640,
      year: 2017,
      month: 1,
      isrRetention: 2
    },
    {
      id: 3,
      lowerLimit: 4210.42,
      upperLimit: 7399.42,
      fixedFee: 247.24,
      porcentage: .1088,
      year: 2017,
      month: 1,
      isrRetention: 2
    },
    {
      id: 4,
      lowerLimit: 7399.43,
      upperLimit: 8601.50,
      fixedFee: 594.21,
      porcentage: .16,
      year: 2017,
      month: 1,
      isrRetention: 2
    },
    {
      id: 5,
      lowerLimit: 8601.51,
      upperLimit: 10298.35,
      fixedFee: 786.54,
      porcentage: .1792,
      year: 2017,
      month: 1,
      isrRetention: 2
    },
    {
      id: 6,
      lowerLimit: 10298.36,
      upperLimit: 20770.29,
      fixedFee: 1090.61,
      porcentage: .2136,
      year: 2017,
      month: 1,
      isrRetention: 2
    },
    {
      id: 7,
      lowerLimit: 20770.30,
      upperLimit: 32736.83,
      fixedFee: 3327.42,
      porcentage: .2352,
      year: 2017,
      month: 1,
      isrRetention: 2
    },
    {
      id: 8,
      lowerLimit: 32736.84,
      upperLimit: 62500.00,
      fixedFee: 6141.95,
      porcentage: .30,
      year: 2017,
      month: 1,
      isrRetention: 2
    },
    {
      id: 9,
      lowerLimit: 62500.01,
      upperLimit: 83333.33,
      fixedFee: 15070.90,
      porcentage: .32,
      year: 2017,
      month: 1,
      isrRetention: 2
    },
    {
      id: 10,
      lowerLimit: 83333.34,
      upperLimit: 250000.00,
      fixedFee: 21737.57,
      porcentage: .34,
      year: 2017,
      month: 1,
      isrRetention: 2
    },
    {
      id: 11,
      lowerLimit: 250000.01,
      upperLimit: null,
      fixedFee: 78404.23,
      porcentage: .35,
      year: 2017,
      month: 1,
      isrRetention: 2
    },
    {
      id: 12,
      lowerLimit: 0.01,
      upperLimit: 992.14,
      fixedFee: 0.00,
      porcentage: .0192,
      year: 2017,
      month: 2,
      isrRetention: 4
    },
    {
      id: 13,
      lowerLimit: 992.15,
      upperLimit: 8420.82,
      fixedFee: 19.04,
      porcentage: .0640,
      year: 2017,
      month: 2,
      isrRetention: 4
    },
    {
      id: 14,
      lowerLimit: 8420.83,
      upperLimit: 14798.84,
      fixedFee: 494.48,
      porcentage: .1088,
      year: 2017,
      month: 2,
      isrRetention: 4
    },
    {
      id: 15,
      lowerLimit: 14798.85,
      upperLimit: 17203.00,
      fixedFee: 1188.42,
      porcentage: .16,
      year: 2017,
      month: 2,
      isrRetention: 4
    },
    {
      id: 16,
      lowerLimit: 17203.01,
      upperLimit: 20596.70,
      fixedFee: 1573.08,
      porcentage: .1792,
      year: 2017,
      month: 2,
      isrRetention: 4
    },
    {
      id: 17,
      lowerLimit: 20596.71,
      upperLimit: 41540.58,
      fixedFee: 2181.22,
      porcentage: .2136,
      year: 2017,
      month: 2,
      isrRetention: 4
    },
    {
      id: 18,
      lowerLimit: 41540.59,
      upperLimit: 65473.66,
      fixedFee: 6654.84,
      porcentage: .2352,
      year: 2017,
      month: 2,
      isrRetention: 4
    },
    {
      id: 19,
      lowerLimit: 65473.67,
      upperLimit: 125000.00,
      fixedFee: 12283.90,
      porcentage: .30,
      year: 2017,
      month: 2,
      isrRetention: 4
    },
    {
      id: 20,
      lowerLimit: 125000.01,
      upperLimit: 166666.66,
      fixedFee: 30141.80,
      porcentage: .32,
      year: 2017,
      month: 2,
      isrRetention: 4
    },
    {
      id: 21,
      lowerLimit: 166666.67,
      upperLimit: 500000.00,
      fixedFee: 43475.14,
      porcentage: .34,
      year: 2017,
      month: 2,
      isrRetention: 4
    },
    {
      id: 22,
      lowerLimit: 500000.01,
      upperLimit: null,
      fixedFee: 156808.46,
      porcentage: .35,
      year: 2017,
      month: 2,
      isrRetention: 4
    },
    {
      id: 23,
      lowerLimit: 0.01,
      upperLimit: 1488.21,
      fixedFee: 0.00,
      porcentage: .0192,
      year: 2017,
      month: 3,
      isrRetention: 6
    },
    {
      id: 24,
      lowerLimit: 1488.22,
      upperLimit: 12631.23,
      fixedFee: 28.56,
      porcentage: .0640,
      year: 2017,
      month: 3,
      isrRetention: 6
    },
    {
      id: 25,
      lowerLimit: 12631.24,
      upperLimit: 22198.26,
      fixedFee: 741.72,
      porcentage: .1088,
      year: 2017,
      month: 3,
      isrRetention: 6
    },
    {
      id: 26,
      lowerLimit: 22198.27,
      upperLimit: 25804.50,
      fixedFee: 1782.63,
      porcentage: .16,
      year: 2017,
      month: 3,
      isrRetention: 6
    },
    {
      id: 27,
      lowerLimit: 25804.51,
      upperLimit: 30895.05,
      fixedFee: 2359.62,
      porcentage: .1792,
      year: 2017,
      month: 3,
      isrRetention: 6
    },
    {
      id: 28,
      lowerLimit: 30895.06,
      upperLimit: 62310.87,
      fixedFee: 3271.83,
      porcentage: .2136,
      year: 2017,
      month: 3,
      isrRetention: 6
    },
    {
      id: 29,
      lowerLimit: 62310.88,
      upperLimit: 98210.49,
      fixedFee: 9982.26,
      porcentage: .2352,
      year: 2017,
      month: 3,
      isrRetention: 6
    },
    {
      id: 30,
      lowerLimit: 98210.50,
      upperLimit: 187500.00,
      fixedFee: 18425.85,
      porcentage: .30,
      year: 2017,
      month: 3,
      isrRetention: 6
    },
    {
      id: 31,
      lowerLimit: 187500.01,
      upperLimit: 249999.99,
      fixedFee: 45212.70,
      porcentage: .32,
      year: 2017,
      month: 3,
      isrRetention: 6
    },
    {
      id: 32,
      lowerLimit: 250000.00,
      upperLimit: 750000.00,
      fixedFee: 65212.71,
      porcentage: .34,
      year: 2017,
      month: 3,
      isrRetention: 6
    },
    {
      id: 33,
      lowerLimit: 750000.01,
      upperLimit: null,
      fixedFee: 235212.69,
      porcentage: .35,
      year: 2017,
      month: 3,
      isrRetention: 6
    },
    {
      id: 34,
      lowerLimit: 0.01,
      upperLimit: 1984.28,
      fixedFee: 0.00,
      porcentage: .0192,
      year: 2017,
      month: 4,
      isrRetention: 8
    },
    {
      id: 35,
      lowerLimit: 1984.29,
      upperLimit: 16841.64,
      fixedFee: 38.08,
      porcentage: .0640,
      year: 2017,
      month: 4,
      isrRetention: 8
    },
    {
      id: 36,
      lowerLimit: 16841.65,
      upperLimit: 29597.68,
      fixedFee: 988.96,
      porcentage: .1088,
      year: 2017,
      month: 4,
      isrRetention: 8
    },
    {
      id: 37,
      lowerLimit: 29597.69,
      upperLimit: 34406.00,
      fixedFee: 2376.84,
      porcentage: .16,
      year: 2017,
      month: 4,
      isrRetention: 8
    },
    {
      id: 38,
      lowerLimit: 34406.01,
      upperLimit: 41193.40,
      fixedFee: 3146.16,
      porcentage: .1792,
      year: 2017,
      month: 4,
      isrRetention: 8
    },
    {
      id: 39,
      lowerLimit: 41193.41,
      upperLimit: 83081.16,
      fixedFee: 4362.44,
      porcentage: .2136,
      year: 2017,
      month: 4,
      isrRetention: 8
    },
    {
      id: 40,
      lowerLimit: 83081.17,
      upperLimit: 130947.32,
      fixedFee: 13309.68,
      porcentage: .2352,
      year: 2017,
      month: 4,
      isrRetention: 8
    },
    {
      id: 41,
      lowerLimit: 130947.33,
      upperLimit: 250000.00,
      fixedFee: 24567.80,
      porcentage: .30,
      year: 2017,
      month: 4,
      isrRetention: 8
    },
    {
      id: 42,
      lowerLimit: 250000.01,
      upperLimit: 333333.32,
      fixedFee: 60283.60,
      porcentage: .32,
      year: 2017,
      month: 4,
      isrRetention: 8
    },
    {
      id: 43,
      lowerLimit: 333333.33,
      upperLimit: 1000000.00,
      fixedFee: 86950.28,
      porcentage: .3400,
      year: 2017,
      month: 4,
      isrRetention: 8
    },
    {
      id: 44,
      lowerLimit: 1000000.01,
      upperLimit: null,
      fixedFee: 313616.92,
      porcentage: .35,
      year: 2017,
      month: 4,
    },
    {
      id: 45,
      lowerLimit: 0.01,
      upperLimit: 2480.35,
      fixedFee: 0,
      porcentage: 0.0192,
      year: 2017,
      month: 5
    },
    {
      id: 46,
      lowerLimit: 2480.36,
      upperLimit: 21052.05,
      fixedFee: 47.60,
      porcentage: 0.064,
      year: 2017,
      month: 5
    },
    {
      id: 47,
      lowerLimit: 21052.06,
      upperLimit: 36997.10,
      fixedFee: 1236.20,
      porcentage: 0.1088,
      year: 2017,
      month: 5
    },
    {
      id: 48,
      lowerLimit: 36997.11,
      upperLimit: 43007.50,
      fixedFee: 2971.05,
      porcentage: .16,
      year: 2017,
      month: 5
    },
    {
      id: 49,
      lowerLimit: 43007.51,
      upperLimit: 51491.75,
      fixedFee: 3932.70,
      porcentage: .1792,
      year: 2017,
      month: 5
    },
    {
      id: 50,
      lowerLimit: 51491.76,
      upperLimit: 103851.45,
      fixedFee: 5453.05,
      porcentage: .2136,
      year: 2017,
      month: 5
    },
    {
      id: 51,
      lowerLimit: 103851.46,
      upperLimit: 163684.15,
      fixedFee: 16637.10,
      porcentage: .2352,
      year: 2017,
      month: 5
    },
    {
      id: 52,
      lowerLimit: 163684.16,
      upperLimit: 312500.00,
      fixedFee: 30709.75,
      porcentage: .3000,
      year: 2017,
      month: 5
    },
    {
      id: 53,
      lowerLimit: 312500.01,
      upperLimit: 416666.65,
      fixedFee: 75354.50,
      porcentage: .3200,
      year: 2017,
      month: 5
    },
    {
      id: 54,
      lowerLimit: 416666.66,
      upperLimit: 1250000.00,
      fixedFee: 108687.85,
      porcentage: .34,
      year: 2017,
      month: 5
    },
    {
      id: 55,
      lowerLimit: 1250000.01,
      upperLimit: null,
      fixedFee: 392021.15,
      porcentage: .3500,
      year: 2017,
      month: 5
    },
    {
      id: 56,
      lowerLimit: 0.01,
      upperLimit: 2976.42,
      fixedFee: 0.00,
      porcentage: .0192,
      year: 2017,
      month: 6
    },
    {
      id: 57,
      lowerLimit: 2976.43,
      upperLimit: 25262.46,
      fixedFee: 57.12,
      porcentage: .0640,
      year: 2017,
      month: 6
    },
    {
      id: 58,
      lowerLimit: 25262.47,
      upperLimit: 44396.52,
      fixedFee: 1483.44,
      porcentage: .1088,
      year: 2017,
      month: 6
    },
    {
      id: 59,
      lowerLimit: 44396.53,
      upperLimit: 51609.00,
      fixedFee: 3565.26,
      porcentage: .1600,
      year: 2017,
      month: 6
    },
    {
      id: 60,
      lowerLimit: 51609.01,
      upperLimit: 61790.10,
      fixedFee: 4719.24,
      porcentage: .1792,
      year: 2017,
      month: 6
    },
    {
      id: 61,
      lowerLimit: 61790.11,
      upperLimit: 124621.74,
      fixedFee: 6543.66,
      porcentage: .2136,
      year: 2017,
      month: 6
    },
    {
      id: 62,
      lowerLimit: 124621.75,
      upperLimit: 196420.98,
      fixedFee: 19964.52,
      porcentage: .2352,
      year: 2017,
      month: 6
    },
    {
      id: 63,
      lowerLimit: 196420.99,
      upperLimit: 375000.00,
      fixedFee: 36851.70,
      porcentage: .3000,
      year: 2017,
      month: 6
    },
    {
      id: 64,
      lowerLimit: 375000.01,
      upperLimit: 499999.98,
      fixedFee: 90425.40,
      porcentage: .3200,
      year: 2017,
      month: 6
    },
    {
      id: 65,
      lowerLimit: 499999.99,
      upperLimit: 1500000.00,
      fixedFee: 130425.42,
      porcentage: .3400,
      year: 2017,
      month: 6
    },
    {
      id: 66,
      lowerLimit: 1500000.01,
      upperLimit: null,
      fixedFee: 470425.38,
      porcentage: .3500,
      year: 2017,
      month: 6
    },
    {
      id: 67,
      lowerLimit: 0.01,
      upperLimit: 3472.49,
      fixedFee: 0.00,
      porcentage: .0192,
      year: 2017,
      month: 7
    },
    {
      id: 68,
      lowerLimit: 3472.50,
      upperLimit: 29472.87,
      fixedFee: 66.64,
      porcentage: .0640,
      year: 2017,
      month: 7
    },
    {
      id: 69,
      lowerLimit: 29472.88,
      upperLimit: 51795.94,
      fixedFee: 1730.68,
      porcentage: .1088,
      year: 2017,
      month: 7
    },
    {
      id: 70,
      lowerLimit: 51795.95,
      upperLimit: 60210.50,
      fixedFee: 4159.47,
      porcentage: .1600,
      year: 2017,
      month: 7
    },
    {
      id: 71,
      lowerLimit: 60210.51,
      upperLimit: 72088.45,
      fixedFee: 5505.78,
      porcentage: .1792,
      year: 2017,
      month: 7
    },
    {
      id: 72,
      lowerLimit: 72088.46,
      upperLimit: 145392.03,
      fixedFee: 7634.27,
      porcentage: .2136,
      year: 2017,
      month: 7
    },
    {
      id: 73,
      lowerLimit: 145392.04,
      upperLimit: 229157.81,
      fixedFee: 23291.94,
      porcentage: .2352,
      year: 2017,
      month: 7
    },
    {
      id: 74,
      lowerLimit: 229157.82,
      upperLimit: 437500.00,
      fixedFee: 42993.65,
      porcentage: .3000,
      year: 2017,
      month: 7
    },
    {
      id: 75,
      lowerLimit: 437500.01,
      upperLimit: 583333.31,
      fixedFee: 105496.30,
      porcentage: .3200,
      year: 2017,
      month: 7
    },
    {
      id: 76,
      lowerLimit: 583333.32,
      upperLimit: 1750000.00,
      fixedFee: 152162.99,
      porcentage: .3400,
      year: 2017,
      month: 7
    },
    {
      id: 77,
      lowerLimit: 1750000.00,
      upperLimit: null,
      fixedFee: 548829.61,
      porcentage: .3500,
      year: 2017,
      month: 7
    },
    {
      id: 78,
      lowerLimit: 0.01,
      upperLimit: 3968.56,
      fixedFee: 0.00,
      porcentage: .0192,
      year: 2017,
      month: 8
    },
    {
      id: 79,
      lowerLimit: 3968.57,
      upperLimit: 33683.28,
      fixedFee: 76.16,
      porcentage: .0640,
      year: 2017,
      month: 8
    },
    {
      id: 80,
      lowerLimit: 33683.29,
      upperLimit: 59195.36,
      fixedFee: 1977.92,
      porcentage: .1088,
      year: 2017,
      month: 8
    },
    {
      id: 81,
      lowerLimit: 59195.37,
      upperLimit: 68812.00,
      fixedFee: 4753.68,
      porcentage: .1600,
      year: 2017,
      month: 8
    },
    {
      id: 82,
      lowerLimit: 68812.01,
      upperLimit: 82386.80,
      fixedFee: 6292.32,
      porcentage: .1792,
      year: 2017,
      month: 8
    },
    {
      id: 83,
      lowerLimit: 82386.81,
      upperLimit: 166162.32,
      fixedFee: 8724.88,
      porcentage: .2136,
      year: 2017,
      month: 8
    },
    {
      id: 84,
      lowerLimit: 166162.33,
      upperLimit: 261894.64,
      fixedFee: 26619.36,
      porcentage: .2352,
      year: 2017,
      month: 8
    },
    {
      id: 85,
      lowerLimit: 261894.65,
      upperLimit: 500000.00,
      fixedFee: 49135.60,
      porcentage: .3000,
      year: 2017,
      month: 8
    },
    {
      id: 86,
      lowerLimit: 500000.01,
      upperLimit: 666666.64,
      fixedFee: 120567.20,
      porcentage: .3200,
      year: 2017,
      month: 8
    },
    {
      id: 87,
      lowerLimit: 666666.65,
      upperLimit: 2000000.00,
      fixedFee: 173900.56,
      porcentage: .3400,
      year: 2017,
      month: 8
    },
    {
      id: 88,
      lowerLimit: 2000000.01,
      upperLimit: null,
      fixedFee: 627233.84,
      porcentage: .3500,
      year: 2017,
      month: 8
    },
    {
      id: 89,
      lowerLimit: 0.01,
      upperLimit: 4464.63,
      fixedFee: 0.00,
      porcentage: .0192,
      year: 2017,
      month: 9
    },
    {
      id: 90,
      lowerLimit: 4464.64,
      upperLimit: 37893.69,
      fixedFee: 85.68,
      porcentage: .0640,
      year: 2017,
      month: 9
    },
    {
      id: 91,
      lowerLimit: 37893.70,
      upperLimit: 66594.78,
      fixedFee: 2225.16,
      porcentage: .1088,
      year: 2017,
      month: 9
    },
    {
      id: 92,
      lowerLimit: 66594.79,
      upperLimit: 77413.50,
      fixedFee: 5347.89,
      porcentage: .16,
      year: 2017,
      month: 9
    },
    {
      id: 93,
      lowerLimit: 77413.51,
      upperLimit: 92685.15,
      fixedFee: 7078.86,
      porcentage: .1792,
      year: 2017,
      month: 9
    },
    {
      id: 94,
      lowerLimit: 92685.16,
      upperLimit: 186932.61,
      fixedFee: 9815.49,
      porcentage: .2136,
      year: 2017,
      month: 9
    },
    {
      id: 95,
      lowerLimit: 186932.62,
      upperLimit: 294631.47,
      fixedFee: 29946.78,
      porcentage: .2352,
      year: 2017,
      month: 9
    },
    {
      id: 96,
      lowerLimit: 294631.48,
      upperLimit: 562500.00,
      fixedFee: 55277.55,
      porcentage: .3000,
      year: 2017,
      month: 9
    },
    {
      id: 97,
      lowerLimit: 562500.01,
      upperLimit: 749999.97,
      fixedFee: 135638.10,
      porcentage: .3200,
      year: 2017,
      month: 9
    },
    {
      id: 98,
      lowerLimit: 749999.98,
      upperLimit: 2250000.00,
      fixedFee: 195638.13,
      porcentage: .3400,
      year: 2017,
      month: 9
    },
    {
      id: 99,
      lowerLimit: 2250000.01,
      upperLimit: null,
      fixedFee: 705638.07,
      porcentage: .3500,
      year: 2017,
      month: 9
    },
    {
      id: 100,
      lowerLimit: 0.01,
      upperLimit: 4960.70,
      fixedFee: 0.00,
      porcentage: .0192,
      year: 2017,
      month: 10
    },
    {
      id: 101,
      lowerLimit: 4960.71,
      upperLimit: 42104.10,
      fixedFee: 95.20,
      porcentage: .0640,
      year: 2017,
      month: 10
    },
    {
      id: 102,
      lowerLimit: 42104.11,
      upperLimit: 73994.20,
      fixedFee: 2472.40,
      porcentage: .1088,
      year: 2017,
      month: 10
    },
    {
      id: 103,
      lowerLimit: 73994.21,
      upperLimit: 86015.00,
      fixedFee: 5942.10,
      porcentage: .1600,
      year: 2017,
      month: 10
    },
    {
      id: 104,
      lowerLimit: 86015.01,
      upperLimit: 102983.50,
      fixedFee: 7865.40,
      porcentage: .1792,
      year: 2017,
      month: 10
    },
    {
      id: 105,
      lowerLimit: 102983.51,
      upperLimit: 207702.90,
      fixedFee: 10906.10,
      porcentage: .2136,
      year: 2017,
      month: 10
    },
    {
      id: 106,
      lowerLimit: 207702.91,
      upperLimit: 327368.30,
      fixedFee: 33274.20,
      porcentage: .2352,
      year: 2017,
      month: 10
    },
    {
      id: 107,
      lowerLimit: 327368.31,
      upperLimit: 625000.00,
      fixedFee: 61419.50,
      porcentage: .3000,
      year: 2017,
      month: 10
    },
    {
      id: 108,
      lowerLimit: 625000.01,
      upperLimit: 833333.30,
      fixedFee: 150709.00,
      porcentage: .3200,
      year: 2017,
      month: 10
    },
    {
      id: 109,
      lowerLimit: 833333.31,
      upperLimit: 2500000.00,
      fixedFee: 217375.70,
      porcentage: .3400,
      year: 2017,
      month: 10
    },
    {
      id: 110,
      lowerLimit: 2500000.01,
      upperLimit: null,
      fixedFee: 784042.30,
      porcentage: .3500,
      year: 2017,
      month: 10
    },

    {
      id: 111,
      lowerLimit: 0.01,
      upperLimit: 5456.77,
      fixedFee: 0.00,
      porcentage: .0192,
      year: 2017,
      month: 11
    },
    {
      id: 112,
      lowerLimit: 5456.78,
      upperLimit: 46314.51,
      fixedFee: 104.72,
      porcentage: .0640,
      year: 2017,
      month: 11
    },
    {
      id: 113,
      lowerLimit: 46314.52,
      upperLimit: 81393.62,
      fixedFee: 2719.64,
      porcentage: .1088,
      year: 2017,
      month: 11
    },
    {
      id: 114,
      lowerLimit: 81393.63,
      upperLimit: 94616.50,
      fixedFee: 6536.31,
      porcentage: .1600,
      year: 2017,
      month: 11
    },
    {
      id: 115,
      lowerLimit: 94616.51,
      upperLimit: 113281.85,
      fixedFee: 8651.94,
      porcentage: .1792,
      year: 2017,
      month: 11
    },
    {
      id: 116,
      lowerLimit: 113281.86,
      upperLimit: 228473.19,
      fixedFee: 11996.71,
      porcentage: .2136,
      year: 2017,
      month: 11
    },
    {
      id: 117,
      lowerLimit: 228473.20,
      upperLimit: 360105.13,
      fixedFee: 36601.62,
      porcentage: .2352,
      year: 2017,
      month: 11
    },
    {
      id: 118,
      lowerLimit: 360105.14,
      upperLimit: 687500.00,
      fixedFee: 67561.45,
      porcentage: .3000,
      year: 2017,
      month: 11
    },
    {
      id: 119,
      lowerLimit: 687500.01,
      upperLimit: 916666.63,
      fixedFee: 165779.90,
      porcentage: .3200,
      year: 2017,
      month: 11
    },
    {
      id: 120,
      lowerLimit: 916666.64,
      upperLimit: 2750000.00,
      fixedFee: 239113.27,
      porcentage: .3400,
      year: 2017,
      month: 11
    },
    {
      id: 121,
      lowerLimit: 2750000.01,
      upperLimit: null,
      fixedFee: 862446.53,
      porcentage: .3500,
      year: 2017,
      month: 11
    },
    {
      id: 122,
      lowerLimit: 0.01,
      upperLimit: 5952.84,
      fixedFee: 0.00,
      porcentage: .0192,
      year: 2017,
      month: 12
    },
    {
      id: 123,
      lowerLimit: 5952.85,
      upperLimit: 50524.92,
      fixedFee: 114.29,
      porcentage: .0640,
      year: 2017,
      month: 12
    },
    {
      id: 124,
      lowerLimit: 50524.93,
      upperLimit: 88793.04,
      fixedFee: 2966.91,
      porcentage: .1088,
      year: 2017,
      month: 12
    },
    {
      id: 125,
      lowerLimit: 88793.05,
      upperLimit: 103218.00,
      fixedFee: 7130.48,
      porcentage: .1600,
      year: 2017,
      month: 12
    },
    {
      id: 126,
      lowerLimit: 103218.01,
      upperLimit: 123580.20,
      fixedFee: 9438.47,
      porcentage: .1792,
      year: 2017,
      month: 12
    },
    {
      id: 127,
      lowerLimit: 123580.21,
      upperLimit: 249243.48,
      fixedFee: 13087.37,
      porcentage: .2136,
      year: 2017,
      month: 12
    },
    {
      id: 128,
      lowerLimit: 249243.49,
      upperLimit: 392841.96,
      fixedFee: 39929.05,
      porcentage: .2352,
      year: 2017,
      month: 12
    },
    {
      id: 129,
      lowerLimit: 392841.97,
      upperLimit: 750000.00,
      fixedFee: 73703.41,
      porcentage: .3000,
      year: 2017,
      month: 12
    },
    {
      id: 130,
      lowerLimit: 750000.01,
      upperLimit: 1000000.00,
      fixedFee: 180850.82,
      porcentage: .3200,
      year: 2017,
      month: 12
    },
    {
      id: 131,
      lowerLimit: 1000000.01,
      upperLimit: 3000000.00,
      fixedFee: 260850.81,
      porcentage: .3400,
      year: 2017,
      month: 12
    },
    {
      id: 132,
      lowerLimit: 3000000.01,
      upperLimit: null,
      fixedFee: 940850.81,
      porcentage: .3500,
      year: 2017,
      month: 12
    },

    {
      id: 1,
      lowerLimit: 0.01,
      upperLimit: 578.52,
      fixedFee: 0.00,
      porcentage: 0.0192,
      year: 2018,
      month: 1,
      isrRetention: 2
    },
    {
      id: 2,
      lowerLimit: 578.53,
      upperLimit: 4910.18,
      fixedFee: 11.11,
      porcentage: 0.064,
      year: 2018,
      month: 1,
      isrRetention: 2
    },
    {
      id: 3,
      lowerLimit: 4910.19,
      upperLimit: 8629.2,
      fixedFee: 288.33,
      porcentage: 0.1088,
      year: 2018,
      month: 1,
      isrRetention: 2
    },
    {
      id: 4,
      lowerLimit: 8629.21,
      upperLimit: 10031.07,
      fixedFee: 692.96,
      porcentage: 0.16,
      year: 2018,
      month: 1,
      isrRetention: 2
    },
    {
      id: 5,
      lowerLimit: 10031.08,
      upperLimit: 120009.94,
      fixedFee: 917.26,
      porcentage: 0.1792,
      year: 2018,
      month: 1,
      isrRetention: 2
    },
    {
      id: 6,
      lowerLimit: 120009.95,
      upperLimit: 24222.31,
      fixedFee: 1271.87,
      porcentage: 0.2136,
      year: 2018,
      month: 1,
      isrRetention: 2
    },
    {
      id: 7,
      lowerLimit: 24222.32,
      upperLimit: 38177.69,
      fixedFee: 3880.44,
      porcentage: 0.2352,
      year: 2018,
      month: 1,
      isrRetention: 2
    },
    {
      id: 8,
      lowerLimit: 38177.7,
      upperLimit: 72887.5,
      fixedFee: 7162.74,
      porcentage: 0.3,
      year: 2018,
      month: 1,
      isrRetention: 2
    },
    {
      id: 9,
      lowerLimit: 72887.51,
      upperLimit: 97183.33,
      fixedFee: 17575.69,
      porcentage: 0.32,
      year: 2018,
      month: 1,
      isrRetention: 2
    },
    {
      id: 10,
      lowerLimit: 97183.34,
      upperLimit: 291550,
      fixedFee: 25350.35,
      porcentage: 0.34,
      year: 2018,
      month: 1,
      isrRetention: 2
    },
    {
      id: 11,
      lowerLimit: 291550.01,
      upperLimit: null,
      fixedFee: 91435.02,
      porcentage: 0.35,
      year: 2018,
      month: 1,
      isrRetention: 2
    },
    {
      id: 12,
      lowerLimit: 0.01,
      upperLimit: 1157.04,
      fixedFee: 0,
      porcentage: 0.0192,
      year: 2018,
      month: 2,
      isrRetention: 4
    },
    {
      id: 13,
      lowerLimit: 1157.05,
      upperLimit: 9820.36,
      fixedFee: 22.22,
      porcentage: 0.064,
      year: 2018,
      month: 2,
      isrRetention: 4
    },
    {
      id: 14,
      lowerLimit: 9820.37,
      upperLimit: 17258.4,
      fixedFee: 576.66,
      porcentage: 0.1088,
      year: 2018,
      month: 2,
      isrRetention: 4
    },
    {
      id: 15,
      lowerLimit: 17258.41,
      upperLimit: 20062.14,
      fixedFee: 1385.92,
      porcentage: 0.16,
      year: 2018,
      month: 2,
      isrRetention: 4
    },
    {
      id: 16,
      lowerLimit: 20062.15,
      upperLimit: 240019.88,
      fixedFee: 1834.52,
      porcentage: 0.1792,
      year: 2018,
      month: 2,
      isrRetention: 4
    },
    {
      id: 17,
      lowerLimit: 240019.89,
      upperLimit: 48444.62,
      fixedFee: 2543.74,
      porcentage: 0.2136,
      year: 2018,
      month: 2,
      isrRetention: 4
    },
    {
      id: 18,
      lowerLimit: 48444.63,
      upperLimit: 76355.38,
      fixedFee: 7760.88,
      porcentage: 0.2352,
      year: 2018,
      month: 2,
      isrRetention: 4
    },
    {
      id: 19,
      lowerLimit: 76355.39,
      upperLimit: 145775,
      fixedFee: 14325.48,
      porcentage: 0.3,
      year: 2018,
      month: 2,
      isrRetention: 4
    },
    {
      id: 20,
      lowerLimit: 145775.01,
      upperLimit: 194366.66,
      fixedFee: 35151.38,
      porcentage: 0.32,
      year: 2018,
      month: 2,
      isrRetention: 4
    },
    {
      id: 21,
      lowerLimit: 194366.67,
      upperLimit: 583100,
      fixedFee: 50700.7,
      porcentage: 0.34,
      year: 2018,
      month: 2,
      isrRetention: 4
    },
    {
      id: 22,
      lowerLimit: 583100.01,
      upperLimit: null,
      fixedFee: 182870.04,
      porcentage: 0.35,
      year: 2018,
      month: 2,
      isrRetention: 4
    },
    {
      id: 23,
      lowerLimit: 0.01,
      upperLimit: 1735.56,
      fixedFee: 0,
      porcentage: 0.0192,
      year: 2018,
      month: 3,
      isrRetention: 6
    },
    {
      id: 24,
      lowerLimit: 1735.57,
      upperLimit: 14730.54,
      fixedFee: 33.33,
      porcentage: 0.064,
      year: 2018,
      month: 3,
      isrRetention: 6
    },
    {
      id: 25,
      lowerLimit: 14730.55,
      upperLimit: 25887.6,
      fixedFee: 864.99,
      porcentage: 0.1088,
      year: 2018,
      month: 3,
      isrRetention: 6
    },
    {
      id: 26,
      lowerLimit: 25887.61,
      upperLimit: 30093.21,
      fixedFee: 2078.88,
      porcentage: 0.16,
      year: 2018,
      month: 3,
      isrRetention: 6
    },
    {
      id: 27,
      lowerLimit: 30093.22,
      upperLimit: 360029.82,
      fixedFee: 2751.78,
      porcentage: 0.1792,
      year: 2018,
      month: 3,
      isrRetention: 6
    },
    {
      id: 28,
      lowerLimit: 360029.83,
      upperLimit: 72666.93,
      fixedFee: 3815.61,
      porcentage: 0.2136,
      year: 2018,
      month: 3,
      isrRetention: 6
    },
    {
      id: 29,
      lowerLimit: 72666.94,
      upperLimit: 114533.07,
      fixedFee: 11641.32,
      porcentage: 0.2352,
      year: 2018,
      month: 3,
      isrRetention: 6
    },
    {
      id: 30,
      lowerLimit: 114533.08,
      upperLimit: 218662.5,
      fixedFee: 21488.22,
      porcentage: 0.3,
      year: 2018,
      month: 3,
      isrRetention: 6
    },
    {
      id: 31,
      lowerLimit: 218662.51,
      upperLimit: 291549.99,
      fixedFee: 52727.07,
      porcentage: 0.32,
      year: 2018,
      month: 3,
      isrRetention: 6
    },
    {
      id: 32,
      lowerLimit: 291550,
      upperLimit: 874650,
      fixedFee: 76051.05,
      porcentage: 0.34,
      year: 2018,
      month: 3,
      isrRetention: 6
    },
    {
      id: 33,
      lowerLimit: 874650.01,
      upperLimit: null,
      fixedFee: 274305.06,
      porcentage: 0.35,
      year: 2018,
      month: 3,
      isrRetention: 6
    },
    {
      id: 34,
      lowerLimit: 0.01,
      upperLimit: 2314.08,
      fixedFee: 0,
      porcentage: 0.0192,
      year: 2018,
      month: 4,
      isrRetention: 6
    },
    {
      id: 35,
      lowerLimit: 2314.09,
      upperLimit: 19640.72,
      fixedFee: 44.44,
      porcentage: 0.064,
      year: 2018,
      month: 4,
      isrRetention: 8
    },
    {
      id: 36,
      lowerLimit: 19640.73,
      upperLimit: 34516.8,
      fixedFee: 1153.32,
      porcentage: 0.1088,
      year: 2018,
      month: 4,
      isrRetention: 8
    },
    {
      id: 37,
      lowerLimit: 34516.81,
      upperLimit: 40124.28,
      fixedFee: 2771.84,
      porcentage: 0.16,
      year: 2018,
      month: 4,
      isrRetention: 8
    },
    {
      id: 38,
      lowerLimit: 40124.29,
      upperLimit: 480039.76,
      fixedFee: 3669.04,
      porcentage: 0.1792,
      year: 2018,
      month: 4,
      isrRetention: 8
    },
    {
      id: 39,
      lowerLimit: 480039.77,
      upperLimit: 96889.24,
      fixedFee: 5087.48,
      porcentage: 0.2136,
      year: 2018,
      month: 4,
      isrRetention: 8
    },
    {
      id: 40,
      lowerLimit: 96889.25,
      upperLimit: 152710.76,
      fixedFee: 15521.76,
      porcentage: 0.2352,
      year: 2018,
      month: 4,
      isrRetention: 8
    },
    {
      id: 41,
      lowerLimit: 152710.77,
      upperLimit: 291550,
      fixedFee: 28650.96,
      porcentage: 0.3,
      year: 2018,
      month: 4,
      isrRetention: 8
    },
    {
      id: 42,
      lowerLimit: 291550.01,
      upperLimit: 388733.32,
      fixedFee: 70302.76,
      porcentage: 0.32,
      year: 2018,
      month: 4,
      isrRetention: 8
    },
    {
      id: 43,
      lowerLimit: 388733.33,
      upperLimit: 1166200,
      fixedFee: 101401.4,
      porcentage: 0.34,
      year: 2018,
      month: 4,
      isrRetention: 8
    },
    {
      id: 44,
      lowerLimit: 1166200.01,
      upperLimit: null,
      fixedFee: 365740.08,
      porcentage: 0.35,
      year: 2018,
      month: 4,
      isrRetention: null
    },
    {
      id: 45,
      lowerLimit: 0.01,
      upperLimit: 2892.6,
      fixedFee: 0,
      porcentage: 0.0192,
      year: 2018,
      month: 5,
      isrRetention: null
    },
    {
      id: 46,
      lowerLimit: 2892.61,
      upperLimit: 24550.9,
      fixedFee: 55.55,
      porcentage: 0.064,
      year: 2018,
      month: 5,
      isrRetention: null
    },
    {
      id: 47,
      lowerLimit: 24550.91,
      upperLimit: 43146,
      fixedFee: 1441.65,
      porcentage: 0.1088,
      year: 2018,
      month: 5,
      isrRetention: null
    },
    {
      id: 48,
      lowerLimit: 43146.01,
      upperLimit: 50155.35,
      fixedFee: 3464.8,
      porcentage: 0.16,
      year: 2018,
      month: 5,
      isrRetention: null
    },
    {
      id: 49,
      lowerLimit: 50155.36,
      upperLimit: 600049.7,
      fixedFee: 4586.3,
      porcentage: 0.1792,
      year: 2018,
      month: 5,
      isrRetention: null
    },
    {
      id: 50,
      lowerLimit: 600049.71,
      upperLimit: 121111.55,
      fixedFee: 6359.35,
      porcentage: 0.2136,
      year: 2018,
      month: 5,
      isrRetention: null
    },
    {
      id: 51,
      lowerLimit: 121111.56,
      upperLimit: 190888.45,
      fixedFee: 19402.2,
      porcentage: 0.2352,
      year: 2018,
      month: 5,
      isrRetention: null
    },
    {
      id: 52,
      lowerLimit: 190888.46,
      upperLimit: 364437.5,
      fixedFee: 35813.7,
      porcentage: 0.3,
      year: 2018,
      month: 5,
      isrRetention: null
    },
    {
      id: 53,
      lowerLimit: 364437.51,
      upperLimit: 485916.65,
      fixedFee: 87878.45,
      porcentage: 0.32,
      year: 2018,
      month: 5,
      isrRetention: null
    },
    {
      id: 54,
      lowerLimit: 485916.66,
      upperLimit: 1457750,
      fixedFee: 126751.75,
      porcentage: 0.34,
      year: 2018,
      month: 5,
      isrRetention: null
    },
    {
      id: 55,
      lowerLimit: 1457750.01,
      upperLimit: null,
      fixedFee: 457175.1,
      porcentage: 0.35,
      year: 2018,
      month: 5,
      isrRetention: null
    },
    {
      id: 56,
      lowerLimit: 0.01,
      upperLimit: 3471.12,
      fixedFee: 0,
      porcentage: 0.0192,
      year: 2018,
      month: 6,
      isrRetention: null
    },
    {
      id: 57,
      lowerLimit: 3471.13,
      upperLimit: 29461.08,
      fixedFee: 66.66,
      porcentage: 0.064,
      year: 2018,
      month: 6,
      isrRetention: null
    },
    {
      id: 58,
      lowerLimit: 29461.09,
      upperLimit: 51775.2,
      fixedFee: 1729.98,
      porcentage: 0.1088,
      year: 2018,
      month: 6,
      isrRetention: null
    },
    {
      id: 59,
      lowerLimit: 51775.21,
      upperLimit: 60186.42,
      fixedFee: 4157.76,
      porcentage: 0.16,
      year: 2018,
      month: 6,
      isrRetention: null
    },
    {
      id: 60,
      lowerLimit: 60186.43,
      upperLimit: 720059.64,
      fixedFee: 5503.56,
      porcentage: 0.1792,
      year: 2018,
      month: 6,
      isrRetention: null
    },
    {
      id: 61,
      lowerLimit: 720059.65,
      upperLimit: 145333.86,
      fixedFee: 7631.22,
      porcentage: 0.2136,
      year: 2018,
      month: 6,
      isrRetention: null
    },
    {
      id: 62,
      lowerLimit: 145333.87,
      upperLimit: 229066.14,
      fixedFee: 23282.64,
      porcentage: 0.2352,
      year: 2018,
      month: 6,
      isrRetention: null
    },
    {
      id: 63,
      lowerLimit: 229066.15,
      upperLimit: 437325,
      fixedFee: 42976.44,
      porcentage: 0.3,
      year: 2018,
      month: 6,
      isrRetention: null
    },
    {
      id: 64,
      lowerLimit: 437325.01,
      upperLimit: 583099.98,
      fixedFee: 105454.14,
      porcentage: 0.32,
      year: 2018,
      month: 6,
      isrRetention: null
    },
    {
      id: 65,
      lowerLimit: 583099.99,
      upperLimit: 1749300,
      fixedFee: 152102.1,
      porcentage: 0.34,
      year: 2018,
      month: 6,
      isrRetention: null
    },
    {
      id: 66,
      lowerLimit: 1749300.01,
      upperLimit: null,
      fixedFee: 548610.12,
      porcentage: 0.35,
      year: 2018,
      month: 6,
      isrRetention: null
    },
    {
      id: 67,
      lowerLimit: 0.01,
      upperLimit: 4049.64,
      fixedFee: 0,
      porcentage: 0.0192,
      year: 2018,
      month: 7,
      isrRetention: null
    },
    {
      id: 68,
      lowerLimit: 4049.65,
      upperLimit: 34371.26,
      fixedFee: 77.77,
      porcentage: 0.064,
      year: 2018,
      month: 7,
      isrRetention: null
    },
    {
      id: 69,
      lowerLimit: 34371.27,
      upperLimit: 60404.4,
      fixedFee: 2018.31,
      porcentage: 0.1088,
      year: 2018,
      month: 7,
      isrRetention: null
    },
    {
      id: 70,
      lowerLimit: 60404.41,
      upperLimit: 70217.49,
      fixedFee: 4850.72,
      porcentage: 0.16,
      year: 2018,
      month: 7,
      isrRetention: null
    },
    {
      id: 71,
      lowerLimit: 70217.5,
      upperLimit: 840069.58,
      fixedFee: 6420.82,
      porcentage: 0.1792,
      year: 2018,
      month: 7,
      isrRetention: null
    },
    {
      id: 72,
      lowerLimit: 840069.59,
      upperLimit: 169556.17,
      fixedFee: 8903.09,
      porcentage: 0.2136,
      year: 2018,
      month: 7,
      isrRetention: null
    },
    {
      id: 73,
      lowerLimit: 169556.18,
      upperLimit: 267243.83,
      fixedFee: 27163.08,
      porcentage: 0.2352,
      year: 2018,
      month: 7,
      isrRetention: null
    },
    {
      id: 74,
      lowerLimit: 267243.84,
      upperLimit: 510212.5,
      fixedFee: 50139.18,
      porcentage: 0.3,
      year: 2018,
      month: 7,
      isrRetention: null
    },
    {
      id: 75,
      lowerLimit: 510212.51,
      upperLimit: 680283.31,
      fixedFee: 123029.83,
      porcentage: 0.32,
      year: 2018,
      month: 7,
      isrRetention: null
    },
    {
      id: 76,
      lowerLimit: 680283.32,
      upperLimit: 2040850,
      fixedFee: 177452.45,
      porcentage: 0.34,
      year: 2018,
      month: 7,
      isrRetention: null
    },
    {
      id: 77,
      lowerLimit: 2040850.01,
      upperLimit: null,
      fixedFee: 640045.14,
      porcentage: 0.35,
      year: 2018,
      month: 7,
      isrRetention: null
    },
    {
      id: 78,
      lowerLimit: 0.01,
      upperLimit: 4628.16,
      fixedFee: 0,
      porcentage: 0.0192,
      year: 2018,
      month: 8,
      isrRetention: null
    },
    {
      id: 79,
      lowerLimit: 4628.17,
      upperLimit: 39281.44,
      fixedFee: 88.88,
      porcentage: 0.064,
      year: 2018,
      month: 8,
      isrRetention: null
    },
    {
      id: 80,
      lowerLimit: 39281.45,
      upperLimit: 69033.6,
      fixedFee: 2306.64,
      porcentage: 0.1088,
      year: 2018,
      month: 8,
      isrRetention: null
    },
    {
      id: 81,
      lowerLimit: 69033.61,
      upperLimit: 80248.56,
      fixedFee: 5543.68,
      porcentage: 0.16,
      year: 2018,
      month: 8,
      isrRetention: null
    },
    {
      id: 82,
      lowerLimit: 80248.57,
      upperLimit: 960079.52,
      fixedFee: 7338.08,
      porcentage: 0.1792,
      year: 2018,
      month: 8,
      isrRetention: null
    },
    {
      id: 83,
      lowerLimit: 960079.53,
      upperLimit: 193778.48,
      fixedFee: 10174.96,
      porcentage: 0.2136,
      year: 2018,
      month: 8,
      isrRetention: null
    },
    {
      id: 84,
      lowerLimit: 193778.49,
      upperLimit: 305421.52,
      fixedFee: 31043.52,
      porcentage: 0.2352,
      year: 2018,
      month: 8,
      isrRetention: null
    },
    {
      id: 85,
      lowerLimit: 305421.53,
      upperLimit: 583100,
      fixedFee: 57301.92,
      porcentage: 0.3,
      year: 2018,
      month: 8,
      isrRetention: null
    },
    {
      id: 86,
      lowerLimit: 583100.01,
      upperLimit: 777466.64,
      fixedFee: 140605.52,
      porcentage: 0.32,
      year: 2018,
      month: 8,
      isrRetention: null
    },
    {
      id: 87,
      lowerLimit: 777466.65,
      upperLimit: 2332400,
      fixedFee: 202802.8,
      porcentage: 0.34,
      year: 2018,
      month: 8,
      isrRetention: null
    },
    {
      id: 88,
      lowerLimit: 2332400.01,
      upperLimit: null,
      fixedFee: 731480.16,
      porcentage: 0.35,
      year: 2018,
      month: 8,
      isrRetention: null
    },
    {
      id: 89,
      lowerLimit: 0.01,
      upperLimit: 5206.68,
      fixedFee: 0,
      porcentage: 0.0192,
      year: 2018,
      month: 9,
      isrRetention: null
    },
    {
      id: 90,
      lowerLimit: 5206.69,
      upperLimit: 44191.62,
      fixedFee: 99.99,
      porcentage: 0.064,
      year: 2018,
      month: 9,
      isrRetention: null
    },
    {
      id: 91,
      lowerLimit: 44191.63,
      upperLimit: 77662.8,
      fixedFee: 2594.97,
      porcentage: 0.1088,
      year: 2018,
      month: 9,
      isrRetention: null
    },
    {
      id: 92,
      lowerLimit: 77662.81,
      upperLimit: 90279.63,
      fixedFee: 6236.64,
      porcentage: 0.16,
      year: 2018,
      month: 9,
      isrRetention: null
    },
    {
      id: 93,
      lowerLimit: 90279.64,
      upperLimit: 1080089.46,
      fixedFee: 8255.34,
      porcentage: 0.1792,
      year: 2018,
      month: 9,
      isrRetention: null
    },
    {
      id: 94,
      lowerLimit: 1080089.47,
      upperLimit: 218000.79,
      fixedFee: 11446.83,
      porcentage: 0.2136,
      year: 2018,
      month: 9,
      isrRetention: null
    },
    {
      id: 95,
      lowerLimit: 218000.8,
      upperLimit: 343599.21,
      fixedFee: 34923.96,
      porcentage: 0.2352,
      year: 2018,
      month: 9,
      isrRetention: null
    },
    {
      id: 96,
      lowerLimit: 343599.22,
      upperLimit: 655987.5,
      fixedFee: 64464.66,
      porcentage: 0.3,
      year: 2018,
      month: 9,
      isrRetention: null
    },
    {
      id: 97,
      lowerLimit: 655987.51,
      upperLimit: 874649.97,
      fixedFee: 158181.21,
      porcentage: 0.32,
      year: 2018,
      month: 9,
      isrRetention: null
    },
    {
      id: 98,
      lowerLimit: 874649.98,
      upperLimit: 2623950,
      fixedFee: 228153.15,
      porcentage: 0.34,
      year: 2018,
      month: 9,
      isrRetention: null
    },
    {
      id: 99,
      lowerLimit: 2623950.01,
      upperLimit: null,
      fixedFee: 822915.18,
      porcentage: 0.35,
      year: 2018,
      month: 9,
      isrRetention: null
    },
    {
      id: 100,
      lowerLimit: 0.01,
      upperLimit: 5785.2,
      fixedFee: 0,
      porcentage: 0.0192,
      year: 2018,
      month: 10,
      isrRetention: null
    },
    {
      id: 101,
      lowerLimit: 5785.21,
      upperLimit: 49101.8,
      fixedFee: 111.1,
      porcentage: 0.064,
      year: 2018,
      month: 10,
      isrRetention: null
    },
    {
      id: 102,
      lowerLimit: 49101.81,
      upperLimit: 86292,
      fixedFee: 2883.3,
      porcentage: 0.1088,
      year: 2018,
      month: 10,
      isrRetention: null
    },
    {
      id: 103,
      lowerLimit: 86292.01,
      upperLimit: 100310.7,
      fixedFee: 6929.6,
      porcentage: 0.16,
      year: 2018,
      month: 10,
      isrRetention: null
    },
    {
      id: 104,
      lowerLimit: 100310.71,
      upperLimit: 1200099.4,
      fixedFee: 9172.6,
      porcentage: 0.1792,
      year: 2018,
      month: 10,
      isrRetention: null
    },
    {
      id: 105,
      lowerLimit: 1200099.41,
      upperLimit: 242223.1,
      fixedFee: 12718.7,
      porcentage: 0.2136,
      year: 2018,
      month: 10,
      isrRetention: null
    },
    {
      id: 106,
      lowerLimit: 242223.11,
      upperLimit: 381776.9,
      fixedFee: 38804.4,
      porcentage: 0.2352,
      year: 2018,
      month: 10,
      isrRetention: null
    },
    {
      id: 107,
      lowerLimit: 381776.91,
      upperLimit: 728875,
      fixedFee: 71627.4,
      porcentage: 0.3,
      year: 2018,
      month: 10,
      isrRetention: null
    },
    {
      id: 108,
      lowerLimit: 728875.01,
      upperLimit: 971833.3,
      fixedFee: 175756.9,
      porcentage: 0.32,
      year: 2018,
      month: 10,
      isrRetention: null
    },
    {
      id: 109,
      lowerLimit: 971833.31,
      upperLimit: 2915500,
      fixedFee: 253503.5,
      porcentage: 0.34,
      year: 2018,
      month: 10,
      isrRetention: null
    },
    {
      id: 110,
      lowerLimit: 2915500.01,
      upperLimit: null,
      fixedFee: 914350.2,
      porcentage: 0.35,
      year: 2018,
      month: 10,
      isrRetention: null
    },
    {
      id: 111,
      lowerLimit: 0.01,
      upperLimit: 6363.72,
      fixedFee: 0,
      porcentage: 0.0192,
      year: 2018,
      month: 11,
      isrRetention: null
    },
    {
      id: 112,
      lowerLimit: 6363.73,
      upperLimit: 54011.98,
      fixedFee: 122.21,
      porcentage: 0.064,
      year: 2018,
      month: 11,
      isrRetention: null
    },
    {
      id: 113,
      lowerLimit: 54011.99,
      upperLimit: 94921.2,
      fixedFee: 3171.63,
      porcentage: 0.1088,
      year: 2018,
      month: 11,
      isrRetention: null
    },
    {
      id: 114,
      lowerLimit: 94921.21,
      upperLimit: 110341.77,
      fixedFee: 7622.56,
      porcentage: 0.16,
      year: 2018,
      month: 11,
      isrRetention: null
    },
    {
      id: 115,
      lowerLimit: 110341.78,
      upperLimit: 1320109.34,
      fixedFee: 10089.86,
      porcentage: 0.1792,
      year: 2018,
      month: 11,
      isrRetention: null
    },
    {
      id: 116,
      lowerLimit: 1320109.35,
      upperLimit: 266445.41,
      fixedFee: 13990.57,
      porcentage: 0.2136,
      year: 2018,
      month: 11,
      isrRetention: null
    },
    {
      id: 117,
      lowerLimit: 266445.42,
      upperLimit: 419954.59,
      fixedFee: 42684.84,
      porcentage: 0.2352,
      year: 2018,
      month: 11,
      isrRetention: null
    },
    {
      id: 118,
      lowerLimit: 419954.6,
      upperLimit: 801762.5,
      fixedFee: 78790.14,
      porcentage: 0.3,
      year: 2018,
      month: 11,
      isrRetention: null
    },
    {
      id: 119,
      lowerLimit: 801762.51,
      upperLimit: 1069016.63,
      fixedFee: 193332.59,
      porcentage: 0.32,
      year: 2018,
      month: 11,
      isrRetention: null
    },
    {
      id: 120,
      lowerLimit: 1069016.64,
      upperLimit: 3207050,
      fixedFee: 278853.85,
      porcentage: 0.34,
      year: 2018,
      month: 11,
      isrRetention: null
    },
    {
      id: 121,
      lowerLimit: 3207050.01,
      upperLimit: null,
      fixedFee: 1005785.22,
      porcentage: 0.35,
      year: 2018,
      month: 11,
      isrRetention: null
    },
    {
      id: 122,
      lowerLimit: 0.01,
      upperLimit: 6942.24,
      fixedFee: 0,
      porcentage: 0.0192,
      year: 2018,
      month: 12,
      isrRetention: null
    },
    {
      id: 123,
      lowerLimit: 6942.25,
      upperLimit: 58922.16,
      fixedFee: 133.32,
      porcentage: 0.064,
      year: 2018,
      month: 12,
      isrRetention: null
    },
    {
      id: 124,
      lowerLimit: 58922.17,
      upperLimit: 103550.4,
      fixedFee: 3459.96,
      porcentage: 0.1088,
      year: 2018,
      month: 12,
      isrRetention: null
    },
    {
      id: 125,
      lowerLimit: 103550.41,
      upperLimit: 120372.84,
      fixedFee: 8315.52,
      porcentage: 0.16,
      year: 2018,
      month: 12,
      isrRetention: null
    },
    {
      id: 126,
      lowerLimit: 120372.85,
      upperLimit: 1440119.28,
      fixedFee: 11007.12,
      porcentage: 0.1792,
      year: 2018,
      month: 12,
      isrRetention: null
    },
    {
      id: 127,
      lowerLimit: 1440119.29,
      upperLimit: 290667.72,
      fixedFee: 15262.44,
      porcentage: 0.2136,
      year: 2018,
      month: 12,
      isrRetention: null
    },
    {
      id: 128,
      lowerLimit: 290667.73,
      upperLimit: 458132.28,
      fixedFee: 46565.28,
      porcentage: 0.2352,
      year: 2018,
      month: 12,
      isrRetention: null
    },
    {
      id: 129,
      lowerLimit: 458132.29,
      upperLimit: 874650,
      fixedFee: 85952.88,
      porcentage: 0.3,
      year: 2018,
      month: 12,
      isrRetention: null
    },
    {
      id: 130,
      lowerLimit: 874650.01,
      upperLimit: 1166199.96,
      fixedFee: 210908.28,
      porcentage: 0.32,
      year: 2018,
      month: 12,
      isrRetention: null
    },
    {
      id: 131,
      lowerLimit: 1166199.97,
      upperLimit: 3498600,
      fixedFee: 304204.2,
      porcentage: 0.34,
      year: 2018,
      month: 12,
      isrRetention: null
    },
    {
      id: 132,
      lowerLimit: 3498600.01,
      upperLimit: null,
      fixedFee: 1097220.24,
      porcentage: 0.35,
      year: 2018,
      month: 12,
      isrRetention: null
    },


    {
      id: 1,
      lowerLimit: 0.01,
      upperLimit: 578.52,
      fixedFee: 0.00,
      porcentage: 0.0192,
      year: 2019,
      month: 1,
      isrRetention: 2
    },
    {
      id: 2,
      lowerLimit: 578.53,
      upperLimit: 4910.18,
      fixedFee: 11.11,
      porcentage: 0.064,
      year: 2019,
      month: 1,
      isrRetention: 2
    },
    {
      id: 3,
      lowerLimit: 4910.19,
      upperLimit: 8629.2,
      fixedFee: 288.33,
      porcentage: 0.1088,
      year: 2019,
      month: 1,
      isrRetention: 2
    },
    {
      id: 4,
      lowerLimit: 8629.21,
      upperLimit: 10031.07,
      fixedFee: 692.96,
      porcentage: 0.16,
      year: 2019,
      month: 1,
      isrRetention: 2
    },
    {
      id: 5,
      lowerLimit: 10031.08,
      upperLimit: 120009.94,
      fixedFee: 917.26,
      porcentage: 0.1792,
      year: 2019,
      month: 1,
      isrRetention: 2
    },
    {
      id: 6,
      lowerLimit: 120009.95,
      upperLimit: 24222.31,
      fixedFee: 1271.87,
      porcentage: 0.2136,
      year: 2019,
      month: 1,
      isrRetention: 2
    },
    {
      id: 7,
      lowerLimit: 24222.32,
      upperLimit: 38177.69,
      fixedFee: 3880.44,
      porcentage: 0.2352,
      year: 2019,
      month: 1,
      isrRetention: 2
    },
    {
      id: 8,
      lowerLimit: 38177.7,
      upperLimit: 72887.5,
      fixedFee: 7162.74,
      porcentage: 0.3,
      year: 2019,
      month: 1,
      isrRetention: 2
    },
    {
      id: 9,
      lowerLimit: 72887.51,
      upperLimit: 97183.33,
      fixedFee: 17575.69,
      porcentage: 0.32,
      year: 2019,
      month: 1,
      isrRetention: 2
    },
    {
      id: 10,
      lowerLimit: 97183.34,
      upperLimit: 291550,
      fixedFee: 25350.35,
      porcentage: 0.34,
      year: 2019,
      month: 1,
      isrRetention: 2
    },
    {
      id: 11,
      lowerLimit: 291550.01,
      upperLimit: null,
      fixedFee: 91435.02,
      porcentage: 0.35,
      year: 2019,
      month: 1,
      isrRetention: 2
    },
    {
      id: 12,
      lowerLimit: 0.01,
      upperLimit: 1157.04,
      fixedFee: 0,
      porcentage: 0.0192,
      year: 2019,
      month: 2,
      isrRetention: 4
    },
    {
      id: 13,
      lowerLimit: 1157.05,
      upperLimit: 9820.36,
      fixedFee: 22.22,
      porcentage: 0.064,
      year: 2019,
      month: 2,
      isrRetention: 4
    },
    {
      id: 14,
      lowerLimit: 9820.37,
      upperLimit: 17258.4,
      fixedFee: 576.66,
      porcentage: 0.1088,
      year: 2019,
      month: 2,
      isrRetention: 4
    },
    {
      id: 15,
      lowerLimit: 17258.41,
      upperLimit: 20062.14,
      fixedFee: 1385.92,
      porcentage: 0.16,
      year: 2019,
      month: 2,
      isrRetention: 4
    },
    {
      id: 16,
      lowerLimit: 20062.15,
      upperLimit: 240019.88,
      fixedFee: 1834.52,
      porcentage: 0.1792,
      year: 2019,
      month: 2,
      isrRetention: 4
    },
    {
      id: 17,
      lowerLimit: 240019.89,
      upperLimit: 48444.62,
      fixedFee: 2543.74,
      porcentage: 0.2136,
      year: 2019,
      month: 2,
      isrRetention: 4
    },
    {
      id: 18,
      lowerLimit: 48444.63,
      upperLimit: 76355.38,
      fixedFee: 7760.88,
      porcentage: 0.2352,
      year: 2019,
      month: 2,
      isrRetention: 4
    },
    {
      id: 19,
      lowerLimit: 76355.39,
      upperLimit: 145775,
      fixedFee: 14325.48,
      porcentage: 0.3,
      year: 2019,
      month: 2,
      isrRetention: 4
    },
    {
      id: 20,
      lowerLimit: 145775.01,
      upperLimit: 194366.66,
      fixedFee: 35151.38,
      porcentage: 0.32,
      year: 2019,
      month: 2,
      isrRetention: 4
    },
    {
      id: 21,
      lowerLimit: 194366.67,
      upperLimit: 583100,
      fixedFee: 50700.7,
      porcentage: 0.34,
      year: 2019,
      month: 2,
      isrRetention: 4
    },
    {
      id: 22,
      lowerLimit: 583100.01,
      upperLimit: null,
      fixedFee: 182870.04,
      porcentage: 0.35,
      year: 2019,
      month: 2,
      isrRetention: 4
    },
    {
      id: 23,
      lowerLimit: 0.01,
      upperLimit: 1735.56,
      fixedFee: 0,
      porcentage: 0.0192,
      year: 2019,
      month: 3,
      isrRetention: 6
    },
    {
      id: 24,
      lowerLimit: 1735.57,
      upperLimit: 14730.54,
      fixedFee: 33.33,
      porcentage: 0.064,
      year: 2019,
      month: 3,
      isrRetention: 6
    },
    {
      id: 25,
      lowerLimit: 14730.55,
      upperLimit: 25887.6,
      fixedFee: 864.99,
      porcentage: 0.1088,
      year: 2019,
      month: 3,
      isrRetention: 6
    },
    {
      id: 26,
      lowerLimit: 25887.61,
      upperLimit: 30093.21,
      fixedFee: 2078.88,
      porcentage: 0.16,
      year: 2019,
      month: 3,
      isrRetention: 6
    },
    {
      id: 27,
      lowerLimit: 30093.22,
      upperLimit: 360029.82,
      fixedFee: 2751.78,
      porcentage: 0.1792,
      year: 2019,
      month: 3,
      isrRetention: 6
    },
    {
      id: 28,
      lowerLimit: 360029.83,
      upperLimit: 72666.93,
      fixedFee: 3815.61,
      porcentage: 0.2136,
      year: 2019,
      month: 3,
      isrRetention: 6
    },
    {
      id: 29,
      lowerLimit: 72666.94,
      upperLimit: 114533.07,
      fixedFee: 11641.32,
      porcentage: 0.2352,
      year: 2019,
      month: 3,
      isrRetention: 6
    },
    {
      id: 30,
      lowerLimit: 114533.08,
      upperLimit: 218662.5,
      fixedFee: 21488.22,
      porcentage: 0.3,
      year: 2019,
      month: 3,
      isrRetention: 6
    },
    {
      id: 31,
      lowerLimit: 218662.51,
      upperLimit: 291549.99,
      fixedFee: 52727.07,
      porcentage: 0.32,
      year: 2019,
      month: 3,
      isrRetention: 6
    },
    {
      id: 32,
      lowerLimit: 291550,
      upperLimit: 874650,
      fixedFee: 76051.05,
      porcentage: 0.34,
      year: 2019,
      month: 3,
      isrRetention: 6
    },
    {
      id: 33,
      lowerLimit: 874650.01,
      upperLimit: null,
      fixedFee: 274305.06,
      porcentage: 0.35,
      year: 2019,
      month: 3,
      isrRetention: 6
    },
    {
      id: 34,
      lowerLimit: 0.01,
      upperLimit: 2314.08,
      fixedFee: 0,
      porcentage: 0.0192,
      year: 2019,
      month: 4,
      isrRetention: 6
    },
    {
      id: 35,
      lowerLimit: 2314.09,
      upperLimit: 19640.72,
      fixedFee: 44.44,
      porcentage: 0.064,
      year: 2019,
      month: 4,
      isrRetention: 8
    },
    {
      id: 36,
      lowerLimit: 19640.73,
      upperLimit: 34516.8,
      fixedFee: 1153.32,
      porcentage: 0.1088,
      year: 2019,
      month: 4,
      isrRetention: 8
    },
    {
      id: 37,
      lowerLimit: 34516.81,
      upperLimit: 40124.28,
      fixedFee: 2771.84,
      porcentage: 0.16,
      year: 2019,
      month: 4,
      isrRetention: 8
    },
    {
      id: 38,
      lowerLimit: 40124.29,
      upperLimit: 480039.76,
      fixedFee: 3669.04,
      porcentage: 0.1792,
      year: 2019,
      month: 4,
      isrRetention: 8
    },
    {
      id: 39,
      lowerLimit: 480039.77,
      upperLimit: 96889.24,
      fixedFee: 5087.48,
      porcentage: 0.2136,
      year: 2019,
      month: 4,
      isrRetention: 8
    },
    {
      id: 40,
      lowerLimit: 96889.25,
      upperLimit: 152710.76,
      fixedFee: 15521.76,
      porcentage: 0.2352,
      year: 2019,
      month: 4,
      isrRetention: 8
    },
    {
      id: 41,
      lowerLimit: 152710.77,
      upperLimit: 291550,
      fixedFee: 28650.96,
      porcentage: 0.3,
      year: 2019,
      month: 4,
      isrRetention: 8
    },
    {
      id: 42,
      lowerLimit: 291550.01,
      upperLimit: 388733.32,
      fixedFee: 70302.76,
      porcentage: 0.32,
      year: 2019,
      month: 4,
      isrRetention: 8
    },
    {
      id: 43,
      lowerLimit: 388733.33,
      upperLimit: 1166200,
      fixedFee: 101401.4,
      porcentage: 0.34,
      year: 2019,
      month: 4,
      isrRetention: 8
    },
    {
      id: 44,
      lowerLimit: 1166200.01,
      upperLimit: null,
      fixedFee: 365740.08,
      porcentage: 0.35,
      year: 2019,
      month: 4,
      isrRetention: null
    },
    {
      id: 45,
      lowerLimit: 0.01,
      upperLimit: 2892.6,
      fixedFee: 0,
      porcentage: 0.0192,
      year: 2019,
      month: 5,
      isrRetention: null
    },
    {
      id: 46,
      lowerLimit: 2892.61,
      upperLimit: 24550.9,
      fixedFee: 55.55,
      porcentage: 0.064,
      year: 2019,
      month: 5,
      isrRetention: null
    },
    {
      id: 47,
      lowerLimit: 24550.91,
      upperLimit: 43146,
      fixedFee: 1441.65,
      porcentage: 0.1088,
      year: 2019,
      month: 5,
      isrRetention: null
    },
    {
      id: 48,
      lowerLimit: 43146.01,
      upperLimit: 50155.35,
      fixedFee: 3464.8,
      porcentage: 0.16,
      year: 2019,
      month: 5,
      isrRetention: null
    },
    {
      id: 49,
      lowerLimit: 50155.36,
      upperLimit: 600049.7,
      fixedFee: 4586.3,
      porcentage: 0.1792,
      year: 2019,
      month: 5,
      isrRetention: null
    },
    {
      id: 50,
      lowerLimit: 600049.71,
      upperLimit: 121111.55,
      fixedFee: 6359.35,
      porcentage: 0.2136,
      year: 2019,
      month: 5,
      isrRetention: null
    },
    {
      id: 51,
      lowerLimit: 121111.56,
      upperLimit: 190888.45,
      fixedFee: 19402.2,
      porcentage: 0.2352,
      year: 2019,
      month: 5,
      isrRetention: null
    },
    {
      id: 52,
      lowerLimit: 190888.46,
      upperLimit: 364437.5,
      fixedFee: 35813.7,
      porcentage: 0.3,
      year: 2019,
      month: 5,
      isrRetention: null
    },
    {
      id: 53,
      lowerLimit: 364437.51,
      upperLimit: 485916.65,
      fixedFee: 87878.45,
      porcentage: 0.32,
      year: 2019,
      month: 5,
      isrRetention: null
    },
    {
      id: 54,
      lowerLimit: 485916.66,
      upperLimit: 1457750,
      fixedFee: 126751.75,
      porcentage: 0.34,
      year: 2019,
      month: 5,
      isrRetention: null
    },
    {
      id: 55,
      lowerLimit: 1457750.01,
      upperLimit: null,
      fixedFee: 457175.1,
      porcentage: 0.35,
      year: 2019,
      month: 5,
      isrRetention: null
    },
    {
      id: 56,
      lowerLimit: 0.01,
      upperLimit: 3471.12,
      fixedFee: 0,
      porcentage: 0.0192,
      year: 2019,
      month: 6,
      isrRetention: null
    },
    {
      id: 57,
      lowerLimit: 3471.13,
      upperLimit: 29461.08,
      fixedFee: 66.66,
      porcentage: 0.064,
      year: 2019,
      month: 6,
      isrRetention: null
    },
    {
      id: 58,
      lowerLimit: 29461.09,
      upperLimit: 51775.2,
      fixedFee: 1729.98,
      porcentage: 0.1088,
      year: 2019,
      month: 6,
      isrRetention: null
    },
    {
      id: 59,
      lowerLimit: 51775.21,
      upperLimit: 60186.42,
      fixedFee: 4157.76,
      porcentage: 0.16,
      year: 2019,
      month: 6,
      isrRetention: null
    },
    {
      id: 60,
      lowerLimit: 60186.43,
      upperLimit: 720059.64,
      fixedFee: 5503.56,
      porcentage: 0.1792,
      year: 2019,
      month: 6,
      isrRetention: null
    },
    {
      id: 61,
      lowerLimit: 720059.65,
      upperLimit: 145333.86,
      fixedFee: 7631.22,
      porcentage: 0.2136,
      year: 2019,
      month: 6,
      isrRetention: null
    },
    {
      id: 62,
      lowerLimit: 145333.87,
      upperLimit: 229066.14,
      fixedFee: 23282.64,
      porcentage: 0.2352,
      year: 2019,
      month: 6,
      isrRetention: null
    },
    {
      id: 63,
      lowerLimit: 229066.15,
      upperLimit: 437325,
      fixedFee: 42976.44,
      porcentage: 0.3,
      year: 2019,
      month: 6,
      isrRetention: null
    },
    {
      id: 64,
      lowerLimit: 437325.01,
      upperLimit: 583099.98,
      fixedFee: 105454.14,
      porcentage: 0.32,
      year: 2019,
      month: 6,
      isrRetention: null
    },
    {
      id: 65,
      lowerLimit: 583099.99,
      upperLimit: 1749300,
      fixedFee: 152102.1,
      porcentage: 0.34,
      year: 2019,
      month: 6,
      isrRetention: null
    },
    {
      id: 66,
      lowerLimit: 1749300.01,
      upperLimit: null,
      fixedFee: 548610.12,
      porcentage: 0.35,
      year: 2019,
      month: 6,
      isrRetention: null
    },
    {
      id: 67,
      lowerLimit: 0.01,
      upperLimit: 4049.64,
      fixedFee: 0,
      porcentage: 0.0192,
      year: 2019,
      month: 7,
      isrRetention: null
    },
    {
      id: 68,
      lowerLimit: 4049.65,
      upperLimit: 34371.26,
      fixedFee: 77.77,
      porcentage: 0.064,
      year: 2019,
      month: 7,
      isrRetention: null
    },
    {
      id: 69,
      lowerLimit: 34371.27,
      upperLimit: 60404.4,
      fixedFee: 2018.31,
      porcentage: 0.1088,
      year: 2019,
      month: 7,
      isrRetention: null
    },
    {
      id: 70,
      lowerLimit: 60404.41,
      upperLimit: 70217.49,
      fixedFee: 4850.72,
      porcentage: 0.16,
      year: 2019,
      month: 7,
      isrRetention: null
    },
    {
      id: 71,
      lowerLimit: 70217.5,
      upperLimit: 840069.58,
      fixedFee: 6420.82,
      porcentage: 0.1792,
      year: 2019,
      month: 7,
      isrRetention: null
    },
    {
      id: 72,
      lowerLimit: 840069.59,
      upperLimit: 169556.17,
      fixedFee: 8903.09,
      porcentage: 0.2136,
      year: 2019,
      month: 7,
      isrRetention: null
    },
    {
      id: 73,
      lowerLimit: 169556.18,
      upperLimit: 267243.83,
      fixedFee: 27163.08,
      porcentage: 0.2352,
      year: 2019,
      month: 7,
      isrRetention: null
    },
    {
      id: 74,
      lowerLimit: 267243.84,
      upperLimit: 510212.5,
      fixedFee: 50139.18,
      porcentage: 0.3,
      year: 2019,
      month: 7,
      isrRetention: null
    },
    {
      id: 75,
      lowerLimit: 510212.51,
      upperLimit: 680283.31,
      fixedFee: 123029.83,
      porcentage: 0.32,
      year: 2019,
      month: 7,
      isrRetention: null
    },
    {
      id: 76,
      lowerLimit: 680283.32,
      upperLimit: 2040850,
      fixedFee: 177452.45,
      porcentage: 0.34,
      year: 2019,
      month: 7,
      isrRetention: null
    },
    {
      id: 77,
      lowerLimit: 2040850.01,
      upperLimit: null,
      fixedFee: 640045.14,
      porcentage: 0.35,
      year: 2019,
      month: 7,
      isrRetention: null
    },
    {
      id: 78,
      lowerLimit: 0.01,
      upperLimit: 4628.16,
      fixedFee: 0,
      porcentage: 0.0192,
      year: 2019,
      month: 8,
      isrRetention: null
    },
    {
      id: 79,
      lowerLimit: 4628.17,
      upperLimit: 39281.44,
      fixedFee: 88.88,
      porcentage: 0.064,
      year: 2019,
      month: 8,
      isrRetention: null
    },
    {
      id: 80,
      lowerLimit: 39281.45,
      upperLimit: 69033.6,
      fixedFee: 2306.64,
      porcentage: 0.1088,
      year: 2019,
      month: 8,
      isrRetention: null
    },
    {
      id: 81,
      lowerLimit: 69033.61,
      upperLimit: 80248.56,
      fixedFee: 5543.68,
      porcentage: 0.16,
      year: 2019,
      month: 8,
      isrRetention: null
    },
    {
      id: 82,
      lowerLimit: 80248.57,
      upperLimit: 960079.52,
      fixedFee: 7338.08,
      porcentage: 0.1792,
      year: 2019,
      month: 8,
      isrRetention: null
    },
    {
      id: 83,
      lowerLimit: 960079.53,
      upperLimit: 193778.48,
      fixedFee: 10174.96,
      porcentage: 0.2136,
      year: 2019,
      month: 8,
      isrRetention: null
    },
    {
      id: 84,
      lowerLimit: 193778.49,
      upperLimit: 305421.52,
      fixedFee: 31043.52,
      porcentage: 0.2352,
      year: 2019,
      month: 8,
      isrRetention: null
    },
    {
      id: 85,
      lowerLimit: 305421.53,
      upperLimit: 583100,
      fixedFee: 57301.92,
      porcentage: 0.3,
      year: 2019,
      month: 8,
      isrRetention: null
    },
    {
      id: 86,
      lowerLimit: 583100.01,
      upperLimit: 777466.64,
      fixedFee: 140605.52,
      porcentage: 0.32,
      year: 2019,
      month: 8,
      isrRetention: null
    },
    {
      id: 87,
      lowerLimit: 777466.65,
      upperLimit: 2332400,
      fixedFee: 202802.8,
      porcentage: 0.34,
      year: 2019,
      month: 8,
      isrRetention: null
    },
    {
      id: 88,
      lowerLimit: 2332400.01,
      upperLimit: null,
      fixedFee: 731480.16,
      porcentage: 0.35,
      year: 2019,
      month: 8,
      isrRetention: null
    },
    {
      id: 89,
      lowerLimit: 0.01,
      upperLimit: 5206.68,
      fixedFee: 0,
      porcentage: 0.0192,
      year: 2019,
      month: 9,
      isrRetention: null
    },
    {
      id: 90,
      lowerLimit: 5206.69,
      upperLimit: 44191.62,
      fixedFee: 99.99,
      porcentage: 0.064,
      year: 2019,
      month: 9,
      isrRetention: null
    },
    {
      id: 91,
      lowerLimit: 44191.63,
      upperLimit: 77662.8,
      fixedFee: 2594.97,
      porcentage: 0.1088,
      year: 2019,
      month: 9,
      isrRetention: null
    },
    {
      id: 92,
      lowerLimit: 77662.81,
      upperLimit: 90279.63,
      fixedFee: 6236.64,
      porcentage: 0.16,
      year: 2019,
      month: 9,
      isrRetention: null
    },
    {
      id: 93,
      lowerLimit: 90279.64,
      upperLimit: 1080089.46,
      fixedFee: 8255.34,
      porcentage: 0.1792,
      year: 2019,
      month: 9,
      isrRetention: null
    },
    {
      id: 94,
      lowerLimit: 1080089.47,
      upperLimit: 218000.79,
      fixedFee: 11446.83,
      porcentage: 0.2136,
      year: 2019,
      month: 9,
      isrRetention: null
    },
    {
      id: 95,
      lowerLimit: 218000.8,
      upperLimit: 343599.21,
      fixedFee: 34923.96,
      porcentage: 0.2352,
      year: 2019,
      month: 9,
      isrRetention: null
    },
    {
      id: 96,
      lowerLimit: 343599.22,
      upperLimit: 655987.5,
      fixedFee: 64464.66,
      porcentage: 0.3,
      year: 2019,
      month: 9,
      isrRetention: null
    },
    {
      id: 97,
      lowerLimit: 655987.51,
      upperLimit: 874649.97,
      fixedFee: 158181.21,
      porcentage: 0.32,
      year: 2019,
      month: 9,
      isrRetention: null
    },
    {
      id: 98,
      lowerLimit: 874649.98,
      upperLimit: 2623950,
      fixedFee: 228153.15,
      porcentage: 0.34,
      year: 2019,
      month: 9,
      isrRetention: null
    },
    {
      id: 99,
      lowerLimit: 2623950.01,
      upperLimit: null,
      fixedFee: 822915.18,
      porcentage: 0.35,
      year: 2019,
      month: 9,
      isrRetention: null
    },
    {
      id: 100,
      lowerLimit: 0.01,
      upperLimit: 5785.2,
      fixedFee: 0,
      porcentage: 0.0192,
      year: 2019,
      month: 10,
      isrRetention: null
    },
    {
      id: 101,
      lowerLimit: 5785.21,
      upperLimit: 49101.8,
      fixedFee: 111.1,
      porcentage: 0.064,
      year: 2019,
      month: 10,
      isrRetention: null
    },
    {
      id: 102,
      lowerLimit: 49101.81,
      upperLimit: 86292,
      fixedFee: 2883.3,
      porcentage: 0.1088,
      year: 2019,
      month: 10,
      isrRetention: null
    },
    {
      id: 103,
      lowerLimit: 86292.01,
      upperLimit: 100310.7,
      fixedFee: 6929.6,
      porcentage: 0.16,
      year: 2019,
      month: 10,
      isrRetention: null
    },
    {
      id: 104,
      lowerLimit: 100310.71,
      upperLimit: 1200099.4,
      fixedFee: 9172.6,
      porcentage: 0.1792,
      year: 2019,
      month: 10,
      isrRetention: null
    },
    {
      id: 105,
      lowerLimit: 1200099.41,
      upperLimit: 242223.1,
      fixedFee: 12718.7,
      porcentage: 0.2136,
      year: 2019,
      month: 10,
      isrRetention: null
    },
    {
      id: 106,
      lowerLimit: 242223.11,
      upperLimit: 381776.9,
      fixedFee: 38804.4,
      porcentage: 0.2352,
      year: 2019,
      month: 10,
      isrRetention: null
    },
    {
      id: 107,
      lowerLimit: 381776.91,
      upperLimit: 728875,
      fixedFee: 71627.4,
      porcentage: 0.3,
      year: 2019,
      month: 10,
      isrRetention: null
    },
    {
      id: 108,
      lowerLimit: 728875.01,
      upperLimit: 971833.3,
      fixedFee: 175756.9,
      porcentage: 0.32,
      year: 2019,
      month: 10,
      isrRetention: null
    },
    {
      id: 109,
      lowerLimit: 971833.31,
      upperLimit: 2915500,
      fixedFee: 253503.5,
      porcentage: 0.34,
      year: 2019,
      month: 10,
      isrRetention: null
    },
    {
      id: 110,
      lowerLimit: 2915500.01,
      upperLimit: null,
      fixedFee: 914350.2,
      porcentage: 0.35,
      year: 2019,
      month: 10,
      isrRetention: null
    },
    {
      id: 111,
      lowerLimit: 0.01,
      upperLimit: 6363.72,
      fixedFee: 0,
      porcentage: 0.0192,
      year: 2019,
      month: 11,
      isrRetention: null
    },
    {
      id: 112,
      lowerLimit: 6363.73,
      upperLimit: 54011.98,
      fixedFee: 122.21,
      porcentage: 0.064,
      year: 2019,
      month: 11,
      isrRetention: null
    },
    {
      id: 113,
      lowerLimit: 54011.99,
      upperLimit: 94921.2,
      fixedFee: 3171.63,
      porcentage: 0.1088,
      year: 2019,
      month: 11,
      isrRetention: null
    },
    {
      id: 114,
      lowerLimit: 94921.21,
      upperLimit: 110341.77,
      fixedFee: 7622.56,
      porcentage: 0.16,
      year: 2019,
      month: 11,
      isrRetention: null
    },
    {
      id: 115,
      lowerLimit: 110341.78,
      upperLimit: 1320109.34,
      fixedFee: 10089.86,
      porcentage: 0.1792,
      year: 2019,
      month: 11,
      isrRetention: null
    },
    {
      id: 116,
      lowerLimit: 1320109.35,
      upperLimit: 266445.41,
      fixedFee: 13990.57,
      porcentage: 0.2136,
      year: 2019,
      month: 11,
      isrRetention: null
    },
    {
      id: 117,
      lowerLimit: 266445.42,
      upperLimit: 419954.59,
      fixedFee: 42684.84,
      porcentage: 0.2352,
      year: 2019,
      month: 11,
      isrRetention: null
    },
    {
      id: 118,
      lowerLimit: 419954.6,
      upperLimit: 801762.5,
      fixedFee: 78790.14,
      porcentage: 0.3,
      year: 2019,
      month: 11,
      isrRetention: null
    },
    {
      id: 119,
      lowerLimit: 801762.51,
      upperLimit: 1069016.63,
      fixedFee: 193332.59,
      porcentage: 0.32,
      year: 2019,
      month: 11,
      isrRetention: null
    },
    {
      id: 120,
      lowerLimit: 1069016.64,
      upperLimit: 3207050,
      fixedFee: 278853.85,
      porcentage: 0.34,
      year: 2019,
      month: 11,
      isrRetention: null
    },
    {
      id: 121,
      lowerLimit: 3207050.01,
      upperLimit: null,
      fixedFee: 1005785.22,
      porcentage: 0.35,
      year: 2019,
      month: 11,
      isrRetention: null
    },
    {
      id: 122,
      lowerLimit: 0.01,
      upperLimit: 6942.24,
      fixedFee: 0,
      porcentage: 0.0192,
      year: 2019,
      month: 12,
      isrRetention: null
    },
    {
      id: 123,
      lowerLimit: 6942.25,
      upperLimit: 58922.16,
      fixedFee: 133.32,
      porcentage: 0.064,
      year: 2019,
      month: 12,
      isrRetention: null
    },
    {
      id: 124,
      lowerLimit: 58922.17,
      upperLimit: 103550.4,
      fixedFee: 3459.96,
      porcentage: 0.1088,
      year: 2019,
      month: 12,
      isrRetention: null
    },
    {
      id: 125,
      lowerLimit: 103550.41,
      upperLimit: 120372.84,
      fixedFee: 8315.52,
      porcentage: 0.16,
      year: 2019,
      month: 12,
      isrRetention: null
    },
    {
      id: 126,
      lowerLimit: 120372.85,
      upperLimit: 1440119.28,
      fixedFee: 11007.12,
      porcentage: 0.1792,
      year: 2019,
      month: 12,
      isrRetention: null
    },
    {
      id: 127,
      lowerLimit: 1440119.29,
      upperLimit: 290667.72,
      fixedFee: 15262.44,
      porcentage: 0.2136,
      year: 2019,
      month: 12,
      isrRetention: null
    },
    {
      id: 128,
      lowerLimit: 290667.73,
      upperLimit: 458132.28,
      fixedFee: 46565.28,
      porcentage: 0.2352,
      year: 2019,
      month: 12,
      isrRetention: null
    },
    {
      id: 129,
      lowerLimit: 458132.29,
      upperLimit: 874650,
      fixedFee: 85952.88,
      porcentage: 0.3,
      year: 2019,
      month: 12,
      isrRetention: null
    },
    {
      id: 130,
      lowerLimit: 874650.01,
      upperLimit: 1166199.96,
      fixedFee: 210908.28,
      porcentage: 0.32,
      year: 2019,
      month: 12,
      isrRetention: null
    },
    {
      id: 131,
      lowerLimit: 1166199.97,
      upperLimit: 3498600,
      fixedFee: 304204.2,
      porcentage: 0.34,
      year: 2019,
      month: 12,
      isrRetention: null
    },
    {
      id: 132,
      lowerLimit: 3498600.01,
      upperLimit: null,
      fixedFee: 1097220.24,
      porcentage: 0.35,
      year: 2019,
      month: 12,
      isrRetention: null
    },

    {
      id: 1,
      lowerLimit: 0.01,
      upperLimit: 578.52,
      fixedFee: 0.00,
      porcentage: 0.0192,
      year: 2020,
      month: 1,
      isrRetention: 2
    },
    {
      id: 2,
      lowerLimit: 578.53,
      upperLimit: 4910.18,
      fixedFee: 11.11,
      porcentage: 0.064,
      year: 2020,
      month: 1,
      isrRetention: 2
    },
    {
      id: 3,
      lowerLimit: 4910.19,
      upperLimit: 8629.2,
      fixedFee: 288.33,
      porcentage: 0.1088,
      year: 2020,
      month: 1,
      isrRetention: 2
    },
    {
      id: 4,
      lowerLimit: 8629.21,
      upperLimit: 10031.07,
      fixedFee: 692.96,
      porcentage: 0.16,
      year: 2020,
      month: 1,
      isrRetention: 2
    },
    {
      id: 5,
      lowerLimit: 10031.08,
      upperLimit: 120009.94,
      fixedFee: 917.26,
      porcentage: 0.1792,
      year: 2020,
      month: 1,
      isrRetention: 2
    },
    {
      id: 6,
      lowerLimit: 120009.95,
      upperLimit: 24222.31,
      fixedFee: 1271.87,
      porcentage: 0.2136,
      year: 2020,
      month: 1,
      isrRetention: 2
    },
    {
      id: 7,
      lowerLimit: 24222.32,
      upperLimit: 38177.69,
      fixedFee: 3880.44,
      porcentage: 0.2352,
      year: 2020,
      month: 1,
      isrRetention: 2
    },
    {
      id: 8,
      lowerLimit: 38177.7,
      upperLimit: 72887.5,
      fixedFee: 7162.74,
      porcentage: 0.3,
      year: 2020,
      month: 1,
      isrRetention: 2
    },
    {
      id: 9,
      lowerLimit: 72887.51,
      upperLimit: 97183.33,
      fixedFee: 17575.69,
      porcentage: 0.32,
      year: 2020,
      month: 1,
      isrRetention: 2
    },
    {
      id: 10,
      lowerLimit: 97183.34,
      upperLimit: 291550,
      fixedFee: 25350.35,
      porcentage: 0.34,
      year: 2020,
      month: 1,
      isrRetention: 2
    },
    {
      id: 11,
      lowerLimit: 291550.01,
      upperLimit: null,
      fixedFee: 91435.02,
      porcentage: 0.35,
      year: 2020,
      month: 1,
      isrRetention: 2
    },
    {
      id: 12,
      lowerLimit: 0.01,
      upperLimit: 1157.04,
      fixedFee: 0,
      porcentage: 0.0192,
      year: 2020,
      month: 2,
      isrRetention: 4
    },
    {
      id: 13,
      lowerLimit: 1157.05,
      upperLimit: 9820.36,
      fixedFee: 22.22,
      porcentage: 0.064,
      year: 2020,
      month: 2,
      isrRetention: 4
    },
    {
      id: 14,
      lowerLimit: 9820.37,
      upperLimit: 17258.4,
      fixedFee: 576.66,
      porcentage: 0.1088,
      year: 2020,
      month: 2,
      isrRetention: 4
    },
    {
      id: 15,
      lowerLimit: 17258.41,
      upperLimit: 20062.14,
      fixedFee: 1385.92,
      porcentage: 0.16,
      year: 2020,
      month: 2,
      isrRetention: 4
    },
    {
      id: 16,
      lowerLimit: 20062.15,
      upperLimit: 240019.88,
      fixedFee: 1834.52,
      porcentage: 0.1792,
      year: 2020,
      month: 2,
      isrRetention: 4
    },
    {
      id: 17,
      lowerLimit: 240019.89,
      upperLimit: 48444.62,
      fixedFee: 2543.74,
      porcentage: 0.2136,
      year: 2020,
      month: 2,
      isrRetention: 4
    },
    {
      id: 18,
      lowerLimit: 48444.63,
      upperLimit: 76355.38,
      fixedFee: 7760.88,
      porcentage: 0.2352,
      year: 2020,
      month: 2,
      isrRetention: 4
    },
    {
      id: 19,
      lowerLimit: 76355.39,
      upperLimit: 145775,
      fixedFee: 14325.48,
      porcentage: 0.3,
      year: 2020,
      month: 2,
      isrRetention: 4
    },
    {
      id: 20,
      lowerLimit: 145775.01,
      upperLimit: 194366.66,
      fixedFee: 35151.38,
      porcentage: 0.32,
      year: 2020,
      month: 2,
      isrRetention: 4
    },
    {
      id: 21,
      lowerLimit: 194366.67,
      upperLimit: 583100,
      fixedFee: 50700.7,
      porcentage: 0.34,
      year: 2020,
      month: 2,
      isrRetention: 4
    },
    {
      id: 22,
      lowerLimit: 583100.01,
      upperLimit: null,
      fixedFee: 182870.04,
      porcentage: 0.35,
      year: 2020,
      month: 2,
      isrRetention: 4
    },
    {
      id: 23,
      lowerLimit: 0.01,
      upperLimit: 1735.56,
      fixedFee: 0,
      porcentage: 0.0192,
      year: 2020,
      month: 3,
      isrRetention: 6
    },
    {
      id: 24,
      lowerLimit: 1735.57,
      upperLimit: 14730.54,
      fixedFee: 33.33,
      porcentage: 0.064,
      year: 2020,
      month: 3,
      isrRetention: 6
    },
    {
      id: 25,
      lowerLimit: 14730.55,
      upperLimit: 25887.6,
      fixedFee: 864.99,
      porcentage: 0.1088,
      year: 2020,
      month: 3,
      isrRetention: 6
    },
    {
      id: 26,
      lowerLimit: 25887.61,
      upperLimit: 30093.21,
      fixedFee: 2078.88,
      porcentage: 0.16,
      year: 2020,
      month: 3,
      isrRetention: 6
    },
    {
      id: 27,
      lowerLimit: 30093.22,
      upperLimit: 360029.82,
      fixedFee: 2751.78,
      porcentage: 0.1792,
      year: 2020,
      month: 3,
      isrRetention: 6
    },
    {
      id: 28,
      lowerLimit: 360029.83,
      upperLimit: 72666.93,
      fixedFee: 3815.61,
      porcentage: 0.2136,
      year: 2020,
      month: 3,
      isrRetention: 6
    },
    {
      id: 29,
      lowerLimit: 72666.94,
      upperLimit: 114533.07,
      fixedFee: 11641.32,
      porcentage: 0.2352,
      year: 2020,
      month: 3,
      isrRetention: 6
    },
    {
      id: 30,
      lowerLimit: 114533.08,
      upperLimit: 218662.5,
      fixedFee: 21488.22,
      porcentage: 0.3,
      year: 2020,
      month: 3,
      isrRetention: 6
    },
    {
      id: 31,
      lowerLimit: 218662.51,
      upperLimit: 291549.99,
      fixedFee: 52727.07,
      porcentage: 0.32,
      year: 2020,
      month: 3,
      isrRetention: 6
    },
    {
      id: 32,
      lowerLimit: 291550,
      upperLimit: 874650,
      fixedFee: 76051.05,
      porcentage: 0.34,
      year: 2020,
      month: 3,
      isrRetention: 6
    },
    {
      id: 33,
      lowerLimit: 874650.01,
      upperLimit: null,
      fixedFee: 274305.06,
      porcentage: 0.35,
      year: 2020,
      month: 3,
      isrRetention: 6
    },
    {
      id: 34,
      lowerLimit: 0.01,
      upperLimit: 2314.08,
      fixedFee: 0,
      porcentage: 0.0192,
      year: 2020,
      month: 4,
      isrRetention: 6
    },
    {
      id: 35,
      lowerLimit: 2314.09,
      upperLimit: 19640.72,
      fixedFee: 44.44,
      porcentage: 0.064,
      year: 2020,
      month: 4,
      isrRetention: 8
    },
    {
      id: 36,
      lowerLimit: 19640.73,
      upperLimit: 34516.8,
      fixedFee: 1153.32,
      porcentage: 0.1088,
      year: 2020,
      month: 4,
      isrRetention: 8
    },
    {
      id: 37,
      lowerLimit: 34516.81,
      upperLimit: 40124.28,
      fixedFee: 2771.84,
      porcentage: 0.16,
      year: 2020,
      month: 4,
      isrRetention: 8
    },
    {
      id: 38,
      lowerLimit: 40124.29,
      upperLimit: 480039.76,
      fixedFee: 3669.04,
      porcentage: 0.1792,
      year: 2020,
      month: 4,
      isrRetention: 8
    },
    {
      id: 39,
      lowerLimit: 480039.77,
      upperLimit: 96889.24,
      fixedFee: 5087.48,
      porcentage: 0.2136,
      year: 2020,
      month: 4,
      isrRetention: 8
    },
    {
      id: 40,
      lowerLimit: 96889.25,
      upperLimit: 152710.76,
      fixedFee: 15521.76,
      porcentage: 0.2352,
      year: 2020,
      month: 4,
      isrRetention: 8
    },
    {
      id: 41,
      lowerLimit: 152710.77,
      upperLimit: 291550,
      fixedFee: 28650.96,
      porcentage: 0.3,
      year: 2020,
      month: 4,
      isrRetention: 8
    },
    {
      id: 42,
      lowerLimit: 291550.01,
      upperLimit: 388733.32,
      fixedFee: 70302.76,
      porcentage: 0.32,
      year: 2020,
      month: 4,
      isrRetention: 8
    },
    {
      id: 43,
      lowerLimit: 388733.33,
      upperLimit: 1166200,
      fixedFee: 101401.4,
      porcentage: 0.34,
      year: 2020,
      month: 4,
      isrRetention: 8
    },
    {
      id: 44,
      lowerLimit: 1166200.01,
      upperLimit: null,
      fixedFee: 365740.08,
      porcentage: 0.35,
      year: 2020,
      month: 4,
      isrRetention: null
    },
    {
      id: 45,
      lowerLimit: 0.01,
      upperLimit: 2892.6,
      fixedFee: 0,
      porcentage: 0.0192,
      year: 2020,
      month: 5,
      isrRetention: null
    },
    {
      id: 46,
      lowerLimit: 2892.61,
      upperLimit: 24550.9,
      fixedFee: 55.55,
      porcentage: 0.064,
      year: 2020,
      month: 5,
      isrRetention: null
    },
    {
      id: 47,
      lowerLimit: 24550.91,
      upperLimit: 43146,
      fixedFee: 1441.65,
      porcentage: 0.1088,
      year: 2020,
      month: 5,
      isrRetention: null
    },
    {
      id: 48,
      lowerLimit: 43146.01,
      upperLimit: 50155.35,
      fixedFee: 3464.8,
      porcentage: 0.16,
      year: 2020,
      month: 5,
      isrRetention: null
    },
    {
      id: 49,
      lowerLimit: 50155.36,
      upperLimit: 600049.7,
      fixedFee: 4586.3,
      porcentage: 0.1792,
      year: 2020,
      month: 5,
      isrRetention: null
    },
    {
      id: 50,
      lowerLimit: 600049.71,
      upperLimit: 121111.55,
      fixedFee: 6359.35,
      porcentage: 0.2136,
      year: 2020,
      month: 5,
      isrRetention: null
    },
    {
      id: 51,
      lowerLimit: 121111.56,
      upperLimit: 190888.45,
      fixedFee: 19402.2,
      porcentage: 0.2352,
      year: 2020,
      month: 5,
      isrRetention: null
    },
    {
      id: 52,
      lowerLimit: 190888.46,
      upperLimit: 364437.5,
      fixedFee: 35813.7,
      porcentage: 0.3,
      year: 2020,
      month: 5,
      isrRetention: null
    },
    {
      id: 53,
      lowerLimit: 364437.51,
      upperLimit: 485916.65,
      fixedFee: 87878.45,
      porcentage: 0.32,
      year: 2020,
      month: 5,
      isrRetention: null
    },
    {
      id: 54,
      lowerLimit: 485916.66,
      upperLimit: 1457750,
      fixedFee: 126751.75,
      porcentage: 0.34,
      year: 2020,
      month: 5,
      isrRetention: null
    },
    {
      id: 55,
      lowerLimit: 1457750.01,
      upperLimit: null,
      fixedFee: 457175.1,
      porcentage: 0.35,
      year: 2020,
      month: 5,
      isrRetention: null
    },
    {
      id: 56,
      lowerLimit: 0.01,
      upperLimit: 3471.12,
      fixedFee: 0,
      porcentage: 0.0192,
      year: 2020,
      month: 6,
      isrRetention: null
    },
    {
      id: 57,
      lowerLimit: 3471.13,
      upperLimit: 29461.08,
      fixedFee: 66.66,
      porcentage: 0.064,
      year: 2020,
      month: 6,
      isrRetention: null
    },
    {
      id: 58,
      lowerLimit: 29461.09,
      upperLimit: 51775.2,
      fixedFee: 1729.98,
      porcentage: 0.1088,
      year: 2020,
      month: 6,
      isrRetention: null
    },
    {
      id: 59,
      lowerLimit: 51775.21,
      upperLimit: 60186.42,
      fixedFee: 4157.76,
      porcentage: 0.16,
      year: 2020,
      month: 6,
      isrRetention: null
    },
    {
      id: 60,
      lowerLimit: 60186.43,
      upperLimit: 720059.64,
      fixedFee: 5503.56,
      porcentage: 0.1792,
      year: 2020,
      month: 6,
      isrRetention: null
    },
    {
      id: 61,
      lowerLimit: 720059.65,
      upperLimit: 145333.86,
      fixedFee: 7631.22,
      porcentage: 0.2136,
      year: 2020,
      month: 6,
      isrRetention: null
    },
    {
      id: 62,
      lowerLimit: 145333.87,
      upperLimit: 229066.14,
      fixedFee: 23282.64,
      porcentage: 0.2352,
      year: 2020,
      month: 6,
      isrRetention: null
    },
    {
      id: 63,
      lowerLimit: 229066.15,
      upperLimit: 437325,
      fixedFee: 42976.44,
      porcentage: 0.3,
      year: 2020,
      month: 6,
      isrRetention: null
    },
    {
      id: 64,
      lowerLimit: 437325.01,
      upperLimit: 583099.98,
      fixedFee: 105454.14,
      porcentage: 0.32,
      year: 2020,
      month: 6,
      isrRetention: null
    },
    {
      id: 65,
      lowerLimit: 583099.99,
      upperLimit: 1749300,
      fixedFee: 152102.1,
      porcentage: 0.34,
      year: 2020,
      month: 6,
      isrRetention: null
    },
    {
      id: 66,
      lowerLimit: 1749300.01,
      upperLimit: null,
      fixedFee: 548610.12,
      porcentage: 0.35,
      year: 2020,
      month: 6,
      isrRetention: null
    },
    {
      id: 67,
      lowerLimit: 0.01,
      upperLimit: 4049.64,
      fixedFee: 0,
      porcentage: 0.0192,
      year: 2020,
      month: 7,
      isrRetention: null
    },
    {
      id: 68,
      lowerLimit: 4049.65,
      upperLimit: 34371.26,
      fixedFee: 77.77,
      porcentage: 0.064,
      year: 2020,
      month: 7,
      isrRetention: null
    },
    {
      id: 69,
      lowerLimit: 34371.27,
      upperLimit: 60404.4,
      fixedFee: 2020.31,
      porcentage: 0.1088,
      year: 2020,
      month: 7,
      isrRetention: null
    },
    {
      id: 70,
      lowerLimit: 60404.41,
      upperLimit: 70217.49,
      fixedFee: 4850.72,
      porcentage: 0.16,
      year: 2020,
      month: 7,
      isrRetention: null
    },
    {
      id: 71,
      lowerLimit: 70217.5,
      upperLimit: 840069.58,
      fixedFee: 6420.82,
      porcentage: 0.1792,
      year: 2020,
      month: 7,
      isrRetention: null
    },
    {
      id: 72,
      lowerLimit: 840069.59,
      upperLimit: 169556.17,
      fixedFee: 8903.09,
      porcentage: 0.2136,
      year: 2020,
      month: 7,
      isrRetention: null
    },
    {
      id: 73,
      lowerLimit: 169556.18,
      upperLimit: 267243.83,
      fixedFee: 27163.08,
      porcentage: 0.2352,
      year: 2020,
      month: 7,
      isrRetention: null
    },
    {
      id: 74,
      lowerLimit: 267243.84,
      upperLimit: 510212.5,
      fixedFee: 50139.18,
      porcentage: 0.3,
      year: 2020,
      month: 7,
      isrRetention: null
    },
    {
      id: 75,
      lowerLimit: 510212.51,
      upperLimit: 680283.31,
      fixedFee: 123029.83,
      porcentage: 0.32,
      year: 2020,
      month: 7,
      isrRetention: null
    },
    {
      id: 76,
      lowerLimit: 680283.32,
      upperLimit: 2040850,
      fixedFee: 177452.45,
      porcentage: 0.34,
      year: 2020,
      month: 7,
      isrRetention: null
    },
    {
      id: 77,
      lowerLimit: 2040850.01,
      upperLimit: null,
      fixedFee: 640045.14,
      porcentage: 0.35,
      year: 2020,
      month: 7,
      isrRetention: null
    },
    {
      id: 78,
      lowerLimit: 0.01,
      upperLimit: 4628.16,
      fixedFee: 0,
      porcentage: 0.0192,
      year: 2020,
      month: 8,
      isrRetention: null
    },
    {
      id: 79,
      lowerLimit: 4628.17,
      upperLimit: 39281.44,
      fixedFee: 88.88,
      porcentage: 0.064,
      year: 2020,
      month: 8,
      isrRetention: null
    },
    {
      id: 80,
      lowerLimit: 39281.45,
      upperLimit: 69033.6,
      fixedFee: 2306.64,
      porcentage: 0.1088,
      year: 2020,
      month: 8,
      isrRetention: null
    },
    {
      id: 81,
      lowerLimit: 69033.61,
      upperLimit: 80248.56,
      fixedFee: 5543.68,
      porcentage: 0.16,
      year: 2020,
      month: 8,
      isrRetention: null
    },
    {
      id: 82,
      lowerLimit: 80248.57,
      upperLimit: 960079.52,
      fixedFee: 7338.08,
      porcentage: 0.1792,
      year: 2020,
      month: 8,
      isrRetention: null
    },
    {
      id: 83,
      lowerLimit: 960079.53,
      upperLimit: 193778.48,
      fixedFee: 10174.96,
      porcentage: 0.2136,
      year: 2020,
      month: 8,
      isrRetention: null
    },
    {
      id: 84,
      lowerLimit: 193778.49,
      upperLimit: 305421.52,
      fixedFee: 31043.52,
      porcentage: 0.2352,
      year: 2020,
      month: 8,
      isrRetention: null
    },
    {
      id: 85,
      lowerLimit: 305421.53,
      upperLimit: 583100,
      fixedFee: 57301.92,
      porcentage: 0.3,
      year: 2020,
      month: 8,
      isrRetention: null
    },
    {
      id: 86,
      lowerLimit: 583100.01,
      upperLimit: 777466.64,
      fixedFee: 140605.52,
      porcentage: 0.32,
      year: 2020,
      month: 8,
      isrRetention: null
    },
    {
      id: 87,
      lowerLimit: 777466.65,
      upperLimit: 2332400,
      fixedFee: 202802.8,
      porcentage: 0.34,
      year: 2020,
      month: 8,
      isrRetention: null
    },
    {
      id: 88,
      lowerLimit: 2332400.01,
      upperLimit: null,
      fixedFee: 731480.16,
      porcentage: 0.35,
      year: 2020,
      month: 8,
      isrRetention: null
    },
    {
      id: 89,
      lowerLimit: 0.01,
      upperLimit: 5206.68,
      fixedFee: 0,
      porcentage: 0.0192,
      year: 2020,
      month: 9,
      isrRetention: null
    },
    {
      id: 90,
      lowerLimit: 5206.69,
      upperLimit: 44191.62,
      fixedFee: 99.99,
      porcentage: 0.064,
      year: 2020,
      month: 9,
      isrRetention: null
    },
    {
      id: 91,
      lowerLimit: 44191.63,
      upperLimit: 77662.8,
      fixedFee: 2594.97,
      porcentage: 0.1088,
      year: 2020,
      month: 9,
      isrRetention: null
    },
    {
      id: 92,
      lowerLimit: 77662.81,
      upperLimit: 90279.63,
      fixedFee: 6236.64,
      porcentage: 0.16,
      year: 2020,
      month: 9,
      isrRetention: null
    },
    {
      id: 93,
      lowerLimit: 90279.64,
      upperLimit: 1080089.46,
      fixedFee: 8255.34,
      porcentage: 0.1792,
      year: 2020,
      month: 9,
      isrRetention: null
    },
    {
      id: 94,
      lowerLimit: 1080089.47,
      upperLimit: 218000.79,
      fixedFee: 11446.83,
      porcentage: 0.2136,
      year: 2020,
      month: 9,
      isrRetention: null
    },
    {
      id: 95,
      lowerLimit: 218000.8,
      upperLimit: 343599.21,
      fixedFee: 34923.96,
      porcentage: 0.2352,
      year: 2020,
      month: 9,
      isrRetention: null
    },
    {
      id: 96,
      lowerLimit: 343599.22,
      upperLimit: 655987.5,
      fixedFee: 64464.66,
      porcentage: 0.3,
      year: 2020,
      month: 9,
      isrRetention: null
    },
    {
      id: 97,
      lowerLimit: 655987.51,
      upperLimit: 874649.97,
      fixedFee: 158181.21,
      porcentage: 0.32,
      year: 2020,
      month: 9,
      isrRetention: null
    },
    {
      id: 98,
      lowerLimit: 874649.98,
      upperLimit: 2623950,
      fixedFee: 228153.15,
      porcentage: 0.34,
      year: 2020,
      month: 9,
      isrRetention: null
    },
    {
      id: 99,
      lowerLimit: 2623950.01,
      upperLimit: null,
      fixedFee: 822915.18,
      porcentage: 0.35,
      year: 2020,
      month: 9,
      isrRetention: null
    },
    {
      id: 100,
      lowerLimit: 0.01,
      upperLimit: 5785.2,
      fixedFee: 0,
      porcentage: 0.0192,
      year: 2020,
      month: 10,
      isrRetention: null
    },
    {
      id: 101,
      lowerLimit: 5785.21,
      upperLimit: 49101.8,
      fixedFee: 111.1,
      porcentage: 0.064,
      year: 2020,
      month: 10,
      isrRetention: null
    },
    {
      id: 102,
      lowerLimit: 49101.81,
      upperLimit: 86292,
      fixedFee: 2883.3,
      porcentage: 0.1088,
      year: 2020,
      month: 10,
      isrRetention: null
    },
    {
      id: 103,
      lowerLimit: 86292.01,
      upperLimit: 100310.7,
      fixedFee: 6929.6,
      porcentage: 0.16,
      year: 2020,
      month: 10,
      isrRetention: null
    },
    {
      id: 104,
      lowerLimit: 100310.71,
      upperLimit: 1200099.4,
      fixedFee: 9172.6,
      porcentage: 0.1792,
      year: 2020,
      month: 10,
      isrRetention: null
    },
    {
      id: 105,
      lowerLimit: 1200099.41,
      upperLimit: 242223.1,
      fixedFee: 12718.7,
      porcentage: 0.2136,
      year: 2020,
      month: 10,
      isrRetention: null
    },
    {
      id: 106,
      lowerLimit: 242223.11,
      upperLimit: 381776.9,
      fixedFee: 38804.4,
      porcentage: 0.2352,
      year: 2020,
      month: 10,
      isrRetention: null
    },
    {
      id: 107,
      lowerLimit: 381776.91,
      upperLimit: 728875,
      fixedFee: 71627.4,
      porcentage: 0.3,
      year: 2020,
      month: 10,
      isrRetention: null
    },
    {
      id: 108,
      lowerLimit: 728875.01,
      upperLimit: 971833.3,
      fixedFee: 175756.9,
      porcentage: 0.32,
      year: 2020,
      month: 10,
      isrRetention: null
    },
    {
      id: 109,
      lowerLimit: 971833.31,
      upperLimit: 2915500,
      fixedFee: 253503.5,
      porcentage: 0.34,
      year: 2020,
      month: 10,
      isrRetention: null
    },
    {
      id: 110,
      lowerLimit: 2915500.01,
      upperLimit: null,
      fixedFee: 914350.2,
      porcentage: 0.35,
      year: 2020,
      month: 10,
      isrRetention: null
    },
    {
      id: 111,
      lowerLimit: 0.01,
      upperLimit: 6363.72,
      fixedFee: 0,
      porcentage: 0.0192,
      year: 2020,
      month: 11,
      isrRetention: null
    },
    {
      id: 112,
      lowerLimit: 6363.73,
      upperLimit: 54011.98,
      fixedFee: 122.21,
      porcentage: 0.064,
      year: 2020,
      month: 11,
      isrRetention: null
    },
    {
      id: 113,
      lowerLimit: 54011.99,
      upperLimit: 94921.2,
      fixedFee: 3171.63,
      porcentage: 0.1088,
      year: 2020,
      month: 11,
      isrRetention: null
    },
    {
      id: 114,
      lowerLimit: 94921.21,
      upperLimit: 110341.77,
      fixedFee: 7622.56,
      porcentage: 0.16,
      year: 2020,
      month: 11,
      isrRetention: null
    },
    {
      id: 115,
      lowerLimit: 110341.78,
      upperLimit: 1320109.34,
      fixedFee: 10089.86,
      porcentage: 0.1792,
      year: 2020,
      month: 11,
      isrRetention: null
    },
    {
      id: 116,
      lowerLimit: 1320109.35,
      upperLimit: 266445.41,
      fixedFee: 13990.57,
      porcentage: 0.2136,
      year: 2020,
      month: 11,
      isrRetention: null
    },
    {
      id: 117,
      lowerLimit: 266445.42,
      upperLimit: 419954.59,
      fixedFee: 42684.84,
      porcentage: 0.2352,
      year: 2020,
      month: 11,
      isrRetention: null
    },
    {
      id: 118,
      lowerLimit: 419954.6,
      upperLimit: 801762.5,
      fixedFee: 78790.14,
      porcentage: 0.3,
      year: 2020,
      month: 11,
      isrRetention: null
    },
    {
      id: 119,
      lowerLimit: 801762.51,
      upperLimit: 1069016.63,
      fixedFee: 193332.59,
      porcentage: 0.32,
      year: 2020,
      month: 11,
      isrRetention: null
    },
    {
      id: 120,
      lowerLimit: 1069016.64,
      upperLimit: 3207050,
      fixedFee: 278853.85,
      porcentage: 0.34,
      year: 2020,
      month: 11,
      isrRetention: null
    },
    {
      id: 121,
      lowerLimit: 3207050.01,
      upperLimit: null,
      fixedFee: 1005785.22,
      porcentage: 0.35,
      year: 2020,
      month: 11,
      isrRetention: null
    },
    {
      id: 122,
      lowerLimit: 0.01,
      upperLimit: 6942.24,
      fixedFee: 0,
      porcentage: 0.0192,
      year: 2020,
      month: 12,
      isrRetention: null
    },
    {
      id: 123,
      lowerLimit: 6942.25,
      upperLimit: 58922.16,
      fixedFee: 133.32,
      porcentage: 0.064,
      year: 2020,
      month: 12,
      isrRetention: null
    },
    {
      id: 124,
      lowerLimit: 58922.17,
      upperLimit: 103550.4,
      fixedFee: 3459.96,
      porcentage: 0.1088,
      year: 2020,
      month: 12,
      isrRetention: null
    },
    {
      id: 125,
      lowerLimit: 103550.41,
      upperLimit: 120372.84,
      fixedFee: 8315.52,
      porcentage: 0.16,
      year: 2020,
      month: 12,
      isrRetention: null
    },
    {
      id: 126,
      lowerLimit: 120372.85,
      upperLimit: 1440119.28,
      fixedFee: 11007.12,
      porcentage: 0.1792,
      year: 2020,
      month: 12,
      isrRetention: null
    },
    {
      id: 127,
      lowerLimit: 1440119.29,
      upperLimit: 290667.72,
      fixedFee: 15262.44,
      porcentage: 0.2136,
      year: 2020,
      month: 12,
      isrRetention: null
    },
    {
      id: 128,
      lowerLimit: 290667.73,
      upperLimit: 458132.28,
      fixedFee: 46565.28,
      porcentage: 0.2352,
      year: 2020,
      month: 12,
      isrRetention: null
    },
    {
      id: 129,
      lowerLimit: 458132.29,
      upperLimit: 874650,
      fixedFee: 85952.88,
      porcentage: 0.3,
      year: 2020,
      month: 12,
      isrRetention: null
    },
    {
      id: 130,
      lowerLimit: 874650.01,
      upperLimit: 1166199.96,
      fixedFee: 210908.28,
      porcentage: 0.32,
      year: 2020,
      month: 12,
      isrRetention: null
    },
    {
      id: 131,
      lowerLimit: 1166199.97,
      upperLimit: 3498600,
      fixedFee: 304204.2,
      porcentage: 0.34,
      year: 2020,
      month: 12,
      isrRetention: null
    },
    {
      id: 132,
      lowerLimit: 3498600.01,
      upperLimit: null,
      fixedFee: 1097220.24,
      porcentage: 0.35,
      year: 2020,
      month: 12,
      isrRetention: null
    },

    {
      id: 1,
      lowerLimit: 0.01,
      upperLimit: 644.58,
      fixedFee: 0.00,
      porcentage: .0192,
      year: 2021,
      month: 1,
      isrRetention: 2
    },
    {
      id: 2,
      lowerLimit: 644.59,
      upperLimit: 5470.92,
      fixedFee: 12.38,
      porcentage: .0640,
      year: 2021,
      month: 1,
      isrRetention: 2
    },
    {
      id: 3,
      lowerLimit: 5470.93,
      upperLimit: 9614.66,
      fixedFee: 321.26,
      porcentage: .1088,
      year: 2021,
      month: 1,
      isrRetention: 2
    },
    {
      id: 4,
      lowerLimit: 9614.67,
      upperLimit: 11176.62,
      fixedFee: 772.10,
      porcentage: .16,
      year: 2021,
      month: 1,
      isrRetention: 2
    },
    {
      id: 5,
      lowerLimit: 11176.63,
      upperLimit: 13381.47,
      fixedFee: 1022.01,
      porcentage: .1792,
      year: 2021,
      month: 1,
      isrRetention: 2
    },
    {
      id: 6,
      lowerLimit: 13381.48,
      upperLimit: 26988.50,
      fixedFee: 1417.12,
      porcentage: .2136,
      year: 2021,
      month: 1,
      isrRetention: 2
    },
    {
      id: 7,
      lowerLimit: 26988.51,
      upperLimit: 42537.58,
      fixedFee: 4323.58,
      porcentage: .2352,
      year: 2021,
      month: 1,
      isrRetention: 2
    },
    {
      id: 8,
      lowerLimit: 42537.59,
      upperLimit: 81211.25,
      fixedFee: 7980.73,
      porcentage: .30,
      year: 2021,
      month: 1,
      isrRetention: 2
    },
    {
      id: 9,
      lowerLimit: 81211.26,
      upperLimit: 108281.67,
      fixedFee: 19582.83,
      porcentage: .32,
      year: 2021,
      month: 1,
      isrRetention: 2
    },
    {
      id: 10,
      lowerLimit: 108281.68,
      upperLimit: 324845.01,
      fixedFee: 28245.36,
      porcentage: .34,
      year: 2021,
      month: 1,
      isrRetention: 2
    },
    {
      id: 11,
      lowerLimit: 324845.02,
      upperLimit: null,
      fixedFee: 101876.90,
      porcentage: .35,
      year: 2021,
      month: 1,
      isrRetention: 2
    },
    {
      id: 12,
      lowerLimit: 0.01,
      upperLimit: 1289.16,
      fixedFee: 0.00,
      porcentage: .0192,
      year: 2021,
      month: 2,
      isrRetention: 4
    },
    {
      id: 13,
      lowerLimit: 1289.17,
      upperLimit: 10941.84,
      fixedFee: 24.76,
      porcentage: .0640,
      year: 2021,
      month: 2,
      isrRetention: 4
    },
    {
      id: 14,
      lowerLimit: 10941.85,
      upperLimit: 19229.32,
      fixedFee: 642.52,
      porcentage: .1088,
      year: 2021,
      month: 2,
      isrRetention: 4
    },
    {
      id: 15,
      lowerLimit: 19229.33,
      upperLimit: 22353.24,
      fixedFee: 1544.20,
      porcentage: .16,
      year: 2021,
      month: 2,
      isrRetention: 4
    },
    {
      id: 16,
      lowerLimit: 22353.25,
      upperLimit: 26762.94,
      fixedFee: 2044.02,
      porcentage: .1792,
      year: 2021,
      month: 2,
      isrRetention: 4
    },
    {
      id: 17,
      lowerLimit: 26762.95,
      upperLimit: 53977.00,
      fixedFee: 2834.24,
      porcentage: .2136,
      year: 2021,
      month: 2,
      isrRetention: 4
    },
    {
      id: 18,
      lowerLimit: 53977.01,
      upperLimit: 85075.16,
      fixedFee: 8647.16,
      porcentage: .2352,
      year: 2021,
      month: 2,
      isrRetention: 4
    },
    {
      id: 19,
      lowerLimit: 85075.17,
      upperLimit: 162422.50,
      fixedFee: 15961.46,
      porcentage: .30,
      year: 2021,
      month: 2,
      isrRetention: 4
    },
    {
      id: 20,
      lowerLimit: 162422.51,
      upperLimit: 216563.34,
      fixedFee: 39165.66,
      porcentage: .32,
      year: 2021,
      month: 2,
      isrRetention: 4
    },
    {
      id: 21,
      lowerLimit: 216563.35,
      upperLimit: 649690.02,
      fixedFee: 56490.72,
      porcentage: .34,
      year: 2021,
      month: 2,
      isrRetention: 4
    },
    {
      id: 22,
      lowerLimit: 649690.03,
      upperLimit: null,
      fixedFee: 203753.80,
      porcentage: .35,
      year: 2021,
      month: 2,
      isrRetention: 4
    },
    {
      id: 23,
      lowerLimit: 0.01,
      upperLimit: 1933.74,
      fixedFee: 0.00,
      porcentage: .0192,
      year: 2021,
      month: 3,
      isrRetention: 6
    },
    {
      id: 24,
      lowerLimit: 1933.75,
      upperLimit: 16412.76,
      fixedFee: 37.14,
      porcentage: .0640,
      year: 2021,
      month: 3,
      isrRetention: 6
    },
    {
      id: 25,
      lowerLimit: 16412.77,
      upperLimit: 28843.98,
      fixedFee: 963.78,
      porcentage: .1088,
      year: 2021,
      month: 3,
      isrRetention: 6
    },
    {
      id: 26,
      lowerLimit: 28843.99,
      upperLimit: 33529.86,
      fixedFee: 2316.30,
      porcentage: .16,
      year: 2021,
      month: 3,
      isrRetention: 6
    },
    {
      id: 27,
      lowerLimit: 33529.87,
      upperLimit: 40144.41,
      fixedFee: 3066.03,
      porcentage: .1792,
      year: 2021,
      month: 3,
      isrRetention: 6
    },
    {
      id: 28,
      lowerLimit: 40144.42,
      upperLimit: 80965.50,
      fixedFee: 4251.36,
      porcentage: .2136,
      year: 2021,
      month: 3,
      isrRetention: 6
    },
    {
      id: 29,
      lowerLimit: 80965.51,
      upperLimit: 127612.74,
      fixedFee: 12970.74,
      porcentage: .2352,
      year: 2021,
      month: 3,
      isrRetention: 6
    },
    {
      id: 30,
      lowerLimit: 127612.75,
      upperLimit: 243633.75,
      fixedFee: 23942.19,
      porcentage: .30,
      year: 2021,
      month: 3,
      isrRetention: 6
    },
    {
      id: 31,
      lowerLimit: 243633.76,
      upperLimit: 324845.01,
      fixedFee: 58748.49,
      porcentage: .32,
      year: 2021,
      month: 3,
      isrRetention: 6
    },
    {
      id: 32,
      lowerLimit: 324845.02,
      upperLimit: 974535.03,
      fixedFee: 84736.08,
      porcentage: .34,
      year: 2021,
      month: 3,
      isrRetention: 6
    },
    {
      id: 33,
      lowerLimit: 974535.04,
      upperLimit: null,
      fixedFee: 305630.70,
      porcentage: .35,
      year: 2021,
      month: 3,
      isrRetention: 6
    },
    {
      id: 34,
      lowerLimit: 0.01,
      upperLimit: 2578.32,
      fixedFee: 0.00,
      porcentage: .0192,
      year: 2021,
      month: 3,
      isrRetention: 6
    },
    {
      id: 35,
      lowerLimit: 2578.33,
      upperLimit: 21883.68,
      fixedFee: 49.52,
      porcentage: .0640,
      year: 2021,
      month: 4,
      isrRetention: 8
    },
    {
      id: 36,
      lowerLimit: 21883.69,
      upperLimit: 38458.64,
      fixedFee: 1285.04,
      porcentage: .1088,
      year: 2021,
      month: 4,
      isrRetention: 8
    },
    {
      id: 37,
      lowerLimit: 38458.65,
      upperLimit: 44706.48,
      fixedFee: 3088.40,
      porcentage: .16,
      year: 2021,
      month: 4,
      isrRetention: 8
    },
    {
      id: 38,
      lowerLimit: 44706.49,
      upperLimit: 53525.88,
      fixedFee: 4088.04,
      porcentage: .1792,
      year: 2021,
      month: 4,
      isrRetention: 8
    },
    {
      id: 39,
      lowerLimit: 53525.89,
      upperLimit: 107954.00,
      fixedFee: 5668.48,
      porcentage: .2136,
      year: 2021,
      month: 4,
      isrRetention: 8
    },
    {
      id: 40,
      lowerLimit: 107954.01,
      upperLimit: 170150.32,
      fixedFee: 17294.32,
      porcentage: .2352,
      year: 2021,
      month: 4,
      isrRetention: 8
    },
    {
      id: 41,
      lowerLimit: 170150.33,
      upperLimit: 324845.00,
      fixedFee: 31922.92,
      porcentage: .30,
      year: 2021,
      month: 4,
      isrRetention: 8
    },
    {
      id: 42,
      lowerLimit: 324845.01,
      upperLimit: 433126.68,
      fixedFee: 78331.32,
      porcentage: .32,
      year: 2021,
      month: 4,
      isrRetention: 8
    },
    {
      id: 43,
      lowerLimit: 433126.69,
      upperLimit: 1299380.04,
      fixedFee: 112981.44,
      porcentage: .3400,
      year: 2021,
      month: 4,
      isrRetention: 8
    },
    {
      id: 44,
      lowerLimit: 1299380.05,
      upperLimit: null,
      fixedFee: 407507.60,
      porcentage: .35,
      year: 2021,
      month: 4,
    },
    {
      id: 45,
      lowerLimit: 0.01,
      upperLimit: 3222.90,
      fixedFee: 0,
      porcentage: 0.0192,
      year: 2021,
      month: 5
    },
    {
      id: 46,
      lowerLimit: 3222.91,
      upperLimit: 27354.60,
      fixedFee: 61.90,
      porcentage: 0.064,
      year: 2021,
      month: 5
    },
    {
      id: 47,
      lowerLimit: 27354.61,
      upperLimit: 48073.30,
      fixedFee: 1606.30,
      porcentage: 0.1088,
      year: 2021,
      month: 5
    },
    {
      id: 48,
      lowerLimit: 48073.31,
      upperLimit: 55883.10,
      fixedFee: 3860.50,
      porcentage: .16,
      year: 2021,
      month: 5
    },
    {
      id: 49,
      lowerLimit: 55883.11,
      upperLimit: 66907.35,
      fixedFee: 5110.05,
      porcentage: .1792,
      year: 2021,
      month: 5
    },
    {
      id: 50,
      lowerLimit: 66907.36,
      upperLimit: 134942.50,
      fixedFee: 7085.60,
      porcentage: .2136,
      year: 2021,
      month: 5
    },
    {
      id: 51,
      lowerLimit: 134942.51,
      upperLimit: 212687.90,
      fixedFee: 21617.90,
      porcentage: .2352,
      year: 2021,
      month: 5
    },
    {
      id: 52,
      lowerLimit: 212687.91,
      upperLimit: 406056.25,
      fixedFee: 39903.65,
      porcentage: .3000,
      year: 2021,
      month: 5
    },
    {
      id: 53,
      lowerLimit: 406056.26,
      upperLimit: 541408.35,
      fixedFee: 97914.15,
      porcentage: .3200,
      year: 2021,
      month: 5
    },
    {
      id: 54,
      lowerLimit: 541408.36,
      upperLimit: 1624225.05,
      fixedFee: 141226.80,
      porcentage: .34,
      year: 2021,
      month: 5
    },
    {
      id: 55,
      lowerLimit: 1624225.06,
      upperLimit: null,
      fixedFee: 509384.50,
      porcentage: .3500,
      year: 2021,
      month: 5
    },
    {
      id: 54,
      lowerLimit: 0.01,
      upperLimit: 3867.48,
      fixedFee: 0.00,
      porcentage: .0192,
      year: 2021,
      month: 6
    },
    {
      id: 55,
      lowerLimit: 3867.49,
      upperLimit: 32825.52,
      fixedFee: 74.28,
      porcentage: .0640,
      year: 2021,
      month: 6
    },
    {
      id: 54,
      lowerLimit: 32825.53,
      upperLimit: 57687.96,
      fixedFee: 1927.56,
      porcentage: .1088,
      year: 2021,
      month: 6
    },
    {
      id: 55,
      lowerLimit: 57687.97,
      upperLimit: 67059.72,
      fixedFee: 4632.60,
      porcentage: .1600,
      year: 2021,
      month: 6
    },
    {
      id: 54,
      lowerLimit: 67059.73,
      upperLimit: 80288.82,
      fixedFee: 6132.06,
      porcentage: .1792,
      year: 2021,
      month: 6
    },
    {
      id: 55,
      lowerLimit: 80288.83,
      upperLimit: 161931.00,
      fixedFee: 8502.72,
      porcentage: .2136,
      year: 2021,
      month: 6
    },
    {
      id: 54,
      lowerLimit: 161931.01,
      upperLimit: 255225.48,
      fixedFee: 25941.48,
      porcentage: .2352,
      year: 2021,
      month: 6
    },
    {
      id: 55,
      lowerLimit: 255225.49,
      upperLimit: 487267.50,
      fixedFee: 47884.38,
      porcentage: .3000,
      year: 2021,
      month: 6
    },
    {
      id: 54,
      lowerLimit: 487267.51,
      upperLimit: 649690.02,
      fixedFee: 117496.98,
      porcentage: .3200,
      year: 2021,
      month: 6
    },
    {
      id: 55,
      lowerLimit: 649690.03,
      upperLimit: 1949070.06,
      fixedFee: 169472.16,
      porcentage: .3400,
      year: 2021,
      month: 6
    },
    {
      id: 54,
      lowerLimit: 1949070.07,
      upperLimit: null,
      fixedFee: 611261.40,
      porcentage: .3500,
      year: 2021,
      month: 6
    },
    {
      id: 55,
      lowerLimit: 0.01,
      upperLimit: 4512.06,
      fixedFee: 0.00,
      porcentage: .0192,
      year: 2021,
      month: 7
    },
    {
      id: 54,
      lowerLimit: 4512.07,
      upperLimit: 38296.44,
      fixedFee: 86.66,
      porcentage: .0640,
      year: 2021,
      month: 7
    },
    {
      id: 55,
      lowerLimit: 38296.45,
      upperLimit: 67302.62,
      fixedFee: 2248.82,
      porcentage: .1088,
      year: 2021,
      month: 7
    },
    {
      id: 54,
      lowerLimit: 67302.63,
      upperLimit: 78236.34,
      fixedFee: 5404.70,
      porcentage: .1600,
      year: 2021,
      month: 7
    },
    {
      id: 55,
      lowerLimit: 78236.35,
      upperLimit: 93670.29,
      fixedFee: 7154.07,
      porcentage: .1792,
      year: 2021,
      month: 7
    },
    {
      id: 55,
      lowerLimit: 93670.30,
      upperLimit: 188919.50,
      fixedFee: 9919.84,
      porcentage: .2136,
      year: 2021,
      month: 7
    },
    {
      id: 55,
      lowerLimit: 188919.51,
      upperLimit: 297763.06,
      fixedFee: 30265.06,
      porcentage: .2352,
      year: 2021,
      month: 7
    },
    {
      id: 55,
      lowerLimit: 297763.07,
      upperLimit: 568478.75,
      fixedFee: 55865.11,
      porcentage: .3000,
      year: 2021,
      month: 7
    },
    {
      id: 55,
      lowerLimit: 568478.76,
      upperLimit: 757971.69,
      fixedFee: 137079.81,
      porcentage: .3200,
      year: 2021,
      month: 7
    },
    {
      id: 55,
      lowerLimit: 757971.70,
      upperLimit: 2273915.07,
      fixedFee: 197717.52,
      porcentage: .3400,
      year: 2021,
      month: 7
    },
    {
      id: 55,
      lowerLimit: 2273915.08,
      upperLimit: null,
      fixedFee: 713138.30,
      porcentage: .3500,
      year: 2021,
      month: 7
    },
    {
      id: 55,
      lowerLimit: 0.01,
      upperLimit: 5156.64,
      fixedFee: 0.00,
      porcentage: .0192,
      year: 2021,
      month: 8
    },
    {
      id: 55,
      lowerLimit: 5156.65,
      upperLimit: 43767.36,
      fixedFee: 99.04,
      porcentage: .0640,
      year: 2021,
      month: 8
    },
    {
      id: 55,
      lowerLimit: 43767.37,
      upperLimit: 76917.28,
      fixedFee: 2570.08,
      porcentage: .1088,
      year: 2021,
      month: 8
    },
    {
      id: 55,
      lowerLimit: 76917.29,
      upperLimit: 89412.96,
      fixedFee: 6176.80,
      porcentage: .1600,
      year: 2021,
      month: 8
    },
    {
      id: 55,
      lowerLimit: 89412.97,
      upperLimit: 107051.76,
      fixedFee: 8176.08,
      porcentage: .1792,
      year: 2021,
      month: 8
    },
    {
      id: 55,
      lowerLimit: 107051.77,
      upperLimit: 215908.00,
      fixedFee: 11336.96,
      porcentage: .2136,
      year: 2021,
      month: 8
    },
    {
      id: 55,
      lowerLimit: 215908.01,
      upperLimit: 340300.64,
      fixedFee: 34588.64,
      porcentage: .2352,
      year: 2021,
      month: 8
    },
    {
      id: 55,
      lowerLimit: 340300.65,
      upperLimit: 649690.00,
      fixedFee: 63845.84,
      porcentage: .3000,
      year: 2021,
      month: 8
    },
    {
      id: 55,
      lowerLimit: 649690.01,
      upperLimit: 866253.36,
      fixedFee: 156662.64,
      porcentage: .3200,
      year: 2021,
      month: 8
    },
    {
      id: 55,
      lowerLimit: 866253.37,
      upperLimit: 2598760.08,
      fixedFee: 225962.88,
      porcentage: .3400,
      year: 2021,
      month: 8
    },
    {
      id: 55,
      lowerLimit: 2598760.09,
      upperLimit: null,
      fixedFee: 815015.20,
      porcentage: .3500,
      year: 2021,
      month: 8
    },
    {
      id: 55,
      lowerLimit: 0.01,
      upperLimit: 5801.22,
      fixedFee: 0.00,
      porcentage: .0192,
      year: 2021,
      month: 9
    },
    {
      id: 55,
      lowerLimit: 5801.23,
      upperLimit: 49238.28,
      fixedFee: 111.42,
      porcentage: .0640,
      year: 2021,
      month: 9
    },
    {
      id: 55,
      lowerLimit: 49238.29,
      upperLimit: 86531.94,
      fixedFee: 2891.34,
      porcentage: .1088,
      year: 2021,
      month: 9
    },
    {
      id: 55,
      lowerLimit: 86531.95,
      upperLimit: 100589.58,
      fixedFee: 6948.90,
      porcentage: .1600,
      year: 2021,
      month: 9
    },
    {
      id: 55,
      lowerLimit: 100589.59,
      upperLimit: 120433.23,
      fixedFee: 9198.09,
      porcentage: .1792,
      year: 2021,
      month: 9
    },
    {
      id: 55,
      lowerLimit: 120433.24,
      upperLimit: 242896.50,
      fixedFee: 12754.08,
      porcentage: .2136,
      year: 2021,
      month: 9
    },
    {
      id: 55,
      lowerLimit: 242896.51,
      upperLimit: 382838.22,
      fixedFee: 38912.22,
      porcentage: .2352,
      year: 2021,
      month: 9
    },
    {
      id: 55,
      lowerLimit: 382838.23,
      upperLimit: 730901.25,
      fixedFee: 71826.57,
      porcentage: .3000,
      year: 2021,
      month: 9
    },
    {
      id: 55,
      lowerLimit: 730901.26,
      upperLimit: 974535.03,
      fixedFee: 176245.47,
      porcentage: .3200,
      year: 2021,
      month: 9
    },
    {
      id: 55,
      lowerLimit: 974535.04,
      upperLimit: 2923605.09,
      fixedFee: 254208.24,
      porcentage: .3400,
      year: 2021,
      month: 9
    },
    {
      id: 55,
      lowerLimit: 2923605.10,
      upperLimit: null,
      fixedFee: 916892.10,
      porcentage: .3500,
      year: 2021,
      month: 9
    },


    {
      id: 55,
      lowerLimit: 0.01,
      upperLimit: 6445.80,
      fixedFee: 0.00,
      porcentage: .0192,
      year: 2021,
      month: 10
    },
    {
      id: 55,
      lowerLimit: 6445.81,
      upperLimit: 54709.20,
      fixedFee: 123.80,
      porcentage: .0640,
      year: 2021,
      month: 10
    },
    {
      id: 55,
      lowerLimit: 96146.61,
      upperLimit: 111766.20,
      fixedFee: 7721.00,
      porcentage: .1600,
      year: 2021,
      month: 10
    },
    {
      id: 55,
      lowerLimit: 111766.21,
      upperLimit: 133814.70,
      fixedFee: 10220.10,
      porcentage: .1792,
      year: 2021,
      month: 10
    },
    {
      id: 55,
      lowerLimit: 133814.71,
      upperLimit: 269885.00,
      fixedFee: 14171.20,
      porcentage: .2136,
      year: 2021,
      month: 10
    },
    {
      id: 55,
      lowerLimit: 269885.01,
      upperLimit: 425375.80,
      fixedFee: 43235.80,
      porcentage: .2352,
      year: 2021,
      month: 10
    },
    {
      id: 55,
      lowerLimit: 425375.81,
      upperLimit: 812112.50,
      fixedFee: 79807.30,
      porcentage: .3000,
      year: 2021,
      month: 10
    },
    {
      id: 55,
      lowerLimit: 812112.51,
      upperLimit: 1082816.70,
      fixedFee: 195828.30,
      porcentage: .3200,
      year: 2021,
      month: 10
    },
    {
      id: 55,
      lowerLimit: 1082816.71,
      upperLimit: 3248450.10,
      fixedFee: 282453.60,
      porcentage: .3400,
      year: 2021,
      month: 10
    },
    {
      id: 55,
      lowerLimit: 3248450.11,
      upperLimit: null,
      fixedFee: 1018769.00,
      porcentage: .3500,
      year: 2021,
      month: 10
    },

    {
      id: 55,
      lowerLimit: 0.01,
      upperLimit: 7090.38,
      fixedFee: 0.00,
      porcentage: .0192,
      year: 2021,
      month: 11
    },
    {
      id: 55,
      lowerLimit: 7090.39,
      upperLimit: 60180.12,
      fixedFee: 136.18,
      porcentage: .0640,
      year: 2021,
      month: 11
    },
    {
      id: 55,
      lowerLimit: 60180.13,
      upperLimit: 105761.26,
      fixedFee: 3533.86,
      porcentage: .1088,
      year: 2021,
      month: 11
    },
    {
      id: 55,
      lowerLimit: 105761.27,
      upperLimit: 122942.82,
      fixedFee: 8493.10,
      porcentage: .1600,
      year: 2021,
      month: 11
    },
    {
      id: 55,
      lowerLimit: 122942.83,
      upperLimit: 147196.17,
      fixedFee: 11242.11,
      porcentage: .1792,
      year: 2021,
      month: 11
    },
    {
      id: 55,
      lowerLimit: 147196.18,
      upperLimit: 296873.50,
      fixedFee: 15588.32,
      porcentage: .2136,
      year: 2021,
      month: 11
    },
    {
      id: 55,
      lowerLimit: 296873.51,
      upperLimit: 467913.38,
      fixedFee: 47559.38,
      porcentage: .2352,
      year: 2021,
      month: 11
    },
    {
      id: 55,
      lowerLimit: 467913.39,
      upperLimit: 893323.75,
      fixedFee: 87788.03,
      porcentage: .3000,
      year: 2021,
      month: 11
    },
    {
      id: 55,
      lowerLimit: 893323.76,
      upperLimit: 1191098.37,
      fixedFee: 215411.13,
      porcentage: .3200,
      year: 2021,
      month: 11
    },
    {
      id: 55,
      lowerLimit: 1191098.38,
      upperLimit: 3573295.11,
      fixedFee: 310698.96,
      porcentage: .3400,
      year: 2021,
      month: 11
    },
    {
      id: 55,
      lowerLimit: 3573295.12,
      upperLimit: null,
      fixedFee: 1120645.90,
      porcentage: .3500,
      year: 2021,
      month: 11
    },
    {
      id: 55,
      lowerLimit: 0.01,
      upperLimit: 7735.00,
      fixedFee: 0.00,
      porcentage: .0192,
      year: 2021,
      month: 12
    },
    {
      id: 55,
      lowerLimit: 7735.01,
      upperLimit: 65651.07,
      fixedFee: 148.51,
      porcentage: .0640,
      year: 2021,
      month: 12
    },
    {
      id: 55,
      lowerLimit: 65651.08,
      upperLimit: 115375.90,
      fixedFee: 3855.14,
      porcentage: .1088,
      year: 2021,
      month: 12
    },
    {
      id: 55,
      lowerLimit: 115375.91,
      upperLimit: 134119.41,
      fixedFee: 9265.20,
      porcentage: .1600,
      year: 2021,
      month: 12
    },
    {
      id: 55,
      lowerLimit: 134119.42,
      upperLimit: 160577.65,
      fixedFee: 12264.16,
      porcentage: .1792,
      year: 2021,
      month: 12
    },
    {
      id: 55,
      lowerLimit: 160577.66,
      upperLimit: 323862.00,
      fixedFee: 17005.47,
      porcentage: .2136,
      year: 2021,
      month: 12
    },
    {
      id: 55,
      lowerLimit: 323862.01,
      upperLimit: 510451.00,
      fixedFee: 51883.01,
      porcentage: .2352,
      year: 2021,
      month: 12
    },
    {
      id: 55,
      lowerLimit: 510451.01,
      upperLimit: 974535.03,
      fixedFee: 95768.74,
      porcentage: .3000,
      year: 2021,
      month: 12
    },
    {
      id: 55,
      lowerLimit: 974535.04,
      upperLimit: 1299380.04,
      fixedFee: 234993.95,
      porcentage: .3200,
      year: 2021,
      month: 12
    },
    {
      id: 55,
      lowerLimit: 1299380.05,
      upperLimit: 3898140.12,
      fixedFee: 338944.34,
      porcentage: .3400,
      year: 2021,
      month: 12
    },
    {
      id: 55,
      lowerLimit: 3898140.13,
      upperLimit: null,
      fixedFee: 1222522.76,
      porcentage: .3500,
      year: 2021,
      month: 12
    },

    {
      id: 1,
      lowerLimit: 0.01,
      upperLimit: 644.58,
      fixedFee: 0.00,
      porcentage: .0192,
      year: 2022,
      month: 1,
      isrRetention: 2
    },
    {
      id: 2,
      lowerLimit: 644.59,
      upperLimit: 5470.92,
      fixedFee: 12.38,
      porcentage: .0640,
      year: 2022,
      month: 1,
      isrRetention: 2
    },
    {
      id: 3,
      lowerLimit: 5470.93,
      upperLimit: 9614.66,
      fixedFee: 321.26,
      porcentage: .1088,
      year: 2022,
      month: 1,
      isrRetention: 2
    },
    {
      id: 4,
      lowerLimit: 9614.67,
      upperLimit: 11176.62,
      fixedFee: 772.10,
      porcentage: .16,
      year: 2022,
      month: 1,
      isrRetention: 2
    },
    {
      id: 5,
      lowerLimit: 11176.63,
      upperLimit: 13381.47,
      fixedFee: 1022.01,
      porcentage: .1792,
      year: 2022,
      month: 1,
      isrRetention: 2
    },
    {
      id: 6,
      lowerLimit: 13381.48,
      upperLimit: 26988.50,
      fixedFee: 1417.12,
      porcentage: .2136,
      year: 2022,
      month: 1,
      isrRetention: 2
    },
    {
      id: 7,
      lowerLimit: 26988.51,
      upperLimit: 42537.58,
      fixedFee: 4323.58,
      porcentage: .2352,
      year: 2022,
      month: 1,
      isrRetention: 2
    },
    {
      id: 8,
      lowerLimit: 42537.59,
      upperLimit: 81211.25,
      fixedFee: 7980.73,
      porcentage: .30,
      year: 2022,
      month: 1,
      isrRetention: 2
    },
    {
      id: 9,
      lowerLimit: 81211.26,
      upperLimit: 108281.67,
      fixedFee: 19582.83,
      porcentage: .32,
      year: 2022,
      month: 1,
      isrRetention: 2
    },
    {
      id: 10,
      lowerLimit: 108281.68,
      upperLimit: 324845.01,
      fixedFee: 28245.36,
      porcentage: .34,
      year: 2022,
      month: 1,
      isrRetention: 2
    },
    {
      id: 11,
      lowerLimit: 324845.02,
      upperLimit: null,
      fixedFee: 101876.90,
      porcentage: .35,
      year: 2022,
      month: 1,
      isrRetention: 2
    },
    {
      id: 12,
      lowerLimit: 0.01,
      upperLimit: 1289.16,
      fixedFee: 0.00,
      porcentage: .0192,
      year: 2022,
      month: 2,
      isrRetention: 4
    },
    {
      id: 13,
      lowerLimit: 1289.17,
      upperLimit: 10941.84,
      fixedFee: 24.76,
      porcentage: .0640,
      year: 2022,
      month: 2,
      isrRetention: 4
    },
    {
      id: 14,
      lowerLimit: 10941.85,
      upperLimit: 19229.32,
      fixedFee: 642.52,
      porcentage: .1088,
      year: 2022,
      month: 2,
      isrRetention: 4
    },
    {
      id: 15,
      lowerLimit: 19229.33,
      upperLimit: 22353.24,
      fixedFee: 1544.20,
      porcentage: .16,
      year: 2022,
      month: 2,
      isrRetention: 4
    },
    {
      id: 16,
      lowerLimit: 22353.25,
      upperLimit: 26762.94,
      fixedFee: 2044.02,
      porcentage: .1792,
      year: 2022,
      month: 2,
      isrRetention: 4
    },
    {
      id: 17,
      lowerLimit: 26762.95,
      upperLimit: 53977.00,
      fixedFee: 2834.24,
      porcentage: .2136,
      year: 2022,
      month: 2,
      isrRetention: 4
    },
    {
      id: 18,
      lowerLimit: 53977.01,
      upperLimit: 85075.16,
      fixedFee: 8647.16,
      porcentage: .2352,
      year: 2022,
      month: 2,
      isrRetention: 4
    },
    {
      id: 19,
      lowerLimit: 85075.17,
      upperLimit: 162422.50,
      fixedFee: 15961.46,
      porcentage: .30,
      year: 2022,
      month: 2,
      isrRetention: 4
    },
    {
      id: 20,
      lowerLimit: 162422.51,
      upperLimit: 216563.34,
      fixedFee: 39165.66,
      porcentage: .32,
      year: 2022,
      month: 2,
      isrRetention: 4
    },
    {
      id: 21,
      lowerLimit: 216563.35,
      upperLimit: 649690.02,
      fixedFee: 56490.72,
      porcentage: .34,
      year: 2022,
      month: 2,
      isrRetention: 4
    },
    {
      id: 22,
      lowerLimit: 649690.03,
      upperLimit: null,
      fixedFee: 203753.80,
      porcentage: .35,
      year: 2022,
      month: 2,
      isrRetention: 4
    },
    {
      id: 23,
      lowerLimit: 0.01,
      upperLimit: 1933.74,
      fixedFee: 0.00,
      porcentage: .0192,
      year: 2022,
      month: 3,
      isrRetention: 6
    },
    {
      id: 24,
      lowerLimit: 1933.75,
      upperLimit: 16412.76,
      fixedFee: 37.14,
      porcentage: .0640,
      year: 2022,
      month: 3,
      isrRetention: 6
    },
    {
      id: 25,
      lowerLimit: 16412.77,
      upperLimit: 28843.98,
      fixedFee: 963.78,
      porcentage: .1088,
      year: 2022,
      month: 3,
      isrRetention: 6
    },
    {
      id: 26,
      lowerLimit: 28843.99,
      upperLimit: 33529.86,
      fixedFee: 2316.30,
      porcentage: .16,
      year: 2022,
      month: 3,
      isrRetention: 6
    },
    {
      id: 27,
      lowerLimit: 33529.87,
      upperLimit: 40144.41,
      fixedFee: 3066.03,
      porcentage: .1792,
      year: 2022,
      month: 3,
      isrRetention: 6
    },
    {
      id: 28,
      lowerLimit: 40144.42,
      upperLimit: 80965.50,
      fixedFee: 4251.36,
      porcentage: .2136,
      year: 2022,
      month: 3,
      isrRetention: 6
    },
    {
      id: 29,
      lowerLimit: 80965.51,
      upperLimit: 127612.74,
      fixedFee: 12970.74,
      porcentage: .2352,
      year: 2022,
      month: 3,
      isrRetention: 6
    },
    {
      id: 30,
      lowerLimit: 127612.75,
      upperLimit: 243633.75,
      fixedFee: 23942.19,
      porcentage: .30,
      year: 2022,
      month: 3,
      isrRetention: 6
    },
    {
      id: 31,
      lowerLimit: 243633.76,
      upperLimit: 324845.01,
      fixedFee: 58748.49,
      porcentage: .32,
      year: 2022,
      month: 3,
      isrRetention: 6
    },
    {
      id: 32,
      lowerLimit: 324845.02,
      upperLimit: 974535.03,
      fixedFee: 84736.08,
      porcentage: .34,
      year: 2022,
      month: 3,
      isrRetention: 6
    },
    {
      id: 33,
      lowerLimit: 974535.04,
      upperLimit: null,
      fixedFee: 305630.70,
      porcentage: .35,
      year: 2022,
      month: 3,
      isrRetention: 6
    },
    {
      id: 34,
      lowerLimit: 0.01,
      upperLimit: 2578.32,
      fixedFee: 0.00,
      porcentage: .0192,
      year: 2022,
      month: 4,
      isrRetention: 6
    },
    {
      id: 35,
      lowerLimit: 2578.33,
      upperLimit: 21883.68,
      fixedFee: 49.52,
      porcentage: .0640,
      year: 2022,
      month: 4,
      isrRetention: 8
    },
    {
      id: 36,
      lowerLimit: 21883.69,
      upperLimit: 38458.64,
      fixedFee: 1285.04,
      porcentage: .1088,
      year: 2022,
      month: 4,
      isrRetention: 8
    },
    {
      id: 37,
      lowerLimit: 38458.65,
      upperLimit: 44706.48,
      fixedFee: 3088.40,
      porcentage: .16,
      year: 2022,
      month: 4,
      isrRetention: 8
    },
    {
      id: 38,
      lowerLimit: 44706.49,
      upperLimit: 53525.88,
      fixedFee: 4088.04,
      porcentage: .1792,
      year: 2022,
      month: 4,
      isrRetention: 8
    },
    {
      id: 39,
      lowerLimit: 53525.89,
      upperLimit: 107954.00,
      fixedFee: 5668.48,
      porcentage: .2136,
      year: 2022,
      month: 4,
      isrRetention: 8
    },
    {
      id: 40,
      lowerLimit: 107954.01,
      upperLimit: 170150.32,
      fixedFee: 17294.32,
      porcentage: .2352,
      year: 2022,
      month: 4,
      isrRetention: 8
    },
    {
      id: 41,
      lowerLimit: 170150.33,
      upperLimit: 324845.00,
      fixedFee: 31922.92,
      porcentage: .30,
      year: 2022,
      month: 4,
      isrRetention: 8
    },
    {
      id: 42,
      lowerLimit: 324845.01,
      upperLimit: 433126.68,
      fixedFee: 78331.32,
      porcentage: .32,
      year: 2022,
      month: 4,
      isrRetention: 8
    },
    {
      id: 43,
      lowerLimit: 433126.69,
      upperLimit: 1299380.04,
      fixedFee: 112981.44,
      porcentage: .3400,
      year: 2022,
      month: 4,
      isrRetention: 8
    },
    {
      id: 44,
      lowerLimit: 1299380.05,
      upperLimit: null,
      fixedFee: 407507.60,
      porcentage: .35,
      year: 2022,
      month: 4,
    },
    {
      id: 45,
      lowerLimit: 0.01,
      upperLimit: 3222.90,
      fixedFee: 0,
      porcentage: 0.0192,
      year: 2022,
      month: 5
    },
    {
      id: 46,
      lowerLimit: 3222.91,
      upperLimit: 27354.60,
      fixedFee: 61.90,
      porcentage: 0.064,
      year: 2022,
      month: 5
    },
    {
      id: 47,
      lowerLimit: 27354.61,
      upperLimit: 48073.30,
      fixedFee: 1606.30,
      porcentage: 0.1088,
      year: 2022,
      month: 5
    },
    {
      id: 48,
      lowerLimit: 48073.31,
      upperLimit: 55883.10,
      fixedFee: 3860.50,
      porcentage: .16,
      year: 2022,
      month: 5
    },
    {
      id: 49,
      lowerLimit: 55883.11,
      upperLimit: 66907.35,
      fixedFee: 5110.05,
      porcentage: .1792,
      year: 2022,
      month: 5
    },
    {
      id: 50,
      lowerLimit: 66907.36,
      upperLimit: 134942.50,
      fixedFee: 7085.60,
      porcentage: .2136,
      year: 2022,
      month: 5
    },
    {
      id: 51,
      lowerLimit: 134942.51,
      upperLimit: 212687.90,
      fixedFee: 21617.90,
      porcentage: .2352,
      year: 2022,
      month: 5
    },
    {
      id: 52,
      lowerLimit: 212687.91,
      upperLimit: 406056.25,
      fixedFee: 39903.65,
      porcentage: .3000,
      year: 2022,
      month: 5
    },
    {
      id: 53,
      lowerLimit: 406056.26,
      upperLimit: 541408.35,
      fixedFee: 97914.15,
      porcentage: .3200,
      year: 2022,
      month: 5
    },
    {
      id: 54,
      lowerLimit: 541408.36,
      upperLimit: 1624225.05,
      fixedFee: 141226.80,
      porcentage: .34,
      year: 2022,
      month: 5
    },
    {
      id: 55,
      lowerLimit: 1624225.06,
      upperLimit: null,
      fixedFee: 509384.50,
      porcentage: .3500,
      year: 2022,
      month: 5
    },
    {
      id: 54,
      lowerLimit: 0.01,
      upperLimit: 3867.48,
      fixedFee: 0.00,
      porcentage: .0192,
      year: 2022,
      month: 6
    },
    {
      id: 55,
      lowerLimit: 3867.49,
      upperLimit: 32825.52,
      fixedFee: 74.28,
      porcentage: .0640,
      year: 2022,
      month: 6
    },
    {
      id: 54,
      lowerLimit: 32825.53,
      upperLimit: 57687.96,
      fixedFee: 1927.56,
      porcentage: .1088,
      year: 2022,
      month: 6
    },
    {
      id: 55,
      lowerLimit: 57687.97,
      upperLimit: 67059.72,
      fixedFee: 4632.60,
      porcentage: .1600,
      year: 2022,
      month: 6
    },
    {
      id: 54,
      lowerLimit: 67059.73,
      upperLimit: 80288.82,
      fixedFee: 6132.06,
      porcentage: .1792,
      year: 2022,
      month: 6
    },
    {
      id: 55,
      lowerLimit: 80288.83,
      upperLimit: 161931.00,
      fixedFee: 8502.72,
      porcentage: .2136,
      year: 2022,
      month: 6
    },
    {
      id: 54,
      lowerLimit: 161931.01,
      upperLimit: 255225.48,
      fixedFee: 25941.48,
      porcentage: .2352,
      year: 2022,
      month: 6
    },
    {
      id: 55,
      lowerLimit: 255225.49,
      upperLimit: 487267.50,
      fixedFee: 47884.38,
      porcentage: .3000,
      year: 2022,
      month: 6
    },
    {
      id: 54,
      lowerLimit: 487267.51,
      upperLimit: 649690.02,
      fixedFee: 117496.98,
      porcentage: .3200,
      year: 2022,
      month: 6
    },
    {
      id: 55,
      lowerLimit: 649690.03,
      upperLimit: 1949070.06,
      fixedFee: 169472.16,
      porcentage: .3400,
      year: 2022,
      month: 6
    },
    {
      id: 54,
      lowerLimit: 1949070.07,
      upperLimit: null,
      fixedFee: 611261.40,
      porcentage: .3500,
      year: 2022,
      month: 6
    },
    {
      id: 55,
      lowerLimit: 0.01,
      upperLimit: 4512.06,
      fixedFee: 0.00,
      porcentage: .0192,
      year: 2022,
      month: 7
    },
    {
      id: 54,
      lowerLimit: 4512.07,
      upperLimit: 38296.44,
      fixedFee: 86.66,
      porcentage: .0640,
      year: 2022,
      month: 7
    },
    {
      id: 55,
      lowerLimit: 38296.45,
      upperLimit: 67302.62,
      fixedFee: 2248.82,
      porcentage: .1088,
      year: 2022,
      month: 7
    },
    {
      id: 54,
      lowerLimit: 67302.63,
      upperLimit: 78236.34,
      fixedFee: 5404.70,
      porcentage: .1600,
      year: 2022,
      month: 7
    },
    {
      id: 55,
      lowerLimit: 78236.35,
      upperLimit: 93670.29,
      fixedFee: 7154.07,
      porcentage: .1792,
      year: 2022,
      month: 7
    },
    {
      id: 55,
      lowerLimit: 93670.30,
      upperLimit: 188919.50,
      fixedFee: 9919.84,
      porcentage: .2136,
      year: 2022,
      month: 7
    },
    {
      id: 55,
      lowerLimit: 188919.51,
      upperLimit: 297763.06,
      fixedFee: 30265.06,
      porcentage: .2352,
      year: 2022,
      month: 7
    },
    {
      id: 55,
      lowerLimit: 297763.07,
      upperLimit: 568478.75,
      fixedFee: 55865.11,
      porcentage: .3000,
      year: 2022,
      month: 7
    },
    {
      id: 55,
      lowerLimit: 568478.76,
      upperLimit: 757971.69,
      fixedFee: 137079.81,
      porcentage: .3200,
      year: 2022,
      month: 7
    },
    {
      id: 55,
      lowerLimit: 757971.70,
      upperLimit: 2273915.07,
      fixedFee: 197717.52,
      porcentage: .3400,
      year: 2022,
      month: 7
    },
    {
      id: 55,
      lowerLimit: 2273915.08,
      upperLimit: null,
      fixedFee: 713138.30,
      porcentage: .3500,
      year: 2022,
      month: 7
    },
    {
      id: 55,
      lowerLimit: 0.01,
      upperLimit: 5156.64,
      fixedFee: 0.00,
      porcentage: .0192,
      year: 2022,
      month: 8
    },
    {
      id: 55,
      lowerLimit: 5156.65,
      upperLimit: 43767.36,
      fixedFee: 99.04,
      porcentage: .0640,
      year: 2022,
      month: 8
    },
    {
      id: 55,
      lowerLimit: 43767.37,
      upperLimit: 76917.28,
      fixedFee: 2570.08,
      porcentage: .1088,
      year: 2022,
      month: 8
    },
    {
      id: 55,
      lowerLimit: 76917.29,
      upperLimit: 89412.96,
      fixedFee: 6176.80,
      porcentage: .1600,
      year: 2022,
      month: 8
    },
    {
      id: 55,
      lowerLimit: 89412.97,
      upperLimit: 107051.76,
      fixedFee: 8176.08,
      porcentage: .1792,
      year: 2022,
      month: 8
    },
    {
      id: 55,
      lowerLimit: 107051.77,
      upperLimit: 215908.00,
      fixedFee: 11336.96,
      porcentage: .2136,
      year: 2022,
      month: 8
    },
    {
      id: 55,
      lowerLimit: 215908.01,
      upperLimit: 340300.64,
      fixedFee: 34588.64,
      porcentage: .2352,
      year: 2022,
      month: 8
    },
    {
      id: 55,
      lowerLimit: 340300.65,
      upperLimit: 649690.00,
      fixedFee: 63845.84,
      porcentage: .3000,
      year: 2022,
      month: 8
    },
    {
      id: 55,
      lowerLimit: 649690.01,
      upperLimit: 866253.36,
      fixedFee: 156662.64,
      porcentage: .3200,
      year: 2022,
      month: 8
    },
    {
      id: 55,
      lowerLimit: 866253.37,
      upperLimit: 2598760.08,
      fixedFee: 225962.88,
      porcentage: .3400,
      year: 2022,
      month: 8
    },
    {
      id: 55,
      lowerLimit: 2598760.09,
      upperLimit: null,
      fixedFee: 815015.20,
      porcentage: .3500,
      year: 2022,
      month: 8
    },
    {
      id: 55,
      lowerLimit: 0.01,
      upperLimit: 5801.22,
      fixedFee: 0.00,
      porcentage: .0192,
      year: 2022,
      month: 9
    },
    {
      id: 55,
      lowerLimit: 5801.23,
      upperLimit: 49238.28,
      fixedFee: 111.42,
      porcentage: .0640,
      year: 2022,
      month: 9
    },
    {
      id: 55,
      lowerLimit: 49238.29,
      upperLimit: 86531.94,
      fixedFee: 2891.34,
      porcentage: .1088,
      year: 2022,
      month: 9
    },
    {
      id: 55,
      lowerLimit: 86531.95,
      upperLimit: 100589.58,
      fixedFee: 6948.90,
      porcentage: .1600,
      year: 2022,
      month: 9
    },
    {
      id: 55,
      lowerLimit: 100589.59,
      upperLimit: 120433.23,
      fixedFee: 9198.09,
      porcentage: .1792,
      year: 2022,
      month: 9
    },
    {
      id: 55,
      lowerLimit: 120433.24,
      upperLimit: 242896.50,
      fixedFee: 12754.08,
      porcentage: .2136,
      year: 2022,
      month: 9
    },
    {
      id: 55,
      lowerLimit: 242896.51,
      upperLimit: 382838.22,
      fixedFee: 38912.22,
      porcentage: .2352,
      year: 2022,
      month: 9
    },
    {
      id: 55,
      lowerLimit: 382838.23,
      upperLimit: 730901.25,
      fixedFee: 71826.57,
      porcentage: .3000,
      year: 2022,
      month: 9
    },
    {
      id: 55,
      lowerLimit: 730901.26,
      upperLimit: 974535.03,
      fixedFee: 176245.47,
      porcentage: .3200,
      year: 2022,
      month: 9
    },
    {
      id: 55,
      lowerLimit: 974535.04,
      upperLimit: 2923605.09,
      fixedFee: 254208.24,
      porcentage: .3400,
      year: 2022,
      month: 9
    },
    {
      id: 55,
      lowerLimit: 2923605.10,
      upperLimit: null,
      fixedFee: 916892.10,
      porcentage: .3500,
      year: 2022,
      month: 9
    },


    {
      id: 55,
      lowerLimit: 0.01,
      upperLimit: 6445.80,
      fixedFee: 0.00,
      porcentage: .0192,
      year: 2022,
      month: 10
    },
    {
      id: 55,
      lowerLimit: 6445.81,
      upperLimit: 54709.20,
      fixedFee: 123.80,
      porcentage: .0640,
      year: 2022,
      month: 10
    },
    {
      id: 55,
      lowerLimit: 54709.21,
      upperLimit: 96146.60,
      fixedFee: 3212.60,
      porcentage: .1088,
      year: 2022,
      month: 10
    },
    {
      id: 55,
      lowerLimit: 96146.61,
      upperLimit: 111766.20,
      fixedFee: 7721.00,
      porcentage: .1600,
      year: 2022,
      month: 10
    },
    {
      id: 55,
      lowerLimit: 111766.21,
      upperLimit: 133814.70,
      fixedFee: 10220.10,
      porcentage: .1792,
      year: 2022,
      month: 10
    },
    {
      id: 55,
      lowerLimit: 133814.71,
      upperLimit: 269885.00,
      fixedFee: 14171.20,
      porcentage: .2136,
      year: 2022,
      month: 10
    },
    {
      id: 55,
      lowerLimit: 269885.01,
      upperLimit: 425375.80,
      fixedFee: 43235.80,
      porcentage: .2352,
      year: 2022,
      month: 10
    },
    {
      id: 55,
      lowerLimit: 425375.81,
      upperLimit: 812112.50,
      fixedFee: 79807.30,
      porcentage: .3000,
      year: 2022,
      month: 10
    },
    {
      id: 55,
      lowerLimit: 812112.51,
      upperLimit: 1082816.70,
      fixedFee: 195828.30,
      porcentage: .3200,
      year: 2022,
      month: 10
    },
    {
      id: 55,
      lowerLimit: 1082816.71,
      upperLimit: 3248450.10,
      fixedFee: 282453.60,
      porcentage: .3400,
      year: 2022,
      month: 10
    },
    {
      id: 55,
      lowerLimit: 3248450.11,
      upperLimit: null,
      fixedFee: 1018769.00,
      porcentage: .3500,
      year: 2022,
      month: 10
    },





    {
      id: 55,
      lowerLimit: 0.01,
      upperLimit: 7090.38,
      fixedFee: 0.00,
      porcentage: .0192,
      year: 2022,
      month: 11
    },
    {
      id: 55,
      lowerLimit: 7090.39,
      upperLimit: 60180.12,
      fixedFee: 136.18,
      porcentage: .0640,
      year: 2022,
      month: 11
    },
    {
      id: 55,
      lowerLimit: 60180.13,
      upperLimit: 105761.26,
      fixedFee: 3533.86,
      porcentage: .1088,
      year: 2022,
      month: 11
    },
    {
      id: 55,
      lowerLimit: 105761.27,
      upperLimit: 122942.82,
      fixedFee: 8493.10,
      porcentage: .1600,
      year: 2022,
      month: 11
    },
    {
      id: 55,
      lowerLimit: 122942.83,
      upperLimit: 147196.17,
      fixedFee: 11242.11,
      porcentage: .1792,
      year: 2022,
      month: 11
    },
    {
      id: 55,
      lowerLimit: 147196.18,
      upperLimit: 296873.50,
      fixedFee: 15588.32,
      porcentage: .2136,
      year: 2022,
      month: 11
    },
    {
      id: 55,
      lowerLimit: 296873.51,
      upperLimit: 467913.38,
      fixedFee: 47559.38,
      porcentage: .2352,
      year: 2022,
      month: 11
    },
    {
      id: 55,
      lowerLimit: 467913.39,
      upperLimit: 893323.75,
      fixedFee: 87788.03,
      porcentage: .3000,
      year: 2022,
      month: 11
    },
    {
      id: 55,
      lowerLimit: 893323.76,
      upperLimit: 1191098.37,
      fixedFee: 215411.13,
      porcentage: .3200,
      year: 2022,
      month: 11
    },
    {
      id: 55,
      lowerLimit: 1191098.38,
      upperLimit: 3573295.11,
      fixedFee: 310698.96,
      porcentage: .3400,
      year: 2022,
      month: 11
    },
    {
      id: 55,
      lowerLimit: 3573295.12,
      upperLimit: null,
      fixedFee: 1120645.90,
      porcentage: .3500,
      year: 2022,
      month: 11
    },
    {
      id: 55,
      lowerLimit: 0.01,
      upperLimit: 7735.00,
      fixedFee: 0.00,
      porcentage: .0192,
      year: 2022,
      month: 12
    },
    {
      id: 55,
      lowerLimit: 7735.01,
      upperLimit: 65651.07,
      fixedFee: 148.51,
      porcentage: .0640,
      year: 2022,
      month: 12
    },
    {
      id: 55,
      lowerLimit: 65651.08,
      upperLimit: 115375.90,
      fixedFee: 3855.14,
      porcentage: .1088,
      year: 2022,
      month: 12
    },
    {
      id: 55,
      lowerLimit: 115375.91,
      upperLimit: 134119.41,
      fixedFee: 9265.20,
      porcentage: .1600,
      year: 2022,
      month: 12
    },
    {
      id: 55,
      lowerLimit: 134119.42,
      upperLimit: 160577.65,
      fixedFee: 12264.16,
      porcentage: .1792,
      year: 2022,
      month: 12
    },
    {
      id: 55,
      lowerLimit: 160577.66,
      upperLimit: 323862.00,
      fixedFee: 17005.47,
      porcentage: .2136,
      year: 2022,
      month: 12
    },
    {
      id: 55,
      lowerLimit: 323862.01,
      upperLimit: 510451.00,
      fixedFee: 51883.01,
      porcentage: .2352,
      year: 2022,
      month: 12
    },
    {
      id: 55,
      lowerLimit: 510451.01,
      upperLimit: 974535.03,
      fixedFee: 95768.74,
      porcentage: .3000,
      year: 2022,
      month: 12
    },
    {
      id: 55,
      lowerLimit: 974535.04,
      upperLimit: 1299380.04,
      fixedFee: 234993.95,
      porcentage: .3200,
      year: 2022,
      month: 12
    },
    {
      id: 55,
      lowerLimit: 1299380.05,
      upperLimit: 3898140.12,
      fixedFee: 338944.34,
      porcentage: .3400,
      year: 2022,
      month: 12
    },
    {
      id: 55,
      lowerLimit: 3898140.13,
      upperLimit: null,
      fixedFee: 1222522.76,
      porcentage: .3500,
      year: 2022,
      month: 12
    },
    {
      id: 1,
      lowerLimit: 0.01,
      upperLimit: 746.04,
      fixedFee: 0.00,
      porcentage: .0192,
      year: 2023,
      month: 1,
      isrRetention: 2
    },
    {
      id: 2,
      lowerLimit: 746.05,
      upperLimit: 6332.05,
      fixedFee: 14.32,
      porcentage: .0640,
      year: 2023,
      month: 1,
      isrRetention: 2
    },
    {
      id: 3,
      lowerLimit: 6332.06,
      upperLimit: 11128.01,
      fixedFee: 371.83,
      porcentage: .1088,
      year: 2023,
      month: 1,
      isrRetention: 2
    },
    {
      id: 4,
      lowerLimit: 11128.02,
      upperLimit: 12935.82,
      fixedFee: 893.63,
      porcentage: .16,
      year: 2023,
      month: 1,
      isrRetention: 2
    },
    {
      id: 5,
      lowerLimit: 12935.83,
      upperLimit: 15487.71,
      fixedFee: 1182.88,
      porcentage: .1792,
      year: 2023,
      month: 1,
      isrRetention: 2
    },
    {
      id: 6,
      lowerLimit: 15487.72,
      upperLimit: 31236.49,
      fixedFee: 1640.18,
      porcentage: .2136,
      year: 2023,
      month: 1,
      isrRetention: 2
    },
    {
      id: 7,
      lowerLimit: 31236.50,
      upperLimit: 49233.00,
      fixedFee: 5004.12,
      porcentage: .2352,
      year: 2023,
      month: 1,
      isrRetention: 2
    },
    {
      id: 8,
      lowerLimit: 49233.01,
      upperLimit: 93993.90,
      fixedFee: 9236.89,
      porcentage: .30,
      year: 2023,
      month: 1,
      isrRetention: 2
    },
    {
      id: 9,
      lowerLimit: 93993.91,
      upperLimit: 125325.20,
      fixedFee: 22665.17,
      porcentage: .32,
      year: 2023,
      month: 1,
      isrRetention: 2
    },
    {
      id: 10,
      lowerLimit: 125325.21,
      upperLimit: 375975.61,
      fixedFee: 32691.18,
      porcentage: .34,
      year: 2023,
      month: 1,
      isrRetention: 2
    },
    {
      id: 11,
      lowerLimit: 375975.62,
      upperLimit: null,
      fixedFee: 117912.32,
      porcentage: .35,
      year: 2023,
      month: 1,
      isrRetention: 2
    },
    {
      id: 1,
      lowerLimit: 0.01,
      upperLimit: 1492.08,
      fixedFee: 0.00,
      porcentage: .0192,
      year: 2023,
      month: 2,
      isrRetention: 2
    },
    {
      id: 2,
      lowerLimit: 1492.09,
      upperLimit: 12664.10,
      fixedFee: 28.64,
      porcentage: .0640,
      year: 2023,
      month: 2,
      isrRetention: 2
    },
    {
      id: 3,
      lowerLimit: 12664.11,
      upperLimit: 22256.02,
      fixedFee: 743.66,
      porcentage: .1088,
      year: 2023,
      month: 2,
      isrRetention: 2
    },
    {
      id: 4,
      lowerLimit: 22256.03,
      upperLimit: 25871.64,
      fixedFee: 1787.26,
      porcentage: .16,
      year: 2023,
      month: 2,
      isrRetention: 2
    },
    {
      id: 5,
      lowerLimit: 25871.65,
      upperLimit: 30975.42,
      fixedFee: 2365.76,
      porcentage: .1792,
      year: 2023,
      month: 2,
      isrRetention: 2
    },
    {
      id: 6,
      lowerLimit: 30975.43,
      upperLimit: 62472.98,
      fixedFee: 3280.36,
      porcentage: .2136,
      year: 2023,
      month: 2,
      isrRetention: 2
    },
    {
      id: 7,
      lowerLimit: 62472.99,
      upperLimit: 98466.00,
      fixedFee: 10008.24,
      porcentage: .2352,
      year: 2023,
      month: 2,
      isrRetention: 2
    },
    {
      id: 8,
      lowerLimit: 98466.01,
      upperLimit: 187987.80,
      fixedFee: 18473.78,
      porcentage: .30,
      year: 2023,
      month: 2,
      isrRetention: 2
    },
    {
      id: 9,
      lowerLimit: 187987.81,
      upperLimit: 250650.40,
      fixedFee: 45330.34,
      porcentage: .32,
      year: 2023,
      month: 2,
      isrRetention: 2
    },
    {
      id: 10,
      lowerLimit: 250650.41,
      upperLimit: 751951.22,
      fixedFee: 65382.36,
      porcentage: .34,
      year: 2023,
      month: 2,
      isrRetention: 2
    },
    {
      id: 11,
      lowerLimit: 751951.23,
      upperLimit: null,
      fixedFee: 235824.64,
      porcentage: .35,
      year: 2023,
      month: 2,
      isrRetention: 2
    },
    {
      id: 1,
      lowerLimit: 0.01,
      upperLimit: 2238.12,
      fixedFee: 0.00,
      porcentage: .0192,
      year: 2023,
      month: 3,
      isrRetention: 2
    },
    {
      id: 2,
      lowerLimit: 2238.13,
      upperLimit: 18996.15,
      fixedFee: 42.96,
      porcentage: .0640,
      year: 2023,
      month: 3,
      isrRetention: 2
    },
    {
      id: 3,
      lowerLimit: 18996.16,
      upperLimit: 33384.03,
      fixedFee: 1115.49,
      porcentage: .1088,
      year: 2023,
      month: 3,
      isrRetention: 2
    },
    {
      id: 4,
      lowerLimit: 33384.04,
      upperLimit: 38807.46,
      fixedFee: 2680.89,
      porcentage: .16,
      year: 2023,
      month: 3,
      isrRetention: 2
    },
    {
      id: 5,
      lowerLimit: 38807.47,
      upperLimit: 46463.13,
      fixedFee: 3548.64,
      porcentage: .1792,
      year: 2023,
      month: 3,
      isrRetention: 2
    },
    {
      id: 6,
      lowerLimit: 46463.14,
      upperLimit: 93709.47,
      fixedFee: 4920.52,
      porcentage: .2136,
      year: 2023,
      month: 3,
      isrRetention: 2
    },
    {
      id: 7,
      lowerLimit: 93709.48,
      upperLimit: 147699.00,
      fixedFee: 15012.36,
      porcentage: .2352,
      year: 2023,
      month: 3,
      isrRetention: 2
    },
    {
      id: 8,
      lowerLimit: 147699.01,
      upperLimit: 281981.70,
      fixedFee: 27710.67,
      porcentage: .30,
      year: 2023,
      month: 3,
      isrRetention: 2
    },
    {
      id: 9,
      lowerLimit: 281981.71,
      upperLimit: 375975.60,
      fixedFee: 67995.51,
      porcentage: .32,
      year: 2023,
      month: 3,
      isrRetention: 2
    },
    {
      id: 10,
      lowerLimit: 375975.61,
      upperLimit: 1127926.83,
      fixedFee: 98073.54,
      porcentage: .34,
      year: 2023,
      month: 3,
      isrRetention: 2
    },
    {
      id: 11,
      lowerLimit: 1127926.84,
      upperLimit: null,
      fixedFee: 353736.96,
      porcentage: .35,
      year: 2023,
      month: 3,
      isrRetention: 2
    },
    {
      id: 1,
      lowerLimit: 0.01,
      upperLimit: 2238.12,
      fixedFee: 0.00,
      porcentage: .0192,
      year: 2023,
      month: 3,
      isrRetention: 2
    },
    {
      id: 2,
      lowerLimit: 2238.13,
      upperLimit: 18996.15,
      fixedFee: 42.96,
      porcentage: .0640,
      year: 2023,
      month: 3,
      isrRetention: 2
    },
    {
      id: 3,
      lowerLimit: 18996.16,
      upperLimit: 33384.03,
      fixedFee: 1115.49,
      porcentage: .1088,
      year: 2023,
      month: 3,
      isrRetention: 2
    },
    {
      id: 4,
      lowerLimit: 33384.04,
      upperLimit: 38807.46,
      fixedFee: 2680.89,
      porcentage: .16,
      year: 2023,
      month: 3,
      isrRetention: 2
    },
    {
      id: 5,
      lowerLimit: 38807.47,
      upperLimit: 46463.13,
      fixedFee: 3548.64,
      porcentage: .1792,
      year: 2023,
      month: 3,
      isrRetention: 2
    },
    {
      id: 6,
      lowerLimit: 46463.14,
      upperLimit: 93709.47,
      fixedFee: 4920.52,
      porcentage: .2136,
      year: 2023,
      month: 3,
      isrRetention: 2
    },
    {
      id: 7,
      lowerLimit: 93709.48,
      upperLimit: 147699.00,
      fixedFee: 15012.36,
      porcentage: .2352,
      year: 2023,
      month: 3,
      isrRetention: 2
    },
    {
      id: 8,
      lowerLimit: 147699.01,
      upperLimit: 281981.70,
      fixedFee: 27710.67,
      porcentage: .30,
      year: 2023,
      month: 3,
      isrRetention: 2
    },
    {
      id: 9,
      lowerLimit: 281981.71,
      upperLimit: 375975.60,
      fixedFee: 67995.51,
      porcentage: .32,
      year: 2023,
      month: 3,
      isrRetention: 2
    },
    {
      id: 10,
      lowerLimit: 375975.61,
      upperLimit: 1127926.83,
      fixedFee: 98073.54,
      porcentage: .34,
      year: 2023,
      month: 3,
      isrRetention: 2
    },
    {
      id: 11,
      lowerLimit: 1127926.84,
      upperLimit: null,
      fixedFee: 353736.96,
      porcentage: .35,
      year: 2023,
      month: 3,
      isrRetention: 2
    },
    {
      id: 1,
      lowerLimit: 0.01,
      upperLimit: 2984.16,
      fixedFee: 0.00,
      porcentage: .0192,
      year: 2023,
      month: 4,
      isrRetention: 2
    },
    {
      id: 2,
      lowerLimit: 2984.17,
      upperLimit: 25328.20,
      fixedFee: 57.28,
      porcentage: .0640,
      year: 2023,
      month: 4,
      isrRetention: 2
    },
    {
      id: 3,
      lowerLimit: 25328.21,
      upperLimit: 44512.04,
      fixedFee: 1487.32,
      porcentage: .1088,
      year: 2023,
      month: 4,
      isrRetention: 2
    },
    {
      id: 4,
      lowerLimit: 44512.05,
      upperLimit: 51743.28,
      fixedFee: 3574.52,
      porcentage: .16,
      year: 2023,
      month: 4,
      isrRetention: 2
    },
    {
      id: 5,
      lowerLimit: 51743.29,
      upperLimit: 61950.84,
      fixedFee: 4731.52,
      porcentage: .1792,
      year: 2023,
      month: 4,
      isrRetention: 2
    },
    {
      id: 6,
      lowerLimit: 61950.85,
      upperLimit: 124945.96,
      fixedFee: 6560.72,
      porcentage: .2136,
      year: 2023,
      month: 4,
      isrRetention: 2
    },
    {
      id: 7,
      lowerLimit: 124945.97,
      upperLimit: 196932.00,
      fixedFee: 20016.48,
      porcentage: .2352,
      year: 2023,
      month: 4,
      isrRetention: 2
    },
    {
      id: 8,
      lowerLimit: 196932.01,
      upperLimit: 375975.60,
      fixedFee: 36947.56,
      porcentage: .30,
      year: 2023,
      month: 4,
      isrRetention: 2
    },
    {
      id: 9,
      lowerLimit: 375975.61,
      upperLimit: 501300.80,
      fixedFee: 90660.68,
      porcentage: .32,
      year: 2023,
      month: 4,
      isrRetention: 2
    },
    {
      id: 10,
      lowerLimit: 501300.81,
      upperLimit: 1503902.44,
      fixedFee: 130764.72,
      porcentage: .34,
      year: 2023,
      month: 4,
      isrRetention: 2
    },
    {
      id: 11,
      lowerLimit: 1503902.45,
      upperLimit: null,
      fixedFee: 471649.28,
      porcentage: .35,
      year: 2023,
      month: 4,
      isrRetention: 2
    },
    {
      id: 1,
      lowerLimit: 0.01,
      upperLimit: 3730.20,
      fixedFee: 0.00,
      porcentage: .0192,
      year: 2023,
      month: 5,
      isrRetention: 2
    },
    {
      id: 2,
      lowerLimit: 3730.21,
      upperLimit: 31660.25,
      fixedFee: 71.60,
      porcentage: .0640,
      year: 2023,
      month: 5,
      isrRetention: 2
    },
    {
      id: 3,
      lowerLimit: 31660.26,
      upperLimit: 55640.05,
      fixedFee: 1859.15,
      porcentage: .1088,
      year: 2023,
      month: 5,
      isrRetention: 2
    },
    {
      id: 4,
      lowerLimit: 55640.06,
      upperLimit: 64679.10,
      fixedFee: 4468.15,
      porcentage: .16,
      year: 2023,
      month: 5,
      isrRetention: 2
    },
    {
      id: 5,
      lowerLimit: 64679.11,
      upperLimit: 77438.55,
      fixedFee: 5914.40,
      porcentage: .1792,
      year: 2023,
      month: 5,
      isrRetention: 2
    },
    {
      id: 6,
      lowerLimit: 77438.56,
      upperLimit: 156182.45,
      fixedFee: 8200.90,
      porcentage: .2136,
      year: 2023,
      month: 5,
      isrRetention: 2
    },
    {
      id: 7,
      lowerLimit: 156182.46,
      upperLimit: 246165.00,
      fixedFee: 25020.60,
      porcentage: .2352,
      year: 2023,
      month: 5,
      isrRetention: 2
    },
    {
      id: 8,
      lowerLimit: 246165.01,
      upperLimit: 469969.50,
      fixedFee: 46184.45,
      porcentage: .30,
      year: 2023,
      month: 5,
      isrRetention: 2
    },
    {
      id: 9,
      lowerLimit: 469969.51,
      upperLimit: 626626.00,
      fixedFee: 113325.85,
      porcentage: .32,
      year: 2023,
      month: 5,
      isrRetention: 2
    },
    {
      id: 10,
      lowerLimit: 626626.01,
      upperLimit: 1879878.05,
      fixedFee: 163455.90,
      porcentage: .34,
      year: 2023,
      month: 5,
      isrRetention: 2
    },
    {
      id: 11,
      lowerLimit: 1879878.06,
      upperLimit: null,
      fixedFee: 589561.60,
      porcentage: .35,
      year: 2023,
      month: 5,
      isrRetention: 2
    },
    {
      id: 1,
      lowerLimit: 0.01,
      upperLimit: 4476.24,
      fixedFee: 0.00,
      porcentage: .0192,
      year: 2023,
      month: 6,
      isrRetention: 2
    },
    {
      id: 2,
      lowerLimit: 4476.25,
      upperLimit: 37992.30,
      fixedFee: 85.92,
      porcentage: .0640,
      year: 2023,
      month: 6,
      isrRetention: 2
    },
    {
      id: 3,
      lowerLimit: 37992.31,
      upperLimit: 66768.06,
      fixedFee: 2230.98,
      porcentage: .1088,
      year: 2023,
      month: 6,
      isrRetention: 2
    },
    {
      id: 4,
      lowerLimit: 66768.07,
      upperLimit: 77614.92,
      fixedFee: 5361.78,
      porcentage: .16,
      year: 2023,
      month: 6,
      isrRetention: 2
    },
    {
      id: 5,
      lowerLimit: 77614.93,
      upperLimit: 92926.26,
      fixedFee: 7097.28,
      porcentage: .1792,
      year: 2023,
      month: 6,
      isrRetention: 2
    },
    {
      id: 6,
      lowerLimit: 92926.27,
      upperLimit: 187418.94,
      fixedFee: 9841.08,
      porcentage: .2136,
      year: 2023,
      month: 6,
      isrRetention: 2
    },
    {
      id: 7,
      lowerLimit: 187418.95,
      upperLimit: 295398.00,
      fixedFee: 30024.72,
      porcentage: .2352,
      year: 2023,
      month: 6,
      isrRetention: 2
    },
    {
      id: 8,
      lowerLimit: 295398.01,
      upperLimit: 563963.40,
      fixedFee: 55421.34,
      porcentage: .30,
      year: 2023,
      month: 6,
      isrRetention: 2
    },
    {
      id: 9,
      lowerLimit: 563963.41,
      upperLimit: 751951.20,
      fixedFee: 135991.02,
      porcentage: .32,
      year: 2023,
      month: 6,
      isrRetention: 2
    },
    {
      id: 10,
      lowerLimit: 751951.21,
      upperLimit: 2255853.66,
      fixedFee: 163455.90,
      porcentage: .34,
      year: 2023,
      month: 6,
      isrRetention: 2
    },
    {
      id: 11,
      lowerLimit: 2255853.67,
      upperLimit: null,
      fixedFee: 707473.92,
      porcentage: .35,
      year: 2023,
      month: 6,
      isrRetention: 2
    },
    {
      id: 1,
      lowerLimit: 0.01,
      upperLimit: 5222.28,
      fixedFee: 0.00,
      porcentage: .0192,
      year: 2023,
      month: 7,
      isrRetention: 2
    },
    {
      id: 2,
      lowerLimit: 5222.29,
      upperLimit: 44324.35,
      fixedFee: 100.24,
      porcentage: .0640,
      year: 2023,
      month: 7,
      isrRetention: 2
    },
    {
      id: 3,
      lowerLimit: 44324.36,
      upperLimit: 77896.07,
      fixedFee: 2602.81,
      porcentage: .1088,
      year: 2023,
      month: 7,
      isrRetention: 2
    },
    {
      id: 4,
      lowerLimit: 77896.08,
      upperLimit: 90550.74,
      fixedFee: 6255.41,
      porcentage: .16,
      year: 2023,
      month: 7,
      isrRetention: 2
    },
    {
      id: 5,
      lowerLimit: 90550.75,
      upperLimit: 108413.97,
      fixedFee: 8280.16,
      porcentage: .1792,
      year: 2023,
      month: 7,
      isrRetention: 2
    },
    {
      id: 6,
      lowerLimit: 108413.98,
      upperLimit: 218655.43,
      fixedFee: 11481.26,
      porcentage: .2136,
      year: 2023,
      month: 7,
      isrRetention: 2
    },
    {
      id: 7,
      lowerLimit: 218655.44,
      upperLimit: 344631.00,
      fixedFee: 35028.84,
      porcentage: .2352,
      year: 2023,
      month: 7,
      isrRetention: 2
    },
    {
      id: 8,
      lowerLimit: 344631.01,
      upperLimit: 657957.30,
      fixedFee: 64658.23,
      porcentage: .30,
      year: 2023,
      month: 7,
      isrRetention: 2
    },
    {
      id: 9,
      lowerLimit: 657957.31,
      upperLimit: 877276.40,
      fixedFee: 158656.19,
      porcentage: .32,
      year: 2023,
      month: 7,
      isrRetention: 2
    },
    {
      id: 10,
      lowerLimit: 877276.41,
      upperLimit: 2631829.27,
      fixedFee: 228838.26,
      porcentage: .34,
      year: 2023,
      month: 7,
      isrRetention: 2
    },
    {
      id: 11,
      lowerLimit: 2631829.28,
      upperLimit: null,
      fixedFee: 825386.24,
      porcentage: .35,
      year: 2023,
      month: 7,
      isrRetention: 2
    },
    {
      id: 1,
      lowerLimit: 0.01,
      upperLimit: 5968.32,
      fixedFee: 0.00,
      porcentage: .0192,
      year: 2023,
      month: 8,
      isrRetention: 2
    },
    {
      id: 2,
      lowerLimit: 5968.33,
      upperLimit: 50656.40,
      fixedFee: 114.56,
      porcentage: .0640,
      year: 2023,
      month: 8,
      isrRetention: 2
    },
    {
      id: 3,
      lowerLimit: 50656.41,
      upperLimit: 89024.08,
      fixedFee: 2974.64,
      porcentage: .1088,
      year: 2023,
      month: 8,
      isrRetention: 2
    },
    {
      id: 4,
      lowerLimit: 89024.09,
      upperLimit: 103486.56,
      fixedFee: 7149.04,
      porcentage: .16,
      year: 2023,
      month: 8,
      isrRetention: 2
    },
    {
      id: 5,
      lowerLimit: 103486.57,
      upperLimit: 123901.68,
      fixedFee: 9463.04,
      porcentage: .1792,
      year: 2023,
      month: 8,
      isrRetention: 2
    },
    {
      id: 6,
      lowerLimit: 123901.69,
      upperLimit: 249891.92,
      fixedFee: 13121.44,
      porcentage: .2136,
      year: 2023,
      month: 8,
      isrRetention: 2
    },
    {
      id: 7,
      lowerLimit: 249891.93,
      upperLimit: 393864.00,
      fixedFee: 40032.96,
      porcentage: .2352,
      year: 2023,
      month: 8,
      isrRetention: 2
    },
    {
      id: 8,
      lowerLimit: 393864.01,
      upperLimit: 751951.20,
      fixedFee: 73895.12,
      porcentage: .30,
      year: 2023,
      month: 8,
      isrRetention: 2
    },
    {
      id: 9,
      lowerLimit: 751951.21,
      upperLimit: 1002601.60,
      fixedFee: 181321.36,
      porcentage: .32,
      year: 2023,
      month: 8,
      isrRetention: 2
    },
    {
      id: 10,
      lowerLimit: 1002601.61,
      upperLimit: 3007804.88,
      fixedFee: 261529.44,
      porcentage: .34,
      year: 2023,
      month: 8,
      isrRetention: 2
    },
    {
      id: 11,
      lowerLimit: 3007804.89,
      upperLimit: null,
      fixedFee: 943298.56,
      porcentage: .35,
      year: 2023,
      month: 8,
      isrRetention: 2
    },
    {
      id: 1,
      lowerLimit: 0.01,
      upperLimit: 6714.36,
      fixedFee: 0.00,
      porcentage: .0192,
      year: 2023,
      month: 9,
      isrRetention: 2
    },
    {
      id: 2,
      lowerLimit: 6714.37,
      upperLimit: 56988.45,
      fixedFee: 128.88,
      porcentage: .0640,
      year: 2023,
      month: 9,
      isrRetention: 2
    },
    {
      id: 3,
      lowerLimit: 56988.46,
      upperLimit: 100152.09,
      fixedFee: 3346.47,
      porcentage: .1088,
      year: 2023,
      month: 9,
      isrRetention: 2
    },
    {
      id: 4,
      lowerLimit: 100152.10,
      upperLimit: 116422.38,
      fixedFee: 8042.67,
      porcentage: .16,
      year: 2023,
      month: 9,
      isrRetention: 2
    },
    {
      id: 5,
      lowerLimit: 116422.39,
      upperLimit: 139389.39,
      fixedFee: 10645.92,
      porcentage: .1792,
      year: 2023,
      month: 9,
      isrRetention: 2
    },
    {
      id: 6,
      lowerLimit: 139389.40,
      upperLimit: 281128.41,
      fixedFee: 14761.62,
      porcentage: .2136,
      year: 2023,
      month: 9,
      isrRetention: 2
    },
    {
      id: 7,
      lowerLimit: 281128.42,
      upperLimit: 443097.00,
      fixedFee: 45037.08,
      porcentage: .2352,
      year: 2023,
      month: 9,
      isrRetention: 2
    },
    {
      id: 8,
      lowerLimit: 443097.01,
      upperLimit: 845945.10,
      fixedFee: 83132.01,
      porcentage: .30,
      year: 2023,
      month: 9,
      isrRetention: 2
    },
    {
      id: 9,
      lowerLimit: 845945.11,
      upperLimit: 1127926.80,
      fixedFee: 203986.53,
      porcentage: .32,
      year: 2023,
      month: 9,
      isrRetention: 2
    },
    {
      id: 10,
      lowerLimit: 1127926.81,
      upperLimit: 3383780.49,
      fixedFee: 294220.62,
      porcentage: .34,
      year: 2023,
      month: 9,
      isrRetention: 2
    },
    {
      id: 11,
      lowerLimit: 3383780.50,
      upperLimit: null,
      fixedFee: 1061210.88,
      porcentage: .35,
      year: 2023,
      month: 9,
      isrRetention: 2
    },
    {
      id: 1,
      lowerLimit: 0.01,
      upperLimit: 7460.40,
      fixedFee: 0.00,
      porcentage: .0192,
      year: 2023,
      month: 10,
      isrRetention: 2
    },
    {
      id: 2,
      lowerLimit: 7460.41,
      upperLimit: 63320.50,
      fixedFee: 143.20,
      porcentage: .0640,
      year: 2023,
      month: 10,
      isrRetention: 2
    },
    {
      id: 3,
      lowerLimit: 63320.51,
      upperLimit: 111280.10,
      fixedFee: 3718.30,
      porcentage: .1088,
      year: 2023,
      month: 10,
      isrRetention: 2
    },
    {
      id: 4,
      lowerLimit: 111280.11,
      upperLimit: 129358.20,
      fixedFee: 8936.30,
      porcentage: .16,
      year: 2023,
      month: 10,
      isrRetention: 2
    },
    {
      id: 5,
      lowerLimit: 129358.21,
      upperLimit: 154877.10,
      fixedFee: 11828.80,
      porcentage: .1792,
      year: 2023,
      month: 10,
      isrRetention: 2
    },
    {
      id: 6,
      lowerLimit: 154877.11,
      upperLimit: 312364.90,
      fixedFee: 16401.80,
      porcentage: .2136,
      year: 2023,
      month: 10,
      isrRetention: 2
    },
    {
      id: 7,
      lowerLimit: 312364.91,
      upperLimit: 492330.00,
      fixedFee: 50041.20,
      porcentage: .2352,
      year: 2023,
      month: 10,
      isrRetention: 2
    },
    {
      id: 8,
      lowerLimit: 492330.01,
      upperLimit: 939939.00,
      fixedFee: 92368.90,
      porcentage: .30,
      year: 2023,
      month: 10,
      isrRetention: 2
    },
    {
      id: 9,
      lowerLimit: 939939.01,
      upperLimit: 1253252.00,
      fixedFee: 226651.70,
      porcentage: .32,
      year: 2023,
      month: 10,
      isrRetention: 2
    },
    {
      id: 10,
      lowerLimit: 1253252.01,
      upperLimit: 3759756.00,
      fixedFee: 326911.80,
      porcentage: .34,
      year: 2023,
      month: 10,
      isrRetention: 2
    },
    {
      id: 11,
      lowerLimit: 3759756.01,
      upperLimit: null,
      fixedFee: 1179123.20,
      porcentage: .35,
      year: 2023,
      month: 10,
      isrRetention: 2
    },
    {
      id: 1,
      lowerLimit: 0.01,
      upperLimit: 8206.44,
      fixedFee: 0.00,
      porcentage: .0192,
      year: 2023,
      month: 11,
      isrRetention: 2
    },
    {
      id: 2,
      lowerLimit: 8206.45,
      upperLimit: 69652.55,
      fixedFee: 157.52,
      porcentage: .0640,
      year: 2023,
      month: 11,
      isrRetention: 2
    },
    {
      id: 3,
      lowerLimit: 69652.56,
      upperLimit: 122408.11,
      fixedFee: 4090.13,
      porcentage: .1088,
      year: 2023,
      month: 11,
      isrRetention: 2
    },
    {
      id: 4,
      lowerLimit: 122408.12,
      upperLimit: 142294.02,
      fixedFee: 9829.93,
      porcentage: .16,
      year: 2023,
      month: 11,
      isrRetention: 2
    },
    {
      id: 5,
      lowerLimit: 142294.03,
      upperLimit: 170364.81,
      fixedFee: 13011.68,
      porcentage: .1792,
      year: 2023,
      month: 11,
      isrRetention: 2
    },
    {
      id: 6,
      lowerLimit: 170364.82,
      upperLimit: 343601.39,
      fixedFee: 18041.98,
      porcentage: .2136,
      year: 2023,
      month: 11,
      isrRetention: 2
    },
    {
      id: 7,
      lowerLimit: 343601.40,
      upperLimit: 541563.00,
      fixedFee: 55045.32,
      porcentage: .2352,
      year: 2023,
      month: 11,
      isrRetention: 2
    },
    {
      id: 8,
      lowerLimit: 541563.01,
      upperLimit: 1033932.90,
      fixedFee: 101605.79,
      porcentage: .30,
      year: 2023,
      month: 11,
      isrRetention: 2
    },
    {
      id: 9,
      lowerLimit: 1033932.91,
      upperLimit: 1378577.20,
      fixedFee: 249316.87,
      porcentage: .32,
      year: 2023,
      month: 11,
      isrRetention: 2
    },
    {
      id: 10,
      lowerLimit: 1378577.21,
      upperLimit: 4135731.71,
      fixedFee: 359602.98,
      porcentage: .34,
      year: 2023,
      month: 11,
      isrRetention: 2
    },
    {
      id: 11,
      lowerLimit: 4135731.72,
      upperLimit: null,
      fixedFee: 1297035.52,
      porcentage: .35,
      year: 2023,
      month: 11,
      isrRetention: 2
    },
    {
      id: 1,
      lowerLimit: 0.01,
      upperLimit: 8952.49,
      fixedFee: 0.00,
      porcentage: .0192,
      year: 2023,
      month: 12,
      isrRetention: 2
    },
    {
      id: 2,
      lowerLimit: 8952.50,
      upperLimit: 75984.55,
      fixedFee: 171.88,
      porcentage: .0640,
      year: 2023,
      month: 12,
      isrRetention: 2
    },
    {
      id: 3,
      lowerLimit: 75984.56,
      upperLimit: 133536.07,
      fixedFee: 4461.94,
      porcentage: .1088,
      year: 2023,
      month: 12,
      isrRetention: 2
    },
    {
      id: 4,
      lowerLimit: 133536.08,
      upperLimit: 155229.80,
      fixedFee: 10723.55,
      porcentage: .16,
      year: 2023,
      month: 12,
      isrRetention: 2
    },
    {
      id: 5,
      lowerLimit: 155229.81,
      upperLimit: 185852.57,
      fixedFee: 14194.54,
      porcentage: .1792,
      year: 2023,
      month: 12,
      isrRetention: 2
    },
    {
      id: 6,
      lowerLimit: 185852.58,
      upperLimit: 374837.88,
      fixedFee: 19682.13,
      porcentage: .2136,
      year: 2023,
      month: 12,
      isrRetention: 2
    },
    {
      id: 7,
      lowerLimit: 374837.89,
      upperLimit: 590795.99,
      fixedFee: 60049.40,
      porcentage: .2352,
      year: 2023,
      month: 12,
      isrRetention: 2
    },
    {
      id: 8,
      lowerLimit: 590796.00,
      upperLimit: 1127926.84,
      fixedFee: 110842.74,
      porcentage: .30,
      year: 2023,
      month: 12,
      isrRetention: 2
    },
    {
      id: 9,
      lowerLimit: 1127926.85,
      upperLimit: 1503902.46,
      fixedFee: 271981.99,
      porcentage: .32,
      year: 2023,
      month: 12,
      isrRetention: 2
    },
    {
      id: 10,
      lowerLimit: 1503902.47,
      upperLimit: 4511707.37,
      fixedFee: 392294.17,
      porcentage: .34,
      year: 2023,
      month: 12,
      isrRetention: 2
    },
    {
      id: 11,
      lowerLimit: 4511707.38,
      upperLimit: null,
      fixedFee: 1414947.85,
      porcentage: .35,
      year: 2023,
      month: 12,
      isrRetention: 2
    },
    // Comienza 2024
    {
      id: 1,
      lowerLimit: 0.01,
      upperLimit: 746.04,
      fixedFee: 0.00,
      porcentage: .0192,
      year: 2024,
      month: 1,
      isrRetention: 2
    },
    {
      id: 2,
      lowerLimit: 746.05,
      upperLimit: 6332.05,
      fixedFee: 14.32,
      porcentage: .0640,
      year: 2024,
      month: 1,
      isrRetention: 2
    },
    {
      id: 3,
      lowerLimit: 6332.06,
      upperLimit: 11128.01,
      fixedFee: 371.83,
      porcentage: .1088,
      year: 2024,
      month: 1,
      isrRetention: 2
    },
    {
      id: 4,
      lowerLimit: 11128.02,
      upperLimit: 12935.82,
      fixedFee: 893.63,
      porcentage: .16,
      year: 2024,
      month: 1,
      isrRetention: 2
    },
    {
      id: 5,
      lowerLimit: 12935.83,
      upperLimit: 15487.71,
      fixedFee: 1182.88,
      porcentage: .1792,
      year: 2024,
      month: 1,
      isrRetention: 2
    },
    {
      id: 6,
      lowerLimit: 15487.72,
      upperLimit: 31236.49,
      fixedFee: 1640.18,
      porcentage: .2136,
      year: 2024,
      month: 1,
      isrRetention: 2
    },
    {
      id: 7,
      lowerLimit: 31236.50,
      upperLimit: 49233.00,
      fixedFee: 5004.12,
      porcentage: .2352,
      year: 2024,
      month: 1,
      isrRetention: 2
    },
    {
      id: 8,
      lowerLimit: 49233.01,
      upperLimit: 93993.90,
      fixedFee: 9236.89,
      porcentage: .30,
      year: 2024,
      month: 1,
      isrRetention: 2
    },
    {
      id: 9,
      lowerLimit: 93993.91,
      upperLimit: 125325.20,
      fixedFee: 22665.17,
      porcentage: .32,
      year: 2024,
      month: 1,
      isrRetention: 2
    },
    {
      id: 10,
      lowerLimit: 125325.21,
      upperLimit: 375975.61,
      fixedFee: 32691.18,
      porcentage: .34,
      year: 2024,
      month: 1,
      isrRetention: 2
    },
    {
      id: 11,
      lowerLimit: 375975.62,
      upperLimit: null,
      fixedFee: 117912.32,
      porcentage: .35,
      year: 2024,
      month: 1,
      isrRetention: 2
    },
    {
      id: 1,
      lowerLimit: 0.01,
      upperLimit: 1492.08,
      fixedFee: 0.00,
      porcentage: .0192,
      year: 2024,
      month: 2,
      isrRetention: 2
    },
    {
      id: 2,
      lowerLimit: 1492.09,
      upperLimit: 12664.10,
      fixedFee: 28.64,
      porcentage: .0640,
      year: 2024,
      month: 2,
      isrRetention: 2
    },
    {
      id: 3,
      lowerLimit: 12664.11,
      upperLimit: 22256.02,
      fixedFee: 743.66,
      porcentage: .1088,
      year: 2024,
      month: 2,
      isrRetention: 2
    },
    {
      id: 4,
      lowerLimit: 22256.03,
      upperLimit: 25871.64,
      fixedFee: 1787.26,
      porcentage: .16,
      year: 2024,
      month: 2,
      isrRetention: 2
    },
    {
      id: 5,
      lowerLimit: 25871.65,
      upperLimit: 30975.42,
      fixedFee: 2365.76,
      porcentage: .1792,
      year: 2024,
      month: 2,
      isrRetention: 2
    },
    {
      id: 6,
      lowerLimit: 30975.43,
      upperLimit: 62472.98,
      fixedFee: 3280.36,
      porcentage: .2136,
      year: 2024,
      month: 2,
      isrRetention: 2
    },
    {
      id: 7,
      lowerLimit: 62472.99,
      upperLimit: 98466.00,
      fixedFee: 10008.24,
      porcentage: .2352,
      year: 2024,
      month: 2,
      isrRetention: 2
    },
    {
      id: 8,
      lowerLimit: 98466.01,
      upperLimit: 187987.80,
      fixedFee: 18473.78,
      porcentage: .30,
      year: 2024,
      month: 2,
      isrRetention: 2
    },
    {
      id: 9,
      lowerLimit: 187987.81,
      upperLimit: 250650.40,
      fixedFee: 45330.34,
      porcentage: .32,
      year: 2024,
      month: 2,
      isrRetention: 2
    },
    {
      id: 10,
      lowerLimit: 250650.41,
      upperLimit: 751951.22,
      fixedFee: 65382.36,
      porcentage: .34,
      year: 2024,
      month: 2,
      isrRetention: 2
    },
    {
      id: 11,
      lowerLimit: 751951.23,
      upperLimit: null,
      fixedFee: 235824.64,
      porcentage: .35,
      year: 2024,
      month: 2,
      isrRetention: 2
    },
    {
      id: 1,
      lowerLimit: 0.01,
      upperLimit: 2238.12,
      fixedFee: 0.00,
      porcentage: .0192,
      year: 2024,
      month: 3,
      isrRetention: 2
    },
    {
      id: 2,
      lowerLimit: 2238.13,
      upperLimit: 18996.15,
      fixedFee: 42.96,
      porcentage: .0640,
      year: 2024,
      month: 3,
      isrRetention: 2
    },
    {
      id: 3,
      lowerLimit: 18996.16,
      upperLimit: 33384.03,
      fixedFee: 1115.49,
      porcentage: .1088,
      year: 2024,
      month: 3,
      isrRetention: 2
    },
    {
      id: 4,
      lowerLimit: 33384.04,
      upperLimit: 38807.46,
      fixedFee: 2680.89,
      porcentage: .16,
      year: 2024,
      month: 3,
      isrRetention: 2
    },
    {
      id: 5,
      lowerLimit: 38807.47,
      upperLimit: 46463.13,
      fixedFee: 3548.64,
      porcentage: .1792,
      year: 2024,
      month: 3,
      isrRetention: 2
    },
    {
      id: 6,
      lowerLimit: 46463.14,
      upperLimit: 93709.47,
      fixedFee: 4920.52,
      porcentage: .2136,
      year: 2024,
      month: 3,
      isrRetention: 2
    },
    {
      id: 7,
      lowerLimit: 93709.48,
      upperLimit: 147699.00,
      fixedFee: 15012.36,
      porcentage: .2352,
      year: 2024,
      month: 3,
      isrRetention: 2
    },
    {
      id: 8,
      lowerLimit: 147699.01,
      upperLimit: 281981.70,
      fixedFee: 27710.67,
      porcentage: .30,
      year: 2024,
      month: 3,
      isrRetention: 2
    },
    {
      id: 9,
      lowerLimit: 281981.71,
      upperLimit: 375975.60,
      fixedFee: 67995.51,
      porcentage: .32,
      year: 2024,
      month: 3,
      isrRetention: 2
    },
    {
      id: 10,
      lowerLimit: 375975.61,
      upperLimit: 1127926.83,
      fixedFee: 98073.54,
      porcentage: .34,
      year: 2024,
      month: 3,
      isrRetention: 2
    },
    {
      id: 11,
      lowerLimit: 1127926.84,
      upperLimit: null,
      fixedFee: 353736.96,
      porcentage: .35,
      year: 2024,
      month: 3,
      isrRetention: 2
    },
    {
      id: 1,
      lowerLimit: 0.01,
      upperLimit: 2238.12,
      fixedFee: 0.00,
      porcentage: .0192,
      year: 2024,
      month: 3,
      isrRetention: 2
    },
    {
      id: 2,
      lowerLimit: 2238.13,
      upperLimit: 18996.15,
      fixedFee: 42.96,
      porcentage: .0640,
      year: 2024,
      month: 3,
      isrRetention: 2
    },
    {
      id: 3,
      lowerLimit: 18996.16,
      upperLimit: 33384.03,
      fixedFee: 1115.49,
      porcentage: .1088,
      year: 2024,
      month: 3,
      isrRetention: 2
    },
    {
      id: 4,
      lowerLimit: 33384.04,
      upperLimit: 38807.46,
      fixedFee: 2680.89,
      porcentage: .16,
      year: 2024,
      month: 3,
      isrRetention: 2
    },
    {
      id: 5,
      lowerLimit: 38807.47,
      upperLimit: 46463.13,
      fixedFee: 3548.64,
      porcentage: .1792,
      year: 2024,
      month: 3,
      isrRetention: 2
    },
    {
      id: 6,
      lowerLimit: 46463.14,
      upperLimit: 93709.47,
      fixedFee: 4920.52,
      porcentage: .2136,
      year: 2024,
      month: 3,
      isrRetention: 2
    },
    {
      id: 7,
      lowerLimit: 93709.48,
      upperLimit: 147699.00,
      fixedFee: 15012.36,
      porcentage: .2352,
      year: 2024,
      month: 3,
      isrRetention: 2
    },
    {
      id: 8,
      lowerLimit: 147699.01,
      upperLimit: 281981.70,
      fixedFee: 27710.67,
      porcentage: .30,
      year: 2024,
      month: 3,
      isrRetention: 2
    },
    {
      id: 9,
      lowerLimit: 281981.71,
      upperLimit: 375975.60,
      fixedFee: 67995.51,
      porcentage: .32,
      year: 2024,
      month: 3,
      isrRetention: 2
    },
    {
      id: 10,
      lowerLimit: 375975.61,
      upperLimit: 1127926.83,
      fixedFee: 98073.54,
      porcentage: .34,
      year: 2024,
      month: 3,
      isrRetention: 2
    },
    {
      id: 11,
      lowerLimit: 1127926.84,
      upperLimit: null,
      fixedFee: 353736.96,
      porcentage: .35,
      year: 2024,
      month: 3,
      isrRetention: 2
    },
    {
      id: 1,
      lowerLimit: 0.01,
      upperLimit: 2984.16,
      fixedFee: 0.00,
      porcentage: .0192,
      year: 2024,
      month: 4,
      isrRetention: 2
    },
    {
      id: 2,
      lowerLimit: 2984.17,
      upperLimit: 25328.20,
      fixedFee: 57.28,
      porcentage: .0640,
      year: 2024,
      month: 4,
      isrRetention: 2
    },
    {
      id: 3,
      lowerLimit: 25328.21,
      upperLimit: 44512.04,
      fixedFee: 1487.32,
      porcentage: .1088,
      year: 2024,
      month: 4,
      isrRetention: 2
    },
    {
      id: 4,
      lowerLimit: 44512.05,
      upperLimit: 51743.28,
      fixedFee: 3574.52,
      porcentage: .16,
      year: 2024,
      month: 4,
      isrRetention: 2
    },
    {
      id: 5,
      lowerLimit: 51743.29,
      upperLimit: 61950.84,
      fixedFee: 4731.52,
      porcentage: .1792,
      year: 2024,
      month: 4,
      isrRetention: 2
    },
    {
      id: 6,
      lowerLimit: 61950.85,
      upperLimit: 124945.96,
      fixedFee: 6560.72,
      porcentage: .2136,
      year: 2024,
      month: 4,
      isrRetention: 2
    },
    {
      id: 7,
      lowerLimit: 124945.97,
      upperLimit: 196932.00,
      fixedFee: 20016.48,
      porcentage: .2352,
      year: 2024,
      month: 4,
      isrRetention: 2
    },
    {
      id: 8,
      lowerLimit: 196932.01,
      upperLimit: 375975.60,
      fixedFee: 36947.56,
      porcentage: .30,
      year: 2024,
      month: 4,
      isrRetention: 2
    },
    {
      id: 9,
      lowerLimit: 375975.61,
      upperLimit: 501300.80,
      fixedFee: 90660.68,
      porcentage: .32,
      year: 2024,
      month: 4,
      isrRetention: 2
    },
    {
      id: 10,
      lowerLimit: 501300.81,
      upperLimit: 1503902.44,
      fixedFee: 130764.72,
      porcentage: .34,
      year: 2024,
      month: 4,
      isrRetention: 2
    },
    {
      id: 11,
      lowerLimit: 1503902.45,
      upperLimit: null,
      fixedFee: 471649.28,
      porcentage: .35,
      year: 2024,
      month: 4,
      isrRetention: 2
    },
    {
      id: 1,
      lowerLimit: 0.01,
      upperLimit: 3730.20,
      fixedFee: 0.00,
      porcentage: .0192,
      year: 2024,
      month: 5,
      isrRetention: 2
    },
    {
      id: 2,
      lowerLimit: 3730.21,
      upperLimit: 31660.25,
      fixedFee: 71.60,
      porcentage: .0640,
      year: 2024,
      month: 5,
      isrRetention: 2
    },
    {
      id: 3,
      lowerLimit: 31660.26,
      upperLimit: 55640.05,
      fixedFee: 1859.15,
      porcentage: .1088,
      year: 2024,
      month: 5,
      isrRetention: 2
    },
    {
      id: 4,
      lowerLimit: 55640.06,
      upperLimit: 64679.10,
      fixedFee: 4468.15,
      porcentage: .16,
      year: 2024,
      month: 5,
      isrRetention: 2
    },
    {
      id: 5,
      lowerLimit: 64679.11,
      upperLimit: 77438.55,
      fixedFee: 5914.40,
      porcentage: .1792,
      year: 2024,
      month: 5,
      isrRetention: 2
    },
    {
      id: 6,
      lowerLimit: 77438.56,
      upperLimit: 156182.45,
      fixedFee: 8200.90,
      porcentage: .2136,
      year: 2024,
      month: 5,
      isrRetention: 2
    },
    {
      id: 7,
      lowerLimit: 156182.46,
      upperLimit: 246165.00,
      fixedFee: 25020.60,
      porcentage: .2352,
      year: 2024,
      month: 5,
      isrRetention: 2
    },
    {
      id: 8,
      lowerLimit: 246165.01,
      upperLimit: 469969.50,
      fixedFee: 46184.45,
      porcentage: .30,
      year: 2024,
      month: 5,
      isrRetention: 2
    },
    {
      id: 9,
      lowerLimit: 469969.51,
      upperLimit: 626626.00,
      fixedFee: 113325.85,
      porcentage: .32,
      year: 2024,
      month: 5,
      isrRetention: 2
    },
    {
      id: 10,
      lowerLimit: 626626.01,
      upperLimit: 1879878.05,
      fixedFee: 163455.90,
      porcentage: .34,
      year: 2024,
      month: 5,
      isrRetention: 2
    },
    {
      id: 11,
      lowerLimit: 1879878.06,
      upperLimit: null,
      fixedFee: 589561.60,
      porcentage: .35,
      year: 2024,
      month: 5,
      isrRetention: 2
    },
    {
      id: 1,
      lowerLimit: 0.01,
      upperLimit: 4476.24,
      fixedFee: 0.00,
      porcentage: .0192,
      year: 2024,
      month: 6,
      isrRetention: 2
    },
    {
      id: 2,
      lowerLimit: 4476.25,
      upperLimit: 37992.30,
      fixedFee: 85.92,
      porcentage: .0640,
      year: 2024,
      month: 6,
      isrRetention: 2
    },
    {
      id: 3,
      lowerLimit: 37992.31,
      upperLimit: 66768.06,
      fixedFee: 2230.98,
      porcentage: .1088,
      year: 2024,
      month: 6,
      isrRetention: 2
    },
    {
      id: 4,
      lowerLimit: 66768.07,
      upperLimit: 77614.92,
      fixedFee: 5361.78,
      porcentage: .16,
      year: 2024,
      month: 6,
      isrRetention: 2
    },
    {
      id: 5,
      lowerLimit: 77614.93,
      upperLimit: 92926.26,
      fixedFee: 7097.28,
      porcentage: .1792,
      year: 2024,
      month: 6,
      isrRetention: 2
    },
    {
      id: 6,
      lowerLimit: 92926.27,
      upperLimit: 187418.94,
      fixedFee: 9841.08,
      porcentage: .2136,
      year: 2024,
      month: 6,
      isrRetention: 2
    },
    {
      id: 7,
      lowerLimit: 187418.95,
      upperLimit: 295398.00,
      fixedFee: 30024.72,
      porcentage: .2352,
      year: 2024,
      month: 6,
      isrRetention: 2
    },
    {
      id: 8,
      lowerLimit: 295398.01,
      upperLimit: 563963.40,
      fixedFee: 55421.34,
      porcentage: .30,
      year: 2024,
      month: 6,
      isrRetention: 2
    },
    {
      id: 9,
      lowerLimit: 563963.41,
      upperLimit: 751951.20,
      fixedFee: 135991.02,
      porcentage: .32,
      year: 2024,
      month: 6,
      isrRetention: 2
    },
    {
      id: 10,
      lowerLimit: 751951.21,
      upperLimit: 2255853.66,
      fixedFee: 163455.90,
      porcentage: .34,
      year: 2024,
      month: 6,
      isrRetention: 2
    },
    {
      id: 11,
      lowerLimit: 2255853.67,
      upperLimit: null,
      fixedFee: 707473.92,
      porcentage: .35,
      year: 2024,
      month: 6,
      isrRetention: 2
    },
    {
      id: 1,
      lowerLimit: 0.01,
      upperLimit: 5222.28,
      fixedFee: 0.00,
      porcentage: .0192,
      year: 2024,
      month: 7,
      isrRetention: 2
    },
    {
      id: 2,
      lowerLimit: 5222.29,
      upperLimit: 44324.35,
      fixedFee: 100.24,
      porcentage: .0640,
      year: 2024,
      month: 7,
      isrRetention: 2
    },
    {
      id: 3,
      lowerLimit: 44324.36,
      upperLimit: 77896.07,
      fixedFee: 2602.81,
      porcentage: .1088,
      year: 2024,
      month: 7,
      isrRetention: 2
    },
    {
      id: 4,
      lowerLimit: 77896.08,
      upperLimit: 90550.74,
      fixedFee: 6255.41,
      porcentage: .16,
      year: 2024,
      month: 7,
      isrRetention: 2
    },
    {
      id: 5,
      lowerLimit: 90550.75,
      upperLimit: 108413.97,
      fixedFee: 8280.16,
      porcentage: .1792,
      year: 2024,
      month: 7,
      isrRetention: 2
    },
    {
      id: 6,
      lowerLimit: 108413.98,
      upperLimit: 218655.43,
      fixedFee: 11481.26,
      porcentage: .2136,
      year: 2024,
      month: 7,
      isrRetention: 2
    },
    {
      id: 7,
      lowerLimit: 218655.44,
      upperLimit: 344631.00,
      fixedFee: 35028.84,
      porcentage: .2352,
      year: 2024,
      month: 7,
      isrRetention: 2
    },
    {
      id: 8,
      lowerLimit: 344631.01,
      upperLimit: 657957.30,
      fixedFee: 64658.23,
      porcentage: .30,
      year: 2024,
      month: 7,
      isrRetention: 2
    },
    {
      id: 9,
      lowerLimit: 657957.31,
      upperLimit: 877276.40,
      fixedFee: 158656.19,
      porcentage: .32,
      year: 2024,
      month: 7,
      isrRetention: 2
    },
    {
      id: 10,
      lowerLimit: 877276.41,
      upperLimit: 2631829.27,
      fixedFee: 228838.26,
      porcentage: .34,
      year: 2024,
      month: 7,
      isrRetention: 2
    },
    {
      id: 11,
      lowerLimit: 2631829.28,
      upperLimit: null,
      fixedFee: 825386.24,
      porcentage: .35,
      year: 2024,
      month: 7,
      isrRetention: 2
    },
    {
      id: 1,
      lowerLimit: 0.01,
      upperLimit: 5968.32,
      fixedFee: 0.00,
      porcentage: .0192,
      year: 2024,
      month: 8,
      isrRetention: 2
    },
    {
      id: 2,
      lowerLimit: 5968.33,
      upperLimit: 50656.40,
      fixedFee: 114.56,
      porcentage: .0640,
      year: 2024,
      month: 8,
      isrRetention: 2
    },
    {
      id: 3,
      lowerLimit: 50656.41,
      upperLimit: 89024.08,
      fixedFee: 2974.64,
      porcentage: .1088,
      year: 2024,
      month: 8,
      isrRetention: 2
    },
    {
      id: 4,
      lowerLimit: 89024.09,
      upperLimit: 103486.56,
      fixedFee: 7149.04,
      porcentage: .16,
      year: 2024,
      month: 8,
      isrRetention: 2
    },
    {
      id: 5,
      lowerLimit: 103486.57,
      upperLimit: 123901.68,
      fixedFee: 9463.04,
      porcentage: .1792,
      year: 2024,
      month: 8,
      isrRetention: 2
    },
    {
      id: 6,
      lowerLimit: 123901.69,
      upperLimit: 249891.92,
      fixedFee: 13121.44,
      porcentage: .2136,
      year: 2024,
      month: 8,
      isrRetention: 2
    },
    {
      id: 7,
      lowerLimit: 249891.93,
      upperLimit: 393864.00,
      fixedFee: 40032.96,
      porcentage: .2352,
      year: 2024,
      month: 8,
      isrRetention: 2
    },
    {
      id: 8,
      lowerLimit: 393864.01,
      upperLimit: 751951.20,
      fixedFee: 73895.12,
      porcentage: .30,
      year: 2024,
      month: 8,
      isrRetention: 2
    },
    {
      id: 9,
      lowerLimit: 751951.21,
      upperLimit: 1002601.60,
      fixedFee: 181321.36,
      porcentage: .32,
      year: 2024,
      month: 8,
      isrRetention: 2
    },
    {
      id: 10,
      lowerLimit: 1002601.61,
      upperLimit: 3007804.88,
      fixedFee: 261529.44,
      porcentage: .34,
      year: 2024,
      month: 8,
      isrRetention: 2
    },
    {
      id: 11,
      lowerLimit: 3007804.89,
      upperLimit: null,
      fixedFee: 943298.56,
      porcentage: .35,
      year: 2024,
      month: 8,
      isrRetention: 2
    },
    {
      id: 1,
      lowerLimit: 0.01,
      upperLimit: 6714.36,
      fixedFee: 0.00,
      porcentage: .0192,
      year: 2024,
      month: 9,
      isrRetention: 2
    },
    {
      id: 2,
      lowerLimit: 6714.37,
      upperLimit: 56988.45,
      fixedFee: 128.88,
      porcentage: .0640,
      year: 2024,
      month: 9,
      isrRetention: 2
    },
    {
      id: 3,
      lowerLimit: 56988.46,
      upperLimit: 100152.09,
      fixedFee: 3346.47,
      porcentage: .1088,
      year: 2024,
      month: 9,
      isrRetention: 2
    },
    {
      id: 4,
      lowerLimit: 100152.10,
      upperLimit: 116422.38,
      fixedFee: 8042.67,
      porcentage: .16,
      year: 2024,
      month: 9,
      isrRetention: 2
    },
    {
      id: 5,
      lowerLimit: 116422.39,
      upperLimit: 139389.39,
      fixedFee: 10645.92,
      porcentage: .1792,
      year: 2024,
      month: 9,
      isrRetention: 2
    },
    {
      id: 6,
      lowerLimit: 139389.40,
      upperLimit: 281128.41,
      fixedFee: 14761.62,
      porcentage: .2136,
      year: 2024,
      month: 9,
      isrRetention: 2
    },
    {
      id: 7,
      lowerLimit: 281128.42,
      upperLimit: 443097.00,
      fixedFee: 45037.08,
      porcentage: .2352,
      year: 2024,
      month: 9,
      isrRetention: 2
    },
    {
      id: 8,
      lowerLimit: 443097.01,
      upperLimit: 845945.10,
      fixedFee: 83132.01,
      porcentage: .30,
      year: 2024,
      month: 9,
      isrRetention: 2
    },
    {
      id: 9,
      lowerLimit: 845945.11,
      upperLimit: 1127926.80,
      fixedFee: 203986.53,
      porcentage: .32,
      year: 2024,
      month: 9,
      isrRetention: 2
    },
    {
      id: 10,
      lowerLimit: 1127926.81,
      upperLimit: 3383780.49,
      fixedFee: 294220.62,
      porcentage: .34,
      year: 2024,
      month: 9,
      isrRetention: 2
    },
    {
      id: 11,
      lowerLimit: 3383780.50,
      upperLimit: null,
      fixedFee: 1061210.88,
      porcentage: .35,
      year: 2024,
      month: 9,
      isrRetention: 2
    },
    {
      id: 1,
      lowerLimit: 0.01,
      upperLimit: 7460.40,
      fixedFee: 0.00,
      porcentage: .0192,
      year: 2024,
      month: 10,
      isrRetention: 2
    },
    {
      id: 2,
      lowerLimit: 7460.41,
      upperLimit: 63320.50,
      fixedFee: 143.20,
      porcentage: .0640,
      year: 2024,
      month: 10,
      isrRetention: 2
    },
    {
      id: 3,
      lowerLimit: 63320.51,
      upperLimit: 111280.10,
      fixedFee: 3718.30,
      porcentage: .1088,
      year: 2024,
      month: 10,
      isrRetention: 2
    },
    {
      id: 4,
      lowerLimit: 111280.11,
      upperLimit: 129358.20,
      fixedFee: 8936.30,
      porcentage: .16,
      year: 2024,
      month: 10,
      isrRetention: 2
    },
    {
      id: 5,
      lowerLimit: 129358.21,
      upperLimit: 154877.10,
      fixedFee: 11828.80,
      porcentage: .1792,
      year: 2024,
      month: 10,
      isrRetention: 2
    },
    {
      id: 6,
      lowerLimit: 154877.11,
      upperLimit: 312364.90,
      fixedFee: 16401.80,
      porcentage: .2136,
      year: 2024,
      month: 10,
      isrRetention: 2
    },
    {
      id: 7,
      lowerLimit: 312364.91,
      upperLimit: 492330.00,
      fixedFee: 50041.20,
      porcentage: .2352,
      year: 2024,
      month: 10,
      isrRetention: 2
    },
    {
      id: 8,
      lowerLimit: 492330.01,
      upperLimit: 939939.00,
      fixedFee: 92368.90,
      porcentage: .30,
      year: 2024,
      month: 10,
      isrRetention: 2
    },
    {
      id: 9,
      lowerLimit: 939939.01,
      upperLimit: 1253252.00,
      fixedFee: 226651.70,
      porcentage: .32,
      year: 2024,
      month: 10,
      isrRetention: 2
    },
    {
      id: 10,
      lowerLimit: 1253252.01,
      upperLimit: 3759756.00,
      fixedFee: 326911.80,
      porcentage: .34,
      year: 2024,
      month: 10,
      isrRetention: 2
    },
    {
      id: 11,
      lowerLimit: 3759756.01,
      upperLimit: null,
      fixedFee: 1179123.20,
      porcentage: .35,
      year: 2024,
      month: 10,
      isrRetention: 2
    },
    {
      id: 1,
      lowerLimit: 0.01,
      upperLimit: 8206.44,
      fixedFee: 0.00,
      porcentage: .0192,
      year: 2024,
      month: 11,
      isrRetention: 2
    },
    {
      id: 2,
      lowerLimit: 8206.45,
      upperLimit: 69652.55,
      fixedFee: 157.52,
      porcentage: .0640,
      year: 2024,
      month: 11,
      isrRetention: 2
    },
    {
      id: 3,
      lowerLimit: 69652.56,
      upperLimit: 122408.11,
      fixedFee: 4090.13,
      porcentage: .1088,
      year: 2024,
      month: 11,
      isrRetention: 2
    },
    {
      id: 4,
      lowerLimit: 122408.12,
      upperLimit: 142294.02,
      fixedFee: 9829.93,
      porcentage: .16,
      year: 2024,
      month: 11,
      isrRetention: 2
    },
    {
      id: 5,
      lowerLimit: 142294.03,
      upperLimit: 170364.81,
      fixedFee: 13011.68,
      porcentage: .1792,
      year: 2024,
      month: 11,
      isrRetention: 2
    },
    {
      id: 6,
      lowerLimit: 170364.82,
      upperLimit: 343601.39,
      fixedFee: 18041.98,
      porcentage: .2136,
      year: 2024,
      month: 11,
      isrRetention: 2
    },
    {
      id: 7,
      lowerLimit: 343601.40,
      upperLimit: 541563.00,
      fixedFee: 55045.32,
      porcentage: .2352,
      year: 2024,
      month: 11,
      isrRetention: 2
    },
    {
      id: 8,
      lowerLimit: 541563.01,
      upperLimit: 1033932.90,
      fixedFee: 101605.79,
      porcentage: .30,
      year: 2024,
      month: 11,
      isrRetention: 2
    },
    {
      id: 9,
      lowerLimit: 1033932.91,
      upperLimit: 1378577.20,
      fixedFee: 249316.87,
      porcentage: .32,
      year: 2024,
      month: 11,
      isrRetention: 2
    },
    {
      id: 10,
      lowerLimit: 1378577.21,
      upperLimit: 4135731.71,
      fixedFee: 359602.98,
      porcentage: .34,
      year: 2024,
      month: 11,
      isrRetention: 2
    },
    {
      id: 11,
      lowerLimit: 4135731.72,
      upperLimit: null,
      fixedFee: 1297035.52,
      porcentage: .35,
      year: 2024,
      month: 11,
      isrRetention: 2
    },
    {
      id: 1,
      lowerLimit: 0.01,
      upperLimit: 8952.49,
      fixedFee: 0.00,
      porcentage: .0192,
      year: 2024,
      month: 12,
      isrRetention: 2
    },
    {
      id: 2,
      lowerLimit: 8952.50,
      upperLimit: 75984.55,
      fixedFee: 171.88,
      porcentage: .0640,
      year: 2024,
      month: 12,
      isrRetention: 2
    },
    {
      id: 3,
      lowerLimit: 75984.56,
      upperLimit: 133536.07,
      fixedFee: 4461.94,
      porcentage: .1088,
      year: 2024,
      month: 12,
      isrRetention: 2
    },
    {
      id: 4,
      lowerLimit: 133536.08,
      upperLimit: 155229.80,
      fixedFee: 10723.55,
      porcentage: .16,
      year: 2024,
      month: 12,
      isrRetention: 2
    },
    {
      id: 5,
      lowerLimit: 155229.81,
      upperLimit: 185852.57,
      fixedFee: 14194.54,
      porcentage: .1792,
      year: 2024,
      month: 12,
      isrRetention: 2
    },
    {
      id: 6,
      lowerLimit: 185852.58,
      upperLimit: 374837.88,
      fixedFee: 19682.13,
      porcentage: .2136,
      year: 2024,
      month: 12,
      isrRetention: 2
    },
    {
      id: 7,
      lowerLimit: 374837.89,
      upperLimit: 590795.99,
      fixedFee: 60049.40,
      porcentage: .2352,
      year: 2024,
      month: 12,
      isrRetention: 2
    },
    {
      id: 8,
      lowerLimit: 590796.00,
      upperLimit: 1127926.84,
      fixedFee: 110842.74,
      porcentage: .30,
      year: 2024,
      month: 12,
      isrRetention: 2
    },
    {
      id: 9,
      lowerLimit: 1127926.85,
      upperLimit: 1503902.46,
      fixedFee: 271981.99,
      porcentage: .32,
      year: 2024,
      month: 12,
      isrRetention: 2
    },
    {
      id: 10,
      lowerLimit: 1503902.47,
      upperLimit: 4511707.37,
      fixedFee: 392294.17,
      porcentage: .34,
      year: 2024,
      month: 12,
      isrRetention: 2
    },
    {
      id: 11,
      lowerLimit: 4511707.38,
      upperLimit: null,
      fixedFee: 1414947.85,
      porcentage: .35,
      year: 2024,
      month: 12,
      isrRetention: 2
    },
    //Aqui comienza 2025
    {
      id: 1,
      lowerLimit: 0.01,
      upperLimit: 746.04,
      fixedFee: 0.00,
      porcentage: .0192,
      year: 2025,
      month: 1,
      isrRetention: 2
    },
    {
      id: 2,
      lowerLimit: 746.05,
      upperLimit: 6332.05,
      fixedFee: 14.32,
      porcentage: .0640,
      year: 2025,
      month: 1,
      isrRetention: 2
    },
    {
      id: 3,
      lowerLimit: 6332.06,
      upperLimit: 11128.01,
      fixedFee: 371.83,
      porcentage: .1088,
      year: 2025,
      month: 1,
      isrRetention: 2
    },
    {
      id: 4,
      lowerLimit: 11128.02,
      upperLimit: 12935.82,
      fixedFee: 893.63,
      porcentage: .16,
      year: 2025,
      month: 1,
      isrRetention: 2
    },
    {
      id: 5,
      lowerLimit: 12935.83,
      upperLimit: 15487.71,
      fixedFee: 1182.88,
      porcentage: .1792,
      year: 2025,
      month: 1,
      isrRetention: 2
    },
    {
      id: 6,
      lowerLimit: 15487.72,
      upperLimit: 31236.49,
      fixedFee: 1640.18,
      porcentage: .2136,
      year: 2025,
      month: 1,
      isrRetention: 2
    },
    {
      id: 7,
      lowerLimit: 31236.50,
      upperLimit: 49233.00,
      fixedFee: 5004.12,
      porcentage: .2352,
      year: 2025,
      month: 1,
      isrRetention: 2
    },
    {
      id: 8,
      lowerLimit: 49233.01,
      upperLimit: 93993.90,
      fixedFee: 9236.89,
      porcentage: .30,
      year: 2025,
      month: 1,
      isrRetention: 2
    },
    {
      id: 9,
      lowerLimit: 93993.91,
      upperLimit: 125325.20,
      fixedFee: 22665.17,
      porcentage: .32,
      year: 2025,
      month: 1,
      isrRetention: 2
    },
    {
      id: 10,
      lowerLimit: 125325.21,
      upperLimit: 375975.61,
      fixedFee: 32691.18,
      porcentage: .34,
      year: 2025,
      month: 1,
      isrRetention: 2
    },
    {
      id: 11,
      lowerLimit: 375975.62,
      upperLimit: null,
      fixedFee: 117912.32,
      porcentage: .35,
      year: 2025,
      month: 1,
      isrRetention: 2
    },
    {
      id: 1,
      lowerLimit: 0.01,
      upperLimit: 1492.08,
      fixedFee: 0.00,
      porcentage: .0192,
      year: 2025,
      month: 2,
      isrRetention: 2
    },
    {
      id: 2,
      lowerLimit: 1492.09,
      upperLimit: 12664.10,
      fixedFee: 28.64,
      porcentage: .0640,
      year: 2025,
      month: 2,
      isrRetention: 2
    },
    {
      id: 3,
      lowerLimit: 12664.11,
      upperLimit: 22256.02,
      fixedFee: 743.66,
      porcentage: .1088,
      year: 2025,
      month: 2,
      isrRetention: 2
    },
    {
      id: 4,
      lowerLimit: 22256.03,
      upperLimit: 25871.64,
      fixedFee: 1787.26,
      porcentage: .16,
      year: 2025,
      month: 2,
      isrRetention: 2
    },
    {
      id: 5,
      lowerLimit: 25871.65,
      upperLimit: 30975.42,
      fixedFee: 2365.76,
      porcentage: .1792,
      year: 2025,
      month: 2,
      isrRetention: 2
    },
    {
      id: 6,
      lowerLimit: 30975.43,
      upperLimit: 62472.98,
      fixedFee: 3280.36,
      porcentage: .2136,
      year: 2025,
      month: 2,
      isrRetention: 2
    },
    {
      id: 7,
      lowerLimit: 62472.99,
      upperLimit: 98466.00,
      fixedFee: 10008.24,
      porcentage: .2352,
      year: 2025,
      month: 2,
      isrRetention: 2
    },
    {
      id: 8,
      lowerLimit: 98466.01,
      upperLimit: 187987.80,
      fixedFee: 18473.78,
      porcentage: .30,
      year: 2025,
      month: 2,
      isrRetention: 2
    },
    {
      id: 9,
      lowerLimit: 187987.81,
      upperLimit: 250650.40,
      fixedFee: 45330.34,
      porcentage: .32,
      year: 2025,
      month: 2,
      isrRetention: 2
    },
    {
      id: 10,
      lowerLimit: 250650.41,
      upperLimit: 751951.22,
      fixedFee: 65382.36,
      porcentage: .34,
      year: 2025,
      month: 2,
      isrRetention: 2
    },
    {
      id: 11,
      lowerLimit: 751951.23,
      upperLimit: null,
      fixedFee: 235824.64,
      porcentage: .35,
      year: 2025,
      month: 2,
      isrRetention: 2
    },
    {
      id: 1,
      lowerLimit: 0.01,
      upperLimit: 2238.12,
      fixedFee: 0.00,
      porcentage: .0192,
      year: 2025,
      month: 3,
      isrRetention: 2
    },
    {
      id: 2,
      lowerLimit: 2238.13,
      upperLimit: 18996.15,
      fixedFee: 42.96,
      porcentage: .0640,
      year: 2025,
      month: 3,
      isrRetention: 2
    },
    {
      id: 3,
      lowerLimit: 18996.16,
      upperLimit: 33384.03,
      fixedFee: 1115.49,
      porcentage: .1088,
      year: 2025,
      month: 3,
      isrRetention: 2
    },
    {
      id: 4,
      lowerLimit: 33384.04,
      upperLimit: 38807.46,
      fixedFee: 2680.89,
      porcentage: .16,
      year: 2025,
      month: 3,
      isrRetention: 2
    },
    {
      id: 5,
      lowerLimit: 38807.47,
      upperLimit: 46463.13,
      fixedFee: 3548.64,
      porcentage: .1792,
      year: 2025,
      month: 3,
      isrRetention: 2
    },
    {
      id: 6,
      lowerLimit: 46463.14,
      upperLimit: 93709.47,
      fixedFee: 4920.52,
      porcentage: .2136,
      year: 2025,
      month: 3,
      isrRetention: 2
    },
    {
      id: 7,
      lowerLimit: 93709.48,
      upperLimit: 147699.00,
      fixedFee: 15012.36,
      porcentage: .2352,
      year: 2025,
      month: 3,
      isrRetention: 2
    },
    {
      id: 8,
      lowerLimit: 147699.01,
      upperLimit: 281981.70,
      fixedFee: 27710.67,
      porcentage: .30,
      year: 2025,
      month: 3,
      isrRetention: 2
    },
    {
      id: 9,
      lowerLimit: 281981.71,
      upperLimit: 375975.60,
      fixedFee: 67995.51,
      porcentage: .32,
      year: 2025,
      month: 3,
      isrRetention: 2
    },
    {
      id: 10,
      lowerLimit: 375975.61,
      upperLimit: 1127926.83,
      fixedFee: 98073.54,
      porcentage: .34,
      year: 2025,
      month: 3,
      isrRetention: 2
    },
    {
      id: 11,
      lowerLimit: 1127926.84,
      upperLimit: null,
      fixedFee: 353736.96,
      porcentage: .35,
      year: 2025,
      month: 3,
      isrRetention: 2
    },
    {
      id: 1,
      lowerLimit: 0.01,
      upperLimit: 2238.12,
      fixedFee: 0.00,
      porcentage: .0192,
      year: 2025,
      month: 3,
      isrRetention: 2
    },
    {
      id: 2,
      lowerLimit: 2238.13,
      upperLimit: 18996.15,
      fixedFee: 42.96,
      porcentage: .0640,
      year: 2025,
      month: 3,
      isrRetention: 2
    },
    {
      id: 3,
      lowerLimit: 18996.16,
      upperLimit: 33384.03,
      fixedFee: 1115.49,
      porcentage: .1088,
      year: 2025,
      month: 3,
      isrRetention: 2
    },
    {
      id: 4,
      lowerLimit: 33384.04,
      upperLimit: 38807.46,
      fixedFee: 2680.89,
      porcentage: .16,
      year: 2025,
      month: 3,
      isrRetention: 2
    },
    {
      id: 5,
      lowerLimit: 38807.47,
      upperLimit: 46463.13,
      fixedFee: 3548.64,
      porcentage: .1792,
      year: 2025,
      month: 3,
      isrRetention: 2
    },
    {
      id: 6,
      lowerLimit: 46463.14,
      upperLimit: 93709.47,
      fixedFee: 4920.52,
      porcentage: .2136,
      year: 2025,
      month: 3,
      isrRetention: 2
    },
    {
      id: 7,
      lowerLimit: 93709.48,
      upperLimit: 147699.00,
      fixedFee: 15012.36,
      porcentage: .2352,
      year: 2025,
      month: 3,
      isrRetention: 2
    },
    {
      id: 8,
      lowerLimit: 147699.01,
      upperLimit: 281981.70,
      fixedFee: 27710.67,
      porcentage: .30,
      year: 2025,
      month: 3,
      isrRetention: 2
    },
    {
      id: 9,
      lowerLimit: 281981.71,
      upperLimit: 375975.60,
      fixedFee: 67995.51,
      porcentage: .32,
      year: 2025,
      month: 3,
      isrRetention: 2
    },
    {
      id: 10,
      lowerLimit: 375975.61,
      upperLimit: 1127926.83,
      fixedFee: 98073.54,
      porcentage: .34,
      year: 2025,
      month: 3,
      isrRetention: 2
    },
    {
      id: 11,
      lowerLimit: 1127926.84,
      upperLimit: null,
      fixedFee: 353736.96,
      porcentage: .35,
      year: 2025,
      month: 3,
      isrRetention: 2
    },
    {
      id: 1,
      lowerLimit: 0.01,
      upperLimit: 2984.16,
      fixedFee: 0.00,
      porcentage: .0192,
      year: 2025,
      month: 4,
      isrRetention: 2
    },
    {
      id: 2,
      lowerLimit: 2984.17,
      upperLimit: 25328.20,
      fixedFee: 57.28,
      porcentage: .0640,
      year: 2025,
      month: 4,
      isrRetention: 2
    },
    {
      id: 3,
      lowerLimit: 25328.21,
      upperLimit: 44512.04,
      fixedFee: 1487.32,
      porcentage: .1088,
      year: 2025,
      month: 4,
      isrRetention: 2
    },
    {
      id: 4,
      lowerLimit: 44512.05,
      upperLimit: 51743.28,
      fixedFee: 3574.52,
      porcentage: .16,
      year: 2025,
      month: 4,
      isrRetention: 2
    },
    {
      id: 5,
      lowerLimit: 51743.29,
      upperLimit: 61950.84,
      fixedFee: 4731.52,
      porcentage: .1792,
      year: 2025,
      month: 4,
      isrRetention: 2
    },
    {
      id: 6,
      lowerLimit: 61950.85,
      upperLimit: 124945.96,
      fixedFee: 6560.72,
      porcentage: .2136,
      year: 2025,
      month: 4,
      isrRetention: 2
    },
    {
      id: 7,
      lowerLimit: 124945.97,
      upperLimit: 196932.00,
      fixedFee: 20016.48,
      porcentage: .2352,
      year: 2025,
      month: 4,
      isrRetention: 2
    },
    {
      id: 8,
      lowerLimit: 196932.01,
      upperLimit: 375975.60,
      fixedFee: 36947.56,
      porcentage: .30,
      year: 2025,
      month: 4,
      isrRetention: 2
    },
    {
      id: 9,
      lowerLimit: 375975.61,
      upperLimit: 501300.80,
      fixedFee: 90660.68,
      porcentage: .32,
      year: 2025,
      month: 4,
      isrRetention: 2
    },
    {
      id: 10,
      lowerLimit: 501300.81,
      upperLimit: 1503902.44,
      fixedFee: 130764.72,
      porcentage: .34,
      year: 2025,
      month: 4,
      isrRetention: 2
    },
    {
      id: 11,
      lowerLimit: 1503902.45,
      upperLimit: null,
      fixedFee: 471649.28,
      porcentage: .35,
      year: 2025,
      month: 4,
      isrRetention: 2
    },
    {
      id: 1,
      lowerLimit: 0.01,
      upperLimit: 3730.20,
      fixedFee: 0.00,
      porcentage: .0192,
      year: 2025,
      month: 5,
      isrRetention: 2
    },
    {
      id: 2,
      lowerLimit: 3730.21,
      upperLimit: 31660.25,
      fixedFee: 71.60,
      porcentage: .0640,
      year: 2025,
      month: 5,
      isrRetention: 2
    },
    {
      id: 3,
      lowerLimit: 31660.26,
      upperLimit: 55640.05,
      fixedFee: 1859.15,
      porcentage: .1088,
      year: 2025,
      month: 5,
      isrRetention: 2
    },
    {
      id: 4,
      lowerLimit: 55640.06,
      upperLimit: 64679.10,
      fixedFee: 4468.15,
      porcentage: .16,
      year: 2025,
      month: 5,
      isrRetention: 2
    },
    {
      id: 5,
      lowerLimit: 64679.11,
      upperLimit: 77438.55,
      fixedFee: 5914.40,
      porcentage: .1792,
      year: 2025,
      month: 5,
      isrRetention: 2
    },
    {
      id: 6,
      lowerLimit: 77438.56,
      upperLimit: 156182.45,
      fixedFee: 8200.90,
      porcentage: .2136,
      year: 2025,
      month: 5,
      isrRetention: 2
    },
    {
      id: 7,
      lowerLimit: 156182.46,
      upperLimit: 246165.00,
      fixedFee: 25020.60,
      porcentage: .2352,
      year: 2025,
      month: 5,
      isrRetention: 2
    },
    {
      id: 8,
      lowerLimit: 246165.01,
      upperLimit: 469969.50,
      fixedFee: 46184.45,
      porcentage: .30,
      year: 2025,
      month: 5,
      isrRetention: 2
    },
    {
      id: 9,
      lowerLimit: 469969.51,
      upperLimit: 626626.00,
      fixedFee: 113325.85,
      porcentage: .32,
      year: 2025,
      month: 5,
      isrRetention: 2
    },
    {
      id: 10,
      lowerLimit: 626626.01,
      upperLimit: 1879878.05,
      fixedFee: 163455.90,
      porcentage: .34,
      year: 2025,
      month: 5,
      isrRetention: 2
    },
    {
      id: 11,
      lowerLimit: 1879878.06,
      upperLimit: null,
      fixedFee: 589561.60,
      porcentage: .35,
      year: 2025,
      month: 5,
      isrRetention: 2
    },
    {
      id: 1,
      lowerLimit: 0.01,
      upperLimit: 4476.24,
      fixedFee: 0.00,
      porcentage: .0192,
      year: 2025,
      month: 6,
      isrRetention: 2
    },
    {
      id: 2,
      lowerLimit: 4476.25,
      upperLimit: 37992.30,
      fixedFee: 85.92,
      porcentage: .0640,
      year: 2025,
      month: 6,
      isrRetention: 2
    },
    {
      id: 3,
      lowerLimit: 37992.31,
      upperLimit: 66768.06,
      fixedFee: 2230.98,
      porcentage: .1088,
      year: 2025,
      month: 6,
      isrRetention: 2
    },
    {
      id: 4,
      lowerLimit: 66768.07,
      upperLimit: 77614.92,
      fixedFee: 5361.78,
      porcentage: .16,
      year: 2025,
      month: 6,
      isrRetention: 2
    },
    {
      id: 5,
      lowerLimit: 77614.93,
      upperLimit: 92926.26,
      fixedFee: 7097.28,
      porcentage: .1792,
      year: 2025,
      month: 6,
      isrRetention: 2
    },
    {
      id: 6,
      lowerLimit: 92926.27,
      upperLimit: 187418.94,
      fixedFee: 9841.08,
      porcentage: .2136,
      year: 2025,
      month: 6,
      isrRetention: 2
    },
    {
      id: 7,
      lowerLimit: 187418.95,
      upperLimit: 295398.00,
      fixedFee: 30024.72,
      porcentage: .2352,
      year: 2025,
      month: 6,
      isrRetention: 2
    },
    {
      id: 8,
      lowerLimit: 295398.01,
      upperLimit: 563963.40,
      fixedFee: 55421.34,
      porcentage: .30,
      year: 2025,
      month: 6,
      isrRetention: 2
    },
    {
      id: 9,
      lowerLimit: 563963.41,
      upperLimit: 751951.20,
      fixedFee: 135991.02,
      porcentage: .32,
      year: 2025,
      month: 6,
      isrRetention: 2
    },
    {
      id: 10,
      lowerLimit: 751951.21,
      upperLimit: 2255853.66,
      fixedFee: 163455.90,
      porcentage: .34,
      year: 2025,
      month: 6,
      isrRetention: 2
    },
    {
      id: 11,
      lowerLimit: 2255853.67,
      upperLimit: null,
      fixedFee: 707473.92,
      porcentage: .35,
      year: 2025,
      month: 6,
      isrRetention: 2
    },
    {
      id: 1,
      lowerLimit: 0.01,
      upperLimit: 5222.28,
      fixedFee: 0.00,
      porcentage: .0192,
      year: 2025,
      month: 7,
      isrRetention: 2
    },
    {
      id: 2,
      lowerLimit: 5222.29,
      upperLimit: 44324.35,
      fixedFee: 100.24,
      porcentage: .0640,
      year: 2025,
      month: 7,
      isrRetention: 2
    },
    {
      id: 3,
      lowerLimit: 44324.36,
      upperLimit: 77896.07,
      fixedFee: 2602.81,
      porcentage: .1088,
      year: 2025,
      month: 7,
      isrRetention: 2
    },
    {
      id: 4,
      lowerLimit: 77896.08,
      upperLimit: 90550.74,
      fixedFee: 6255.41,
      porcentage: .16,
      year: 2025,
      month: 7,
      isrRetention: 2
    },
    {
      id: 5,
      lowerLimit: 90550.75,
      upperLimit: 108413.97,
      fixedFee: 8280.16,
      porcentage: .1792,
      year: 2025,
      month: 7,
      isrRetention: 2
    },
    {
      id: 6,
      lowerLimit: 108413.98,
      upperLimit: 218655.43,
      fixedFee: 11481.26,
      porcentage: .2136,
      year: 2025,
      month: 7,
      isrRetention: 2
    },
    {
      id: 7,
      lowerLimit: 218655.44,
      upperLimit: 344631.00,
      fixedFee: 35028.84,
      porcentage: .2352,
      year: 2025,
      month: 7,
      isrRetention: 2
    },
    {
      id: 8,
      lowerLimit: 344631.01,
      upperLimit: 657957.30,
      fixedFee: 64658.23,
      porcentage: .30,
      year: 2025,
      month: 7,
      isrRetention: 2
    },
    {
      id: 9,
      lowerLimit: 657957.31,
      upperLimit: 877276.40,
      fixedFee: 158656.19,
      porcentage: .32,
      year: 2025,
      month: 7,
      isrRetention: 2
    },
    {
      id: 10,
      lowerLimit: 877276.41,
      upperLimit: 2631829.27,
      fixedFee: 228838.26,
      porcentage: .34,
      year: 2025,
      month: 7,
      isrRetention: 2
    },
    {
      id: 11,
      lowerLimit: 2631829.28,
      upperLimit: null,
      fixedFee: 825386.24,
      porcentage: .35,
      year: 2025,
      month: 7,
      isrRetention: 2
    },
    {
      id: 1,
      lowerLimit: 0.01,
      upperLimit: 5968.32,
      fixedFee: 0.00,
      porcentage: .0192,
      year: 2025,
      month: 8,
      isrRetention: 2
    },
    {
      id: 2,
      lowerLimit: 5968.33,
      upperLimit: 50656.40,
      fixedFee: 114.56,
      porcentage: .0640,
      year: 2025,
      month: 8,
      isrRetention: 2
    },
    {
      id: 3,
      lowerLimit: 50656.41,
      upperLimit: 89024.08,
      fixedFee: 2974.64,
      porcentage: .1088,
      year: 2025,
      month: 8,
      isrRetention: 2
    },
    {
      id: 4,
      lowerLimit: 89024.09,
      upperLimit: 103486.56,
      fixedFee: 7149.04,
      porcentage: .16,
      year: 2025,
      month: 8,
      isrRetention: 2
    },
    {
      id: 5,
      lowerLimit: 103486.57,
      upperLimit: 123901.68,
      fixedFee: 9463.04,
      porcentage: .1792,
      year: 2025,
      month: 8,
      isrRetention: 2
    },
    {
      id: 6,
      lowerLimit: 123901.69,
      upperLimit: 249891.92,
      fixedFee: 13121.44,
      porcentage: .2136,
      year: 2025,
      month: 8,
      isrRetention: 2
    },
    {
      id: 7,
      lowerLimit: 249891.93,
      upperLimit: 393864.00,
      fixedFee: 40032.96,
      porcentage: .2352,
      year: 2025,
      month: 8,
      isrRetention: 2
    },
    {
      id: 8,
      lowerLimit: 393864.01,
      upperLimit: 751951.20,
      fixedFee: 73895.12,
      porcentage: .30,
      year: 2025,
      month: 8,
      isrRetention: 2
    },
    {
      id: 9,
      lowerLimit: 751951.21,
      upperLimit: 1002601.60,
      fixedFee: 181321.36,
      porcentage: .32,
      year: 2025,
      month: 8,
      isrRetention: 2
    },
    {
      id: 10,
      lowerLimit: 1002601.61,
      upperLimit: 3007804.88,
      fixedFee: 261529.44,
      porcentage: .34,
      year: 2025,
      month: 8,
      isrRetention: 2
    },
    {
      id: 11,
      lowerLimit: 3007804.89,
      upperLimit: null,
      fixedFee: 943298.56,
      porcentage: .35,
      year: 2025,
      month: 8,
      isrRetention: 2
    },
    {
      id: 1,
      lowerLimit: 0.01,
      upperLimit: 6714.36,
      fixedFee: 0.00,
      porcentage: .0192,
      year: 2025,
      month: 9,
      isrRetention: 2
    },
    {
      id: 2,
      lowerLimit: 6714.37,
      upperLimit: 56988.45,
      fixedFee: 128.88,
      porcentage: .0640,
      year: 2025,
      month: 9,
      isrRetention: 2
    },
    {
      id: 3,
      lowerLimit: 56988.46,
      upperLimit: 100152.09,
      fixedFee: 3346.47,
      porcentage: .1088,
      year: 2025,
      month: 9,
      isrRetention: 2
    },
    {
      id: 4,
      lowerLimit: 100152.10,
      upperLimit: 116422.38,
      fixedFee: 8042.67,
      porcentage: .16,
      year: 2025,
      month: 9,
      isrRetention: 2
    },
    {
      id: 5,
      lowerLimit: 116422.39,
      upperLimit: 139389.39,
      fixedFee: 10645.92,
      porcentage: .1792,
      year: 2025,
      month: 9,
      isrRetention: 2
    },
    {
      id: 6,
      lowerLimit: 139389.40,
      upperLimit: 281128.41,
      fixedFee: 14761.62,
      porcentage: .2136,
      year: 2025,
      month: 9,
      isrRetention: 2
    },
    {
      id: 7,
      lowerLimit: 281128.42,
      upperLimit: 443097.00,
      fixedFee: 45037.08,
      porcentage: .2352,
      year: 2025,
      month: 9,
      isrRetention: 2
    },
    {
      id: 8,
      lowerLimit: 443097.01,
      upperLimit: 845945.10,
      fixedFee: 83132.01,
      porcentage: .30,
      year: 2025,
      month: 9,
      isrRetention: 2
    },
    {
      id: 9,
      lowerLimit: 845945.11,
      upperLimit: 1127926.80,
      fixedFee: 203986.53,
      porcentage: .32,
      year: 2025,
      month: 9,
      isrRetention: 2
    },
    {
      id: 10,
      lowerLimit: 1127926.81,
      upperLimit: 3383780.49,
      fixedFee: 294220.62,
      porcentage: .34,
      year: 2025,
      month: 9,
      isrRetention: 2
    },
    {
      id: 11,
      lowerLimit: 3383780.50,
      upperLimit: null,
      fixedFee: 1061210.88,
      porcentage: .35,
      year: 2025,
      month: 9,
      isrRetention: 2
    },
    {
      id: 1,
      lowerLimit: 0.01,
      upperLimit: 7460.40,
      fixedFee: 0.00,
      porcentage: .0192,
      year: 2025,
      month: 10,
      isrRetention: 2
    },
    {
      id: 2,
      lowerLimit: 7460.41,
      upperLimit: 63320.50,
      fixedFee: 143.20,
      porcentage: .0640,
      year: 2025,
      month: 10,
      isrRetention: 2
    },
    {
      id: 3,
      lowerLimit: 63320.51,
      upperLimit: 111280.10,
      fixedFee: 3718.30,
      porcentage: .1088,
      year: 2025,
      month: 10,
      isrRetention: 2
    },
    {
      id: 4,
      lowerLimit: 111280.11,
      upperLimit: 129358.20,
      fixedFee: 8936.30,
      porcentage: .16,
      year: 2025,
      month: 10,
      isrRetention: 2
    },
    {
      id: 5,
      lowerLimit: 129358.21,
      upperLimit: 154877.10,
      fixedFee: 11828.80,
      porcentage: .1792,
      year: 2025,
      month: 10,
      isrRetention: 2
    },
    {
      id: 6,
      lowerLimit: 154877.11,
      upperLimit: 312364.90,
      fixedFee: 16401.80,
      porcentage: .2136,
      year: 2025,
      month: 10,
      isrRetention: 2
    },
    {
      id: 7,
      lowerLimit: 312364.91,
      upperLimit: 492330.00,
      fixedFee: 50041.20,
      porcentage: .2352,
      year: 2025,
      month: 10,
      isrRetention: 2
    },
    {
      id: 8,
      lowerLimit: 492330.01,
      upperLimit: 939939.00,
      fixedFee: 92368.90,
      porcentage: .30,
      year: 2025,
      month: 10,
      isrRetention: 2
    },
    {
      id: 9,
      lowerLimit: 939939.01,
      upperLimit: 1253252.00,
      fixedFee: 226651.70,
      porcentage: .32,
      year: 2025,
      month: 10,
      isrRetention: 2
    },
    {
      id: 10,
      lowerLimit: 1253252.01,
      upperLimit: 3759756.00,
      fixedFee: 326911.80,
      porcentage: .34,
      year: 2025,
      month: 10,
      isrRetention: 2
    },
    {
      id: 11,
      lowerLimit: 3759756.01,
      upperLimit: null,
      fixedFee: 1179123.20,
      porcentage: .35,
      year: 2025,
      month: 10,
      isrRetention: 2
    },
    {
      id: 1,
      lowerLimit: 0.01,
      upperLimit: 8206.44,
      fixedFee: 0.00,
      porcentage: .0192,
      year: 2025,
      month: 11,
      isrRetention: 2
    },
    {
      id: 2,
      lowerLimit: 8206.45,
      upperLimit: 69652.55,
      fixedFee: 157.52,
      porcentage: .0640,
      year: 2025,
      month: 11,
      isrRetention: 2
    },
    {
      id: 3,
      lowerLimit: 69652.56,
      upperLimit: 122408.11,
      fixedFee: 4090.13,
      porcentage: .1088,
      year: 2025,
      month: 11,
      isrRetention: 2
    },
    {
      id: 4,
      lowerLimit: 122408.12,
      upperLimit: 142294.02,
      fixedFee: 9829.93,
      porcentage: .16,
      year: 2025,
      month: 11,
      isrRetention: 2
    },
    {
      id: 5,
      lowerLimit: 142294.03,
      upperLimit: 170364.81,
      fixedFee: 13011.68,
      porcentage: .1792,
      year: 2025,
      month: 11,
      isrRetention: 2
    },
    {
      id: 6,
      lowerLimit: 170364.82,
      upperLimit: 343601.39,
      fixedFee: 18041.98,
      porcentage: .2136,
      year: 2025,
      month: 11,
      isrRetention: 2
    },
    {
      id: 7,
      lowerLimit: 343601.40,
      upperLimit: 541563.00,
      fixedFee: 55045.32,
      porcentage: .2352,
      year: 2025,
      month: 11,
      isrRetention: 2
    },
    {
      id: 8,
      lowerLimit: 541563.01,
      upperLimit: 1033932.90,
      fixedFee: 101605.79,
      porcentage: .30,
      year: 2025,
      month: 11,
      isrRetention: 2
    },
    {
      id: 9,
      lowerLimit: 1033932.91,
      upperLimit: 1378577.20,
      fixedFee: 249316.87,
      porcentage: .32,
      year: 2025,
      month: 11,
      isrRetention: 2
    },
    {
      id: 10,
      lowerLimit: 1378577.21,
      upperLimit: 4135731.71,
      fixedFee: 359602.98,
      porcentage: .34,
      year: 2025,
      month: 11,
      isrRetention: 2
    },
    {
      id: 11,
      lowerLimit: 4135731.72,
      upperLimit: null,
      fixedFee: 1297035.52,
      porcentage: .35,
      year: 2025,
      month: 11,
      isrRetention: 2
    },
    {
      id: 1,
      lowerLimit: 0.01,
      upperLimit: 8952.49,
      fixedFee: 0.00,
      porcentage: .0192,
      year: 2025,
      month: 12,
      isrRetention: 2
    },
    {
      id: 2,
      lowerLimit: 8952.50,
      upperLimit: 75984.55,
      fixedFee: 171.88,
      porcentage: .0640,
      year: 2025,
      month: 12,
      isrRetention: 2
    },
    {
      id: 3,
      lowerLimit: 75984.56,
      upperLimit: 133536.07,
      fixedFee: 4461.94,
      porcentage: .1088,
      year: 2025,
      month: 12,
      isrRetention: 2
    },
    {
      id: 4,
      lowerLimit: 133536.08,
      upperLimit: 155229.80,
      fixedFee: 10723.55,
      porcentage: .16,
      year: 2025,
      month: 12,
      isrRetention: 2
    },
    {
      id: 5,
      lowerLimit: 155229.81,
      upperLimit: 185852.57,
      fixedFee: 14194.54,
      porcentage: .1792,
      year: 2025,
      month: 12,
      isrRetention: 2
    },
    {
      id: 6,
      lowerLimit: 185852.58,
      upperLimit: 374837.88,
      fixedFee: 19682.13,
      porcentage: .2136,
      year: 2025,
      month: 12,
      isrRetention: 2
    },
    {
      id: 7,
      lowerLimit: 374837.89,
      upperLimit: 590795.99,
      fixedFee: 60049.40,
      porcentage: .2352,
      year: 2025,
      month: 12,
      isrRetention: 2
    },
    {
      id: 8,
      lowerLimit: 590796.00,
      upperLimit: 1127926.84,
      fixedFee: 110842.74,
      porcentage: .30,
      year: 2025,
      month: 12,
      isrRetention: 2
    },
    {
      id: 9,
      lowerLimit: 1127926.85,
      upperLimit: 1503902.46,
      fixedFee: 271981.99,
      porcentage: .32,
      year: 2025,
      month: 12,
      isrRetention: 2
    },
    {
      id: 10,
      lowerLimit: 1503902.47,
      upperLimit: 4511707.37,
      fixedFee: 392294.17,
      porcentage: .34,
      year: 2025,
      month: 12,
      isrRetention: 2
    },
    {
      id: 11,
      lowerLimit: 4511707.38,
      upperLimit: null,
      fixedFee: 1414947.85,
      porcentage: .35,
      year: 2025,
      month: 12,
      isrRetention: 2
    },
  ]
}

export const IsrHonorariumReducer = (state = initialState, action) => {
  switch (action.type) {

    default:
      return state;
  }
}
